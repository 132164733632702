import { useEffect, useState } from "react";
import DocumentViewer from "./documentView";
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { message } from "antd";
import Loader from "../../shared/sharedComponents/loader";
import _ from "lodash";

export default function ViewSteps({ modalData, reportId }) {
    const [stepsData, setStepsData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // useEffect code here
        if (!_.isEmpty(modalData)) {
            getSteps(modalData, reportId);
        }
    }, [modalData, reportId]);

    const getSteps = (modalData, reportId) => {
        // getSteps code here
        const postData = {
            reportId: reportId,
            alertId: modalData.id,
        };
        setLoading(true);
        axios({
            method: 'post',
            url: `${GlobalConst.API_URL}/auth/pentest/get-step-document`,
            responseType: 'blob',
            data: postData
        }).then((response) => {
            console.log(response);
            if (!_.isEmpty(response)) {
                setStepsData({
                    steps: response.data,
                    docType: response.headers['content-type']
                })
            }
        }).catch((error) => {
            message.error("We encountered an issue retrieving the steps document. Please try again later.")
        }).finally(() => {
            setLoading(false);
        });
    }

    return (<>
        {loading && <Loader />}
        {!_.isEmpty(stepsData) ? <DocumentViewer docData={stepsData.steps} docType={stepsData.docType} /> : <div className='alert alert-info px-2 py-1'>No data found</div>}
    </>)
}