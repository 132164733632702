import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import Loader from "../../shared/sharedComponents/loader";
import VulnerableRepoGraph from '../dastDashboard/vulnerableRepoGraph';
import HorizontalBarGraph from "../dastDashboard/horizontalBarGraph";
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import PentestStatisticsTable from "./pentestStatisticsTable"
import { capitalizeFirstLetter, getPlanTypeFromProductKey, getProductSubscription } from "../../shared/helper/genHelper";
import { VscDebugConsole } from "react-icons/vsc";
import TrendGraph from "./trendGraph";
import axios from "axios";
import { Button, message, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faGlobe, faMobile, faNetworkWired, faRotateRight, faServer, faWifi } from "@fortawesome/free-solid-svg-icons";
import { PiPhoneCallLight } from "react-icons/pi";
import { useSupport } from "../../shared/hooks/contact-support";

export default function PenTestingDashboard() {
    const subscribedProductsReducer = useSelector(state => state.productsReducer);
    const reduxStore = useSelector(state => state);

    const [loadingRows, setLoadingRows] = useState(false);
    const [pentestDashboardReports, setPentestDashboardReports] = useState({});
    const [trendValue, setTrendValue] = useState('7');
    const { data: supportResponse, loading, supportAPI } = useSupport();

    useEffect(() => {
        if (!_.isEmpty(supportResponse)) {
            message.success(`Our support team will review your request and contact you shortly to discuss the available subscription options and how you can access the pentesting features.`);
        } else {
            if (supportResponse === null) {
                message.error(`We encountered an issue connecting to the support team. Please try again later.`);
            }
        }
    }, [supportResponse]);

    useEffect(() => {
        getPentestingReports();
    }, []);

    const getPentestingReports = () => {
        setLoadingRows(true);
        axios.post(`${GlobalConst.API_URL}/auth/pentest/dashboard/summary`)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    if (!_.isEmpty(op.data.assetSummaries)) {
                        setPentestDashboardReports(op.data);
                    } else {
                        setPentestDashboardReports({})
                    }
                } else {
                    setPentestDashboardReports({})
                }
            }
            ).catch(e => {
                console.log("Exception: ", e);
                setPentestDashboardReports(null);
            })
            .finally(() => {
                setLoadingRows(false);
            });
    }

    const handleDashboardReload = () => {
        window.location.reload();
    };

    const onContactSupportClick = (product) => {
        supportAPI({
            email: reduxStore.authReducer.userDetails.email,
            product: product,
            source: "This support request is from Sec1 Unified Application"
        })
    }

    const colorArray = [
        '#785add',
        '#2db46b',
        '#3f80e2'
    ];

    const pentestServices = [{
        title: "Website Pen Testing",
        desc: "The world’s first adaptive pentest engine",
        icon: <FontAwesomeIcon icon={faGlobe} style={{ fontSize: 24 }} />
    }, {
        title: "Network Pen Testing",
        desc: "Uncover and mitigate network vulnerabilities with advanced testing techniques",
        icon: <FontAwesomeIcon icon={faNetworkWired} style={{ fontSize: 24 }} />
    },
    {
        title: "Cloud Pen Testing",
        desc: "Secure your cloud, mitigate risks, ensure compliance and resilience",
        icon: <FontAwesomeIcon icon={faCloud} style={{ fontSize: 24 }} />
    },
    {
        title: "API Pen Testing",
        desc: "Identifying Weak Points and Enhancing API Security",
        icon: <FontAwesomeIcon icon={faServer} style={{ fontSize: 24 }} />
    },
    {
        title: "Mobile App Pen Testing",
        desc: "Fortify Your Mobile Apps with Comprehensive Penetration Testing",
        icon: <FontAwesomeIcon icon={faMobile} style={{ fontSize: 24 }} />
    },
    {
        title: "Wireless Network Testing",
        desc: "Securing Your Wireless Infrastructure with Advanced Testing Techniques",
        icon: <FontAwesomeIcon icon={faWifi} style={{ fontSize: 24 }} />
    }]

    if (loadingRows) {
        return <Loader />;
    } else {
        return (
            <section className="mx-3">
                <section className="mb-0">
                    <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex">
                            <h2 className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                                <VscDebugConsole className="me-2" /> Penetration Testing Dashboard
                                <Tooltip
                                    title="Refresh">
                                    <button className="btn btn-primary py-1 ms-4" onClick={handleDashboardReload}>
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    </button>
                                </Tooltip>
                            </h2>
                        </div>
                    </div>
                </section>
                {(!_.isEmpty(pentestDashboardReports) && !_.isEmpty(pentestDashboardReports.assetSummaries)) ?
                    <>
                        <section>
                            <div className="row">
                                <div className="col-lg-6 custom-border-right">
                                    <div className="p-3 ps-0 d-flex align-items-center w-100 h-100">
                                        <div className="card-body">
                                            <div className="d-flex flex-row align-items-start justify-content-between">
                                                <div className="me-3">
                                                    <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Vulnerabilities</p>
                                                    <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{pentestDashboardReports.totalVulnerabilityCount}</h1>
                                                </div>
                                                <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Critical'} loadingRows={false} dashboardData={pentestDashboardReports.critical} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'High'} loadingRows={false} dashboardData={pentestDashboardReports.high} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Medium'} loadingRows={false} dashboardData={pentestDashboardReports.medium} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Low'} loadingRows={false} dashboardData={pentestDashboardReports.low} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 custom-border-right">
                                    <div className="p-2">
                                        <div className="card-body">
                                            <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                <h5 className="fw-medium">Vulnerability Trend</h5>
                                            </div>
                                            <div style={{ height: 300 }}>
                                                <TrendGraph trendValue={trendValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {!_.isEmpty(pentestDashboardReports.assetSummaries) && <section>
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <PentestStatisticsTable pentestReports={pentestDashboardReports.assetSummaries} />
                                    </div>
                                </div>
                            </div>
                        </section>}
                        <section>
                            <div className="row">
                                {!_.isEmpty(pentestDashboardReports.topVulnerableApplication) && <div className="col-lg-6 custom-border-right">
                                    <div className="p-3">
                                        <div className="card-body">
                                            <div className="mb-3 border-0">
                                                <h5 className="fw-medium">Top 3 Vulnerable Web Applications</h5>
                                            </div>
                                            <div className="row d-flex align-items-center justify-content-start">
                                                {
                                                    pentestDashboardReports.topVulnerableApplication.map((row, index) => {
                                                        return (
                                                            <div className="col-6 col-sm-4" key={`vulnerable-repos-${index}`}>
                                                                <div className="p-3">
                                                                    <VulnerableRepoGraph data={row} assetUrl={row.assetUrl} vulnerabilityCount={row.vulnerabilityCount} totalCve={pentestDashboardReports.totalVulnerabilityCount} strokeColor={colorArray[index]} />
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {!_.isEmpty(pentestDashboardReports.topRuleFailures) && <div className="col-lg-6 ">
                                    <div className="p-3">
                                        <div className="card-body">
                                            <div className="mb-3 border-0">
                                                <h5 className="fw-medium">Top 3 Vulnerabilities</h5>
                                            </div>
                                            <div style={{ width: "100%", height: 200 }}>
                                                <HorizontalBarGraph data={pentestDashboardReports.topRuleFailures} />
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            </div>
                        </section>
                    </>
                    : (
                        <section className="mb-5">
                            <div className="mx-4 mt-4">
                                {pentestDashboardReports !== null ? <>
                                    <h2 className="text-center">Penetration Testing Services</h2>
                                    <h5 className="text-center">Services we offer</h5>
                                    <section className="container">
                                        <div className="row mt-3">
                                            {pentestServices.map(({ title, desc, icon }, index) => (<div className="col-sm-4 mb-4">
                                                <div className="card custom-card d-flex flex-column align-items-center py-3 h-100">
                                                    <div className="card-body text-center text-white d-flex flex-column">
                                                        <div>
                                                            {icon}
                                                        </div>
                                                        <div className="mt-2 d-flex flex-column flex-grow-1">
                                                            <h3>{title}</h3>
                                                            <p className="flex-grow-1">{desc}</p>
                                                            <div>
                                                                <Button
                                                                    type="primary"
                                                                    size="large"
                                                                    onClick={() => onContactSupportClick(title)}
                                                                >
                                                                    <PiPhoneCallLight />
                                                                    Contact Support
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                        </div>
                                    </section>

                                </> :
                                    <p className="text-white text-center mb-4">We encountered an issue retrieving the pentesting reports. Please try again later....</p>}
                            </div>
                        </section>
                    )}
            </section>
        );
    }
}