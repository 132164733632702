import ReactMarkdown from "react-markdown";
import _ from "lodash";
import remarkBreaks from "remark-breaks";
import { Modal } from 'react-bootstrap';
import { Tabs, Tag } from 'antd';
import { getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import ViewSteps from "./viewSteps";

const LINK_COLOR = '#3b82f6';

export default function ViewDetailsModal({ visible, onClose, modalData, reportId }) {

    const renderReferenceLinks = (referenceText) => {
        if (!referenceText) return null;

        const links = referenceText.split('\n').filter(link => link.trim() !== '');

        return (
            <ul>
                {links.map((link, index) => (
                    <li key={index}>
                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: LINK_COLOR }}>
                            {link}
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderRiskOrConfidence = (val) => {
        switch (val.toLowerCase()) {
            case 'low':
                return (<Tag
                    style={{
                        color: getFontColor("LOW"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >LOW</Tag>);
            case 'medium':
                return (<Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >MEDIUM</Tag>);
            case 'high':
                return (<Tag
                    style={{
                        color: getFontColor("HIGH"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >HIGH</Tag>);
            case 'critical':
                return (<Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >CRITICAL</Tag>);
            default:
                <Tag>{val}</Tag>
        }
    }

    const renderDocument = () => {
        return <ViewSteps modalData={modalData} reportId={reportId}/>;
    }

    let tabItems = [
        {
            key: '1',
            label: 'Reference',
            children: (!_.isEmpty(modalData) && !_.isEmpty(modalData.reference)) ? <div>
                {renderReferenceLinks(modalData.reference.join("\n"))}
            </div> : <div className='alert alert-info px-2 py-1'>No data found</div>
        },
        {
            key: '2',
            label: 'Solution',
            children: (!_.isEmpty(modalData) && !_.isEmpty(modalData.solution)) ? <div>
                <ReactMarkdown
                    children={modalData.solution}
                    remarkPlugins={[remarkBreaks]}
                />
            </div> : <div className='alert alert-info px-2 py-1'>No data found</div>
        },
        {
            key: '3',
            label: 'Steps',
            children: renderDocument()
        },
    ];



    return (<>
        <Modal show={visible} onHide={onClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{`${modalData.cweid} Details`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                {!_.isEmpty(modalData) && <>
                    <div className="p-3">
                        <div>
                            <h6 className='d-inline '>Name:</h6>
                            <p className='d-inline ms-2'>{modalData.title}</p>
                        </div>
                        <div className="mt-3">
                            <h6 className='d-inline '>Description:</h6>
                            <p className='d-inline ms-2' style={{ maxHeight: "120px", overflowY: "auto" }}>{modalData.description}</p>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-4">
                                <h6 className='d-inline '>Status:</h6>
                                <span className="d-inline ms-2">
                                    {modalData.status.toLowerCase() === 'pass' ? (
                                        <CheckCircleFilled style={{ color: 'green' }} className="me-2" />
                                    ) : (
                                        <WarningFilled style={{ color: getStrokeColor('Critical') }} className="me-2" />
                                    )}
                                    {modalData.status}
                                </span>
                            </div>
                            <div className="col-lg-4"><h6 className='d-inline me-2'>Confidence:</h6>
                                {renderRiskOrConfidence(modalData.confidence)}</div>
                            <div className="col-lg-4"><h6 className='d-inline me-2'>Risk</h6>
                                {renderRiskOrConfidence(modalData.risk)}</div>
                        </div>
                        <div className="mt-3">
                            <Tabs defaultActiveKey="1" items={tabItems} style={{ color: '#e0e0e0' }} />
                        </div>
                    </div>
                </>}
            </Modal.Body>

        </Modal>
    </>)
}