import { message, Space, Switch, Table } from "antd"
import Loader from "../../../shared/sharedComponents/loader"
import { MdOutlineError } from "react-icons/md"
import { getStrokeColor } from "../../../shared/helper/genHelper"
import NoDataFound from "../noDataFound";
import { useEffect, useMemo, useState } from "react";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import _ from "lodash";
import axios from "axios";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import TableFilterDropdown from "../../../shared/sharedComponents/antdTableComponents/tableFilterDropDown";
import ViewDetailsModal from "./viewDetailsModal";

export default function SSLTabView({ reportId }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showAllComponents, setShowAllComponents] = useState(false);

    useEffect(() => {
        let reqBody = {
            reportId: reportId
        }
        setLoading(true)
        axios.post(`${GlobalConst.API_URL}/auth/dast/asset/report/vulnerability-view`, reqBody).then(op => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                let newData = [];
                if (!_.isEmpty(op.data.scanReport.sslAlerts)) {
                    _.forEach(op.data.scanReport.sslAlerts, (el, key) => {
                        newData.push({
                            ...el,
                            key: key.toString(),
                            name: el.name,
                            status: el.status,
                            description: el.description,
                        })
                    });
                    //console.log("newData : ", newData);
                    setData(newData);
                } else {
                    setData([]);
                }
            }
        }).catch((e) => {
            if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data) && !_.isEmpty(e.response.data.errorMessage)) {
                message.error(e.response.data.errorMessage);
            }
            console.log(e);
        })
            .finally(() => {
                setLoading(false);
            })
    }, [reportId]);

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedData(null);
    };

    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
    }

    const columns = [{
        title: 'Sno',
        dataIndex: "sno",
        key: 'sno',
        render: (text) => text,
        width: 100,
        onCell: () => ({
            style: {
                paddingLeft: '50px'
            }
        }),
        onHeaderCell: () => ({
            style: {
                paddingLeft: '50px'
            }
        })
    }, {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "30%",
        ellipsis: true
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "50%",
        ellipsis: true
    },
    {
        title: 'Status',
        dataIndex: "status",
        key: 'status',
        width: 100,
        filters: [
            { text: 'Pass', value: 'Pass' },
            { text: 'Fail', value: 'Fail' },
            { text: 'N/A', value: 'NA' }
        ],
        onFilter: (value, record) => record.status.toLowerCase() === value.toLowerCase(),
        filterDropdown: (props) => <TableFilterDropdown {...props} />,
        render: (value) => (
            <span>
                {value.toLowerCase() === 'pass' ? (
                    <CheckCircleFilled style={{ color: 'green' }} className="me-2" />
                ) : (
                    <WarningFilled style={{ color: getStrokeColor('Critical') }} className="me-2" />
                )}
                {value}
            </span>
        ),
    }, {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: '12%',
        render: (_, row) => {
            return (
                <Space size="middle">
                    <a href='#'
                        onClick={(e) => {
                            e.preventDefault();
                            setIsModalVisible(true);
                            console.log("row", row)
                            setSelectedData(row);
                        }}
                    >View Detail</a>
                </Space>
            );
        },
    },];

    const myUpdatedData = useMemo(() => {
        return _.isEmpty(data) ? [] : data.map((row, index) => {
            return {
                ...row,
                sno: index + 1,
                key: `ssl-check-${index}`
            }
        });
    }, [data]);

    const filteredData = showAllComponents ? myUpdatedData : myUpdatedData.filter(item => item.status.toLowerCase() === 'fail');

    if (loading) {
        return (<Loader />);
    } else {
        return (<section className="my-2">
            <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2'>
                    <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show All
                  </div>
            <div>
                <Table
                    className='custom-table'
                    columns={columns}
                    dataSource={filteredData}
                    size="small"
                    locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                    pagination={{
                        showTotal: (total, range) => {
                            return (
                                <span className="text-white">
                                    {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                                </span>
                            );
                        },
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        position: ["bottom", "left"],
                        className: "searchPagePagination px-0",
                        pageSizeOptions: [10, 20, 25],
                    }}
                />
                {isModalVisible && <ViewDetailsModal
                    details={selectedData ? selectedData : undefined}
                    cweId={selectedData ? selectedData.cweid : undefined}
                    visible={isModalVisible}
                    onClose={handleCloseModal}
                />}
            </div>
        </section>);
    }
}