import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import UserLayout from './userLayout';
// import ScrollToTop from '../helper/scrollToTopHelper';
import Login from '../../pages/authentication/login';
// import { updateToken } from '../redux/reducers/authReducer';
import _ from 'lodash';
// import { useDispatch } from 'react-redux';

import ProtectedRoute from '../guards/protectedRoute';
import NonProtectedRoute from '../guards/nonProtectedRoute';
import ForgotPasswordPage from '../../pages/authentication/login/forgotPasswordPage';
import CreateAccountPage from '../../pages/authentication/login/createAccountPage';
import { GlobalConst } from '../appConfig/globalConst';
import axios from 'axios';




/* And designs library */

export default function AppLayout() {

    // const dispatch = useDispatch();
    // const reduxState = useSelector(state => state)
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getLocalStorage() {
            let token = await localStorage.getItem("__t");
            if (!_.isEmpty(token)) {
                // dispatch(updateToken(token));
                setIsAuthenticated(true);
                getisFirstLogin();
            }
            setIsLoading(false);
        }

        getLocalStorage();

        // console.log("I am refreshed", isAuthenticated);
    }, []);

    const getisFirstLogin = async () => {

        let isDemoUser = await localStorage.getItem('isDemoUser');
        let normalUser = await localStorage.getItem('normalUser');

        if (_.isEmpty(isDemoUser) && _.isEmpty(normalUser)) {

            axios.post(`${GlobalConst.API_URL}/auth/foss/check-user`, { "idpType": "github" })
                .then(op => {
                    // console.log("I am is first login here::", op);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        // return op.data.isFirstLogin;
                        if (_.has(op.data, 'disableScans') && op.data.disableScans) {
                            localStorage.setItem('isDemoUser', 'true');
                        }
                        else {
                            localStorage.setItem('normalUser', 'true');
                        }
                    }
                    return false;
                })
                .catch((e) => {
                    localStorage.removeItem('__t');
                    localStorage.removeItem('__r');
                    window.location.href = GlobalConst.BASE_URL + '/login';
                    console.log("Exception: ", e);
                    return null;
                })
        }


    }

    // Render loading indicator while loading
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <BrowserRouter>
            {/* <ScrollToTop> */}
            <Switch>
                {/* <Route path="/login" component={Login} /> */}
                {/* <Route path="/main" component={DashboardLayout} /> */}
                {/* <Route path="/scanner-page" component={ScannerLayout} /> */}
                {/* <Route path="/scanner" component={ScannerLayout} />
                <Route path="/scan-details" component={ScannerLayout} />
                <Route path="/scan-details/:id/" component={ScannerLayout} /> */}
                {/* <Route path="/cve/:id/" component={DashboardLayout} /> */}
                {/* <Route path="/details/:id/:page" component={DashboardLayout} /> */}
                {/* <Route path="/dashboard" component={UserLayout} />
                <Route path="/scanned-list" component={UserLayout} /> */}
                {/* <Route path="/dashboard-new" component={UserLayout} /> */}
                {/* <Route path={'/sca-dashboard'} component={UserLayout} />
                <Route path={'/dashboard-scan-details/:id'} component={UserLayout} />
                <Route path={'/extract-cve'} component={UserLayout} />

                <Route path={'/fix-vulnerabilities/:id'} component={UserLayout} />

                <Route path={'/welcome-screen'} component={UserLayout} />
                <Route path={'/settings'} component={UserLayout} />
                <Route path={'/user-prefrences'} component={UserLayout} />


                <Route path={'/vul-site'} component={UserLayout} /> */}

                {/* <Route path="/animation" component={AnimatedDesign} /> */}





                <Route path="/login">
                    {/* {isAuthenticated ? <Redirect to="/sca-dashboard" /> : <Login />} */}
                    <NonProtectedRoute path="/login" component={Login} isAuthenticated={isAuthenticated} />
                </Route>
                <Route path="/forgot-password">
                    {/* {isAuthenticated ? <Redirect to="/sca-dashboard" /> : <Login />} */}
                    <NonProtectedRoute path="/forgot-password" component={ForgotPasswordPage} isAuthenticated={isAuthenticated} />
                </Route>
                <Route path="/create-new-account">
                    {/* {isAuthenticated ? <Redirect to="/sca-dashboard" /> : <Login />} */}
                    <NonProtectedRoute path="/create-new-account" component={CreateAccountPage} isAuthenticated={isAuthenticated} />
                </Route>
                {/* <ProtectedRoute
                    path="/dashboard"
                    component={Dashboard}
                    isAuthenticated={isAuthenticated}
                /> */}

                {/* <ProtectedRoute path="/scanner" component={ScannerLayout} isAuthenticated={isAuthenticated} /> */}
                {/* <ProtectedRoute path="/scan-details" component={ScannerLayout} isAuthenticated={isAuthenticated} /> */}
                {/* <ProtectedRoute path="/scan-details/:id/" component={ScannerLayout} isAuthenticated={isAuthenticated} /> */}
                {/* <ProtectedRoute path="/dashboard" component={UserLayout} isAuthenticated={isAuthenticated} /> */}
                <ProtectedRoute path={"/manual-scanner"} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={"/unified-dashboard"} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={"/dashboard"} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={"/scanned-list"} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/sca-dashboard'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/dashboard-scan-details/:id'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/extract-cve'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/fix-vulnerabilities/:id'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/welcome-screen'} component={UserLayout} isAuthenticated={isAuthenticated} />
                {/* <ProtectedRoute path={'/settings'} component={UserLayout} isAuthenticated={isAuthenticated} /> */}
                <ProtectedRoute path={'/user-prefrences'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/vul-site'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/downloads'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/:id'} component={UserLayout} isAuthenticated={isAuthenticated} />


                <ProtectedRoute path={'/integrations-list/notifications/microsoft-teams-notification-settings'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/notifications/slack-notification-settings'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/notifications/email-notification-settings'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/docker-registry'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/ibm-registry'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/google-registry'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/jfrog-registry'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/azure-registry'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list/aws-registry'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/integrations-list'} component={UserLayout} isAuthenticated={isAuthenticated} />

                {/* <ProtectedRoute path={'/subscriptions'} component={UserLayout} isAuthenticated={isAuthenticated} /> */}

                <ProtectedRoute path={'/team'} component={UserLayout} isAuthenticated={isAuthenticated} />

                <ProtectedRoute path={'/plans-and-pricing'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/myplan'} component={UserLayout} isAuthenticated={isAuthenticated} />


                <ProtectedRoute path={'/advance-cloud-security-dashboard/:id'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/cloud-security-dashboard'} component={UserLayout} isAuthenticated={isAuthenticated} />



                <ProtectedRoute path={'/sast-dashboard'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/sast-advance-dashboard/:id'} component={UserLayout} isAuthenticated={isAuthenticated} />

                <ProtectedRoute path={'/api-security-dashboard'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/api-security-advance-dashboard/:id'} component={UserLayout} isAuthenticated={isAuthenticated} />

                <ProtectedRoute path={'/web-security-dashboard'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/web-security-advance-dashboard/:id'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/ai-threat-vision'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/container-security'} component={UserLayout} isAuthenticated={isAuthenticated} />
                <ProtectedRoute path={'/'} component={UserLayout} isAuthenticated={isAuthenticated} />
                {/* <Route path="/">
                    <Redirect to="/sca-dashboard" />
                </Route> */}
            </Switch>
            {/* </ScrollToTop> */}
        </BrowserRouter>
    );
}