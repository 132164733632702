import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from 'antd';
import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CiLink } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from 'react-redux';
import { addUpdateDastDashboardTableData, setDastScanTriggeredState, setIScanModalState } from '../../shared/redux/reducers/dastReducer';
import { handleDastFormSubmit } from '../../shared/helper/apiHelper';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Scheduler from './scheduler';

dayjs.extend(customParseFormat);

export default function ScanPublicWebsite({ fromModal }) {
    const formikRef = useRef(null);
    const dispatch = useDispatch();
    const ssPlaceholderUrl = 'https://example-secured.com/';
    const pwsPlaceholderUrl = 'https://example.com/';

    const [showClientSecret, setShowClientSecret] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordUname, setShowPasswordUname] = useState(false);

    const [schedulerList, setSchedulerList] = useState([]);

    const handleFormSubmit = (values, setSubmitting) => {


        let isDemoUser = localStorage.getItem('isDemoUser');
        if (isDemoUser && isDemoUser === 'true') {
            alert("Scan has been disabled for this account");
            setSubmitting(false);
        }
        else {
            setSubmitting(true);
            dispatch(setDastScanTriggeredState(true));
            // let scanAuthType = activeTab === '1' ? 'NO-AUTH' : authType;
            // let scanType = 'website';
            // values.scanAuthType = scanAuthType;
            values.scanType = 'website';
            let toScanData = {};
            if (!values.isSecureSite) {
                toScanData = { scanType: 'website', scanAuthType: 'NO-AUTH', url: values.url, triggerNow: values.triggerNow };
            }
            // console.log("I am the to scan data :", toScanData)

            // console.log("values", values);

            // return;

            let response = handleDastFormSubmit(!values.isSecureSite ? toScanData : values, 'scan', schedulerList);
            // console.log("iscan response : ", response);
            if (response) {

                // console.log("I am the from modal ", fromModal);
                dispatch(addUpdateDastDashboardTableData(response));
                if (fromModal) {
                    dispatch(setIScanModalState(false));
                }
                else {
                    window.location.reload();
                }

                // dispatch(setIScanModalState(false));
            }
            else {
                // setSubmitting(false);
            }
        }
    }

    const validationSchema = Yup.object().shape({
        url: Yup.string()
            .required('Website URL is required')
            .url('Please enter a valid URL')
            .test('is-valid-protocol', 'Please enter a valid HTTP or HTTPS URL', function (value) {
                const { isSecureSite } = this.parent;
                if (isSecureSite) {
                    return value && value.startsWith('https://');
                }
                return value && (value.startsWith('http://') || value.startsWith('https://'));
            }),

        isSecureSite: Yup.boolean(),

        scanAuthType: Yup.string().when('isSecureSite', {
            is: true,
            then: () => Yup.string()
                .required('Authentication type is required')
                .oneOf(['NO-AUTH', 'OAUTH2', 'USERNAME-PASSWORD'], 'Invalid authentication type')
        }),

        // OAuth2 fields
        authUrl: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'OAUTH2',
            then: () => Yup.string()
                .required('Auth URL is required')
                .url('Please enter a valid Auth URL')
                .matches(/^https:\/\//, 'Auth URL must use HTTPS')
        }),

        realmName: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'OAUTH2',
            then: () => Yup.string()
                .required('Realm name is required')
                .min(2, 'Realm name must be at least 2 characters')
        }),

        clientId: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'OAUTH2',
            then: () => Yup.string()
                .required('Client ID is required')
                .min(3, 'Client ID must be at least 3 characters')
        }),

        clientSecret: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'OAUTH2',
            then: () => Yup.string()
                .required('Client Secret is required')
                .min(8, 'Client Secret must be at least 8 characters')
        }),

        userId: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'OAUTH2',
            then: () => Yup.string()
                .required('User ID is required')
                .min(3, 'User ID must be at least 3 characters')
        }),

        password: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'OAUTH2',
            then: () => Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
                )
        }),

        // Username-Password fields
        authenticatorName: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'USERNAME-PASSWORD',
            then: () => Yup.string()
                .required('Authenticator name is required')
                .min(2, 'Authenticator name must be at least 2 characters')
        }),

        userIdUname: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'USERNAME-PASSWORD',
            then: () => Yup.string()
                .required('User ID is required')
                .min(3, 'User ID must be at least 3 characters')
        }),

        passwordUname: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'USERNAME-PASSWORD',
            then: () => Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
                )
        })
    });


    return (
        <section className="card custom-card">
            <div className="card-body">
                <h5 className='mb-3'>Scan Web Application</h5>
                <div>
                    <Formik
                        initialValues={{
                            url: '',
                            authUrl: '',
                            realmName: '',
                            clientId: '',
                            clientSecret: '',
                            scanAuthType: 'NO-AUTH',
                            userId: '',
                            password: '',
                            schemaFile: null,
                            isSecureSite: false,
                            triggerNow: true,
                            certificateType: '',
                            sslVersion: '',
                            authenticatorName: '',
                            userIdUname: '',
                            passwordUname: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleFormSubmit(values, setSubmitting);
                        }}
                        innerRef={formikRef}
                    >
                        {
                            ({ isSubmitting, setFieldValue, setTouched, values }) => {

                                return (
                                    <Form>
                                        <div className="mb-3">
                                            <div className="input-group me-3">
                                                <span className="input-group-text"><CiLink /></span>
                                                <Field
                                                    name="url"
                                                    type="text"
                                                    className="input input-rounded form-control"
                                                    aria-describedby="Website URL"
                                                    placeholder={`Enter a website URL to trigger Web Security Scan for e.g. ${values.isSecureSite ? ssPlaceholderUrl : pwsPlaceholderUrl}`}
                                                    onPaste={(e) => {
                                                        e.preventDefault();
                                                        const pastedText = e.clipboardData.getData('text');
                                                        let inputValue = pastedText.trim();
                                                        if (inputValue && !inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                                                            inputValue = `https://${inputValue}`;
                                                        }
                                                        setFieldValue('url', inputValue);
                                                    }}
                                                />
                                            </div>
                                            <ErrorMessage name="url">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-check">
                                                <Field
                                                    type="checkbox"
                                                    name="isSecureSite"
                                                    className="form-check-input me-2"
                                                    disabled={!values.url}
                                                />
                                                Run Authenticated Scan
                                            </label>
                                        </div>

                                        {values.isSecureSite && (
                                            <div className="secure-site-fields mb-4">
                                                <div className="mb-3">
                                                    <label htmlFor='scanAuthType' className="form-label">Authentication Type</label>
                                                    <div className="input-group mb-3">
                                                        <Field
                                                            id="scanAuthType"
                                                            as="select"
                                                            name="scanAuthType"
                                                            className="form-select"
                                                            onChange={(e) => {
                                                                const newAuthType = e.target.value;
                                                                setFieldValue('scanAuthType', newAuthType);

                                                                // Clear fields based on selected auth type
                                                                if (newAuthType === 'OAUTH2') {
                                                                    ['authenticatorName', 'userIdUname', 'passwordUname'].forEach(
                                                                        field => setFieldValue(field, '')
                                                                    );
                                                                } else if (newAuthType === 'USERNAME-PASSWORD') {
                                                                    ['authUrl', 'realmName', 'clientId', 'clientSecret', 'userId', 'password'].forEach(
                                                                        field => setFieldValue(field, '')
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <option value="NO-AUTH">Select Authentication Type</option>
                                                            <option value="OAUTH2">OAUTH2</option>
                                                            <option value="USERNAME-PASSWORD">Username Password</option>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="scanAuthType">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                </div>

                                                {values.scanAuthType === 'OAUTH2' && (
                                                    <>
                                                        <div className="mb-3">
                                                            <Field
                                                                id="authUrl"
                                                                name="authUrl"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Auth URL"
                                                            />
                                                            <ErrorMessage name="authUrl">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Field
                                                                id="realmName"
                                                                name="realmName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Realm Name"
                                                            />
                                                            <ErrorMessage name="realmName">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Field
                                                                id="clientId"
                                                                name="clientId"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Client ID"
                                                            />
                                                            <ErrorMessage name="clientId">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="input-group me-3">
                                                                <Field
                                                                    id="clientSecret"
                                                                    name="clientSecret"
                                                                    type={showClientSecret ? 'text' : 'password'}
                                                                    className="form-control"
                                                                    placeholder="Enter Client Secret"
                                                                />
                                                                <span className="input-group-text" onClick={(e) => setShowClientSecret(!showClientSecret)}>
                                                                    {showClientSecret ? <FaRegEye /> : <FaRegEyeSlash />}
                                                                </span>
                                                            </div>
                                                            <ErrorMessage name="clientSecret">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Field
                                                                id="userId"
                                                                name="userId"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter User Id"
                                                            />
                                                            <ErrorMessage name="userId">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="input-group me-3">
                                                                <Field
                                                                    id="password"
                                                                    name="password"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    className="form-control"
                                                                    placeholder="Enter Password"
                                                                />
                                                                <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                                                    {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                                                                </span>
                                                            </div>
                                                            <ErrorMessage name="password">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>
                                                    </>
                                                )}

                                                {values.scanAuthType === 'USERNAME-PASSWORD' && (
                                                    <>
                                                        <div className="mb-3">
                                                            <Field
                                                                id="authenticatorName"
                                                                name="authenticatorName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Authenticator Name (Provided by Sec1)"
                                                            />
                                                            <ErrorMessage name="authenticatorName">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Field
                                                                id="userIdUname"
                                                                name="userIdUname"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter User Id"
                                                            />
                                                            <ErrorMessage name="userIdUname">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="input-group me-3">
                                                                <Field
                                                                    id="passwordUname"
                                                                    name="passwordUname"
                                                                    type={showPasswordUname ? 'text' : 'password'}
                                                                    className="form-control"
                                                                    placeholder="Enter Password"
                                                                />
                                                                <span className="input-group-text" onClick={() => setShowPasswordUname(!showPasswordUname)}>
                                                                    {showPasswordUname ? <FaRegEye /> : <FaRegEyeSlash />}
                                                                </span>
                                                            </div>
                                                            <ErrorMessage name="passwordUname">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        <Divider />
                                        <Scheduler schedulerList={schedulerList} setSchedulerList={setSchedulerList} />
                                        <div className="mb-4">
                                            <label className="form-check">
                                                <Field
                                                    type="checkbox"
                                                    name="triggerNow"
                                                    className="form-check-input me-2"
                                                />
                                                Trigger scan now
                                            </label>
                                        </div>
                                        <div className="">
                                            <Button htmlType="submit" type="primary" size={'large'}
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                            >
                                                Scan
                                            </Button>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </div>
            </div>
        </section>
    );
}