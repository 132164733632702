import { message, Table, Tag } from "antd";
import { getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import NoDataFound from "./noDataFound";
import { MdOutlineError } from "react-icons/md";
import _ from "lodash"
import { useEffect, useState } from "react";
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import Loader from "../../shared/sharedComponents/loader";

const secondLevelRowRender = (record) => {
    let subData = []
    if (!_.isEmpty(record) && !_.isEmpty(record.occurrences)) {
        let newData = [];

        _.forEach(record.occurrences, (row, i) => {
            console.log("urls",row)
            newData.push({
                key: i.toString(),
                url: row.url,
            })
        });
        subData = newData;
    }
    else {
        subData = [];
    }

    subData = subData.map((row, index) => {

        return {
            key: `url-table-${index}`,
            sno: index + 1,
            ...row,
        };
    });

    const columns = [{
        title: 'Sno',
        dataIndex: 'sno',
        width: 60
    },
    {
        title: 'Occurrences',
        key: 'url',
        dataIndex: 'url',
    }]
    return (<Table
        columns={columns}
        dataSource={subData}
        size="small"
        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
    />)
}

const firstLevelRowRender = (record) => {
    let subData = []
    if (!_.isEmpty(record) && !_.isEmpty(record.vulnerabilities)) {
        let newData = [];

        _.forEach(record.vulnerabilities, (row, i) => {
            // console.log("Row", row)
            newData.push({
                key: row.id.toString(),
                cweId: `CWE-${row.id}`,
                vulTitle: row.title,
                severity: row.severity,
                "critical": row.critical,
                "high": row.high,
                "medium": row.medium,
                "low": row.low,
                "total": row.total,
                occurrences: row.occurrences
            })
        });
        // console.log("newData", newData);
        subData = newData;
    }
    else {
        subData = [];
    }

    subData = subData.map((row, index) => {

        return {
            key: `owasp-table-${index}`,
            sno: index + 1,
            ...row,
        };
    });

    const columns = [{
        title: 'Sno',
        dataIndex: 'sno',
        width: 60
    },
    {
        title: 'Vulnerability Title',
        key: 'vulTitle',
        dataIndex: 'vulTitle',
        width: '30%',
    },
    {
        title: 'CWE ID',
        dataIndex: 'cweId',
        key: 'cweId',
        width: '10%',
    },
    {
        title: 'Severity',
        dataIndex: 'severity',
        key: 'severity',
        align: 'center',
        width: '12%',
        render: (index, row) => {
            if (!_.isEmpty(row) && !_.isEmpty(row.severity)) {
                return (
                    <Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600, textTransform: 'capitalize' }} color={getStrokeColor(row.severity.toUpperCase())}>
                        {row.severity}
                    </Tag>);
            }
            else {
                return '-';
            }
        },
    },
    {
        title: 'Critical',
        dataIndex: 'critical',
        key: 'critical',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {row.critical}
                </Tag>
            </div>);
        }
    },
    {
        title: 'High',
        dataIndex: 'high',
        key: 'high',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {row.high}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Medium',
        dataIndex: 'medium',
        key: 'medium',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {row.medium}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Low',
        dataIndex: 'low',
        key: 'low',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {row.low}
                </Tag>
            </div>);
        }
    },
    ]
    return (<Table columns={columns} dataSource={subData}
        expandable={{
            expandedRowRender: (record) => secondLevelRowRender(record),
        }}
        size="small"
        locale={{ emptyText: <NoDataFound title={'No Data'} /> }} />
    )
}

export default function OwaspTabView({ reportId }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let reqBody = {
            reportId: reportId
        }
        setLoading(true)
        axios.post(`${GlobalConst.API_URL}/auth/dast/asset/report/owasp-view`, reqBody).then(op => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                let newData = [];
                _.forEach(op.data.owaspComplianceSummary.categories, (el, key) => {
                    if (el.status.toLowerCase() === "fail") {
                        let categoryObj = {
                            key: key.toString(),
                            "name": el.name,
                            "status": el.status,
                            "total": el.totalCount,
                            "critical": el.severityCounts.critical,
                            "high": el.severityCounts.high,
                            "medium": el.severityCounts.medium,
                            "low": el.severityCounts.low,
                        }

                        let subData = []
                        _.forEach(el.vulnerabilities, (vul, key) => {
                            subData.push({
                                "id": vul.id,
                                "title": vul.title,
                                "severity": vul.severity,
                                "critical": vul.vulnerabilityCounts.critical,
                                "high": vul.vulnerabilityCounts.high,
                                "medium": vul.vulnerabilityCounts.medium,
                                "low": vul.vulnerabilityCounts.low,
                                "total": vul.vulnerabilityCounts.total,
                                occurrences: vul.occurrences
                            })
                        })

                        categoryObj = {
                            ...categoryObj,
                            "vulnerabilities": subData
                        }
                        newData.push(categoryObj)
                    }

                })
                setData(newData)
            }
        }).catch((e) => {
            if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data) && !_.isEmpty(e.response.data.errorMessage)) {
                message.error(e.response.data.errorMessage);
            }
            console.log(e)
        })
            .finally(() => {
                setLoading(false)
            })
    }, [reportId])

    const columns = [{
        title: "Title",
        dataIndex: "name",
        key: "name"
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: 'center'
    },
    {
        title: 'Critical',
        dataIndex: 'critical',
        key: 'critical',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {row.critical}
                </Tag>
            </div>);
        }
    },
    {
        title: 'High',
        dataIndex: 'high',
        key: 'high',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {row.high}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Medium',
        dataIndex: 'medium',
        key: 'medium',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {row.medium}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Low',
        dataIndex: 'low',
        key: 'low',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {row.low}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Status',
        key: 'status',
        width: 100,
        render: (_, record) => (
            <span>
                <MdOutlineError style={{ color: getStrokeColor('Critical') }} className="me-2" />
                {record.status}
            </span>
        ),
    },]

    if (loading) {
        return (<Loader />)
    } else {
        return (<section className="my-2">
            <div>
                <Table
                    className='custom-table'
                    columns={columns}
                    dataSource={data}
                    expandable={{
                        expandedRowRender: (record) => firstLevelRowRender(record),
                    }}
                    size="small"
                    locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                />
            </div>
        </section>)
    }

}