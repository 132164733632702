import { Button, notification } from "antd"
import { PiPhoneCallLight } from "react-icons/pi";
import Loader from './loader';
import { GlobalConst } from "../appConfig/globalConst";
import { useSelector } from "react-redux";
import _ from "lodash"
import { useHistory } from "react-router-dom";
import { useSupport } from "../hooks/contact-support";

const getUnsubscribedProductName = (type) => {
    let name = "";
    switch(type){
        case GlobalConst.PRODUCT_KEYS.SCA:
            name = "SCA";
        break;
        case GlobalConst.PRODUCT_KEYS.SAST:
            name = "SAST";
        break;
        case GlobalConst.PRODUCT_KEYS.DAST:
            name = "Web Security";
        break;
        case GlobalConst.PRODUCT_KEYS.CLOUD_SECURITY:
            name = "Cloud Security";
        break;
        case GlobalConst.PRODUCT_KEYS.KUBERNETES:
            name = "Kubernetes Security";
        break;
        case GlobalConst.PRODUCT_KEYS.CONTEK:
            name = "Container Security";
        break;
        case GlobalConst.PRODUCT_KEYS.API:
            name = "API Security";
        break;
        case GlobalConst.PRODUCT_KEYS.THREAT_VISION:
            name = "Threat Vision";
        break;
        default:
            name = ""
    }
    return name;
}

export const NoSubscription = ({productType}) => {
    // const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);
    const { data, loading, supportAPI } = useSupport();
    const reduxStore = useSelector(state => state);
    const history = useHistory();

    const handleGetSubscription = (e) => {
        e.preventDefault();
        supportAPI({
            email: reduxStore.authReducer.userDetails.email,
            product: `${getUnsubscribedProductName(productType)}-Subscription`,
            source: "This support request is from Sec1 Unified Application"
        });
    }

    const handleDashboardNavigation = () => {
        history.push('/dashboard')
    }

    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    {!_.isEmpty(data) ?
                        <>
                            <p className="mb-4 text-center">
                                <div className="card custom-card border-success">
                                    <div className="card-body">
                                        <h3 className="mb-2">Thank you for reaching out!</h3>
                                        <p className="mb-1">{`Our support team will review your request and contact you shortly to discuss the available subscription options and how you can access the advanced ${getUnsubscribedProductName(productType)} features.`}</p>
                                        <p>We appreciate your patience and look forward to assisting you!</p>
                                    </div>
                                </div>
                            </p>
                            <Button
                                onClick={handleDashboardNavigation}
                                type="primary"
                                size="large"
                            >
                                Go Back To Dashboard
                            </Button>
                        </> : <>
                            <h2 className="mb-3">{`Unlock ${getUnsubscribedProductName(productType)} Features`}</h2>
                            <p className="mb-4 text-center">{`You’ve tried to access a ${getUnsubscribedProductName(productType)} module of Sec1 that is not included in your current subscription.`}<br />
                                {`Contact our support team to explore subscription options and gain access to advanced ${getUnsubscribedProductName(productType)} features.`}</p>
                            <Button
                                onClick={handleGetSubscription}
                                type="primary"
                                size="large"
                            >
                                <PiPhoneCallLight />
                                Contact Support
                            </Button>
                        </>}
                </div>

            </div>
        </div>
        {loading && <Loader />}
    </section>)
}
