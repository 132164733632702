import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks';
import { getFontColor, getStrokeColor } from "../../../shared/helper/genHelper";
import { Tag } from "antd";
const LINK_COLOR = '#3b82f6';

export default function ViewDetailsModal({ details, cweId, visible, onClose }) {

    const renderReferenceLinks = (referenceText) => {
        if (!referenceText) return null;

        const links = referenceText.split('\n').filter(link => link.trim() !== '');

        return (
            <ul>
                {links.map((link, index) => (
                    <li key={index}>
                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: LINK_COLOR }}>
                            {link}
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderConfidence = (val) => {
        switch (val.toLowerCase()) {
            case 'low':
                return (<Tag
                    style={{
                        color: getFontColor("LOW"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >LOW</Tag>);
            case 'medium':
                return (<Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >MEDIUM</Tag>);
            case 'high':
                return (<Tag
                    style={{
                        color: getFontColor("HIGH"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >HIGH</Tag>);
            case 'critical':
                return (<Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >CRITICAL</Tag>);
            default:
                <Tag>{val}</Tag>
        }
    }

    return (<Modal show={visible} onHide={onClose} size='lg' >
        <Modal.Header closeButton>
            <Modal.Title>{`${cweId} Details`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
            <div className='p-3 border-bottom'>
                <h5 className='d-inline'>Name:</h5>
                <span className="ms-2">{details.name}</span>
            </div>
            <div className='p-3 border-bottom'>
                <h5 className='d-inline'>Description:</h5>
                <span className="ms-2">{details.description}</span>
            </div>
            <div className='p-3 border-bottom'>
                <h5 className='d-inline'>Status:</h5>
                <span className="ms-2">
                    {details.status.toLowerCase() === 'pass' ? (
                        <CheckCircleFilled style={{ color: 'green' }} className="me-2" />
                    ) : (
                        <WarningFilled style={{ color: getStrokeColor('Critical') }} className="me-2" />
                    )}
                    {details.status}
                </span>
            </div>
            <div className='p-3 border-bottom'>
                <h5 className='d-inline'>Confidence:</h5>
                <span className="ms-2">
                    {renderConfidence(details.confidence)}
                </span>
            </div>
            <div className='p-3 border-bottom'>
                <h5 className='d-inline'>Risk:</h5>
                <span className="ms-2">
                    {renderConfidence(details.risk)}
                </span>
            </div>
            <div className='p-3 border-bottom'>
                <h5 className='mb-2'>Solution:</h5>
                <div>
                    <ReactMarkdown
                        children={details.solution}
                        remarkPlugins={[remarkBreaks]}
                    />
                </div>
            </div>
            <div className='p-3 mb-3'>
                <h5 className='mb-2'>References:</h5>
                <div>
                    {renderReferenceLinks(details.reference)}
                </div>
            </div>
        </Modal.Body>
    </Modal>)
}