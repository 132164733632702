import { createSlice } from "@reduxjs/toolkit";

const unifiedReducer = createSlice({
    name: 'unifiedDashboadReducer',
    initialState: {
        summaryReports: {}
    },
    reducers: {
        updateUnifiedReports: (state,action) => {
            state.summaryReports = action.payload;
        },
    }
})

export const { updateUnifiedReports } = unifiedReducer.actions;

export default unifiedReducer.reducer;