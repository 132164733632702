import _ from 'lodash';
import moment from "moment";
import { Tooltip, Tag, Space, Table } from "antd";
import { getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { AiOutlineDashboard } from "react-icons/ai";

export default function PentestReportTable({ pentestReports }) {

    const openChildPage = (e, reportId) => {
        e.preventDefault();
        window.open(`/penetration-testing-advanced-dashboard/${reportId}`, '_self');
    }

    const columns = [
        {
            title: 'Sno',
            key: 'sno',
            render: (_, __, index) => index + 1,
            width: 20,
        },
        {
            title: "Asset Name",
            dataIndex: 'assetUrl',
            key: 'assetUrl',
            width: 350,
            render: (text, record, index, expand) => (
                <span>
                    <a onClick={(e) => openChildPage(e, record.reportId)} href="#" className="me-3 details-hover-animate" style={{ fontSize: 14 }}>{text}
                    </a>
                </span>
            )
        },
        {
            title: "Total",
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 95,
            render: (text, row) => {

                return text;
            },
        },
        {
            title: "Critical",
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            width: 105,
            render: (text, row) => (<Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "High",
            dataIndex: 'high',
            key: 'high',
            width: 85,
            align: 'center',
            render: (text, row) => (<Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "Medium",
            dataIndex: 'medium',
            key: 'medium',
            width: 105,
            align: 'center',
            render: (text, row) => (<Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "Low",
            dataIndex: 'low',
            key: 'low',
            width: 95,
            align: 'center',
            render: (text, row) => (<Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "Last Scan",
            dataIndex: 'scanDateTime',
            width: 150,
            key: 'scanDateTime',
            render: (text, row) => moment.utc(row.scanDateTime).local().fromNow()
        },
        {
            title: "Action",
            key: 'action',
            fixed: "right",
            width: 160,
            render: (_, row) => <Space>
                <a onClick={(e) => openChildPage(e, row.reportId)} href={`#`} className={`me-2 details-hover-animate`}>
                    <Tooltip title="Project Dashboard">
                        <AiOutlineDashboard style={{ fontSize: 20 }} />
                    </Tooltip>
                </a>
            </Space>,
        },
    ]

    const data = Array.isArray(pentestReports)
        ? pentestReports.map((row, index) => ({
            key: index,
            ...row
        }))
        : [];

    return (
        <>
            <Table
                columns={columns}
                className='custom-table mb-0 fixedScrollCustomTable'
                dataSource={data}
                size="small"
                locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                }}
            />
        </>
    );
}