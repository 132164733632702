import React, { useState, useEffect, useRef } from "react";
import { Radio, Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import PieChart from "../../shared/sharedComponents/echartsGraphs/pieChart";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { getTooltipName } from "../../shared/helper/getAssetImagesHelper";
import Loader from "../../shared/sharedComponents/loader";


import IScanModal from "./iScanModal";
import { setKubernetesSecurityDashboardTableData, setKubernetesSecurityScanTriggeredState, setIScanModalState, updateKubernetesSecurityTriggeredScans } from "../../shared/redux/reducers/kubernetesSecurityReducer";
import ComponentTable from "./componentTable";
import { SiKubernetes } from "react-icons/si";
import TrendGraph from "./trendGraph";
import CustomResultView from "../../shared/sharedComponents/customResultView";
import { TbGridScan } from "react-icons/tb";
import { capitalizeFirstLetter, getPlanTypeFromProductKey, getProductSubscription } from "../../shared/helper/genHelper";

export default function KubernetesDashboard() {

    const dispatch = useDispatch();
    const kubernetesSecurityReducer = useSelector(state => state.kubernetesSecurityReducer);

    const [issuesDistribution, setIssuesDistribution] = useState([]);
    const [trendValue, setTrendValue] = useState('7');
    const [overallCVESummary, setOverallCVESummary] = useState({});
    const [loading, setLoading] = useState(false);
    const [advanceSearchInfo, setAdvanceSearchInfo] = useState({
        package: "",
        version: "",
        cves: ""
    });
    const [isAdvanceSearchApplied, setIsAdvanceSearchApplied] = useState(false);
    const intervalId = useRef(null);
    const subscribedProductsReducer = useSelector(state => state.productsReducer);

    useEffect(() => {
        // console.log("I am triggered here", kubernetesSecurityReducer.kubernetesSecurityTriggeredScans);
        if (kubernetesSecurityReducer && !_.isEmpty(kubernetesSecurityReducer.kubernetesSecurityTriggeredScans)) {
            // Accumulate report IDs
            // setReportIds(prevReportIds => _.union(prevReportIds, kubernetesSecurityReducer.kubernetesSecurityTriggeredScans));

            // Clear previous interval if it exists
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
            // console.log("Inside the if block ", intervalId);
            // Set a new interval for polling
            intervalId.current = setInterval(() => {
                poll(kubernetesSecurityReducer.kubernetesSecurityTriggeredScans);
            }, GlobalConst.POLLING_LIMIT);

            // Cleanup interval on component unmount
            return () => {
                if (intervalId.current) {
                    clearInterval(intervalId.current);
                }
            };
        }

    }, [kubernetesSecurityReducer.kubernetesSecurityTriggeredScans]);

    const poll = (reportIds) => {
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/report/status`, { reportId: reportIds, type: "k8s" })
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    const updatedContainerTableData = !_.isEmpty(kubernetesSecurityReducer.kubernetesSecurityDashTableData) ? kubernetesSecurityReducer.kubernetesSecurityDashTableData.map(record => {
                        const isPresent = op.data.find(opRecord => opRecord.reportId === record.reportId);
                        return isPresent ? {
                            ...record,
                            overallStatus: _.has(isPresent, "status") ? isPresent.status : record.overallStatus
                        } : record;
                    }) : [];
                    const isAllScansFailed = op.data.every((scanResult) => _.has(scanResult, "status") && scanResult.status.toLowerCase() === "failed")
                    const initiatedScans = op.data.filter((scanResult) => _.has(scanResult, "status") && scanResult.status.toLowerCase() === "initiated").map((scanResult) => scanResult.reportId)
                    if (_.isEmpty(initiatedScans) || isAllScansFailed) {
                        clearInterval(intervalId.current);
                        dispatch(setKubernetesSecurityScanTriggeredState(false));
                        dispatch(updateKubernetesSecurityTriggeredScans([]));
                    }
                    dispatch(setKubernetesSecurityDashboardTableData(updatedContainerTableData));
                }
            })
            .catch(e => {
                // console.log("Exception: ", e.response.data);
                if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data) && !_.isEmpty(e.response.data.error)) {
                    clearInterval(intervalId.current);
                    dispatch(setKubernetesSecurityScanTriggeredState(false));
                    dispatch(updateKubernetesSecurityTriggeredScans([]));
                    window.location.reload();
                }


            });
    };


    useEffect(() => {
        if (kubernetesSecurityReducer.kubernetesSecurityScanTriggered === false) {
            getDashboardSummary();
        }
    }, [kubernetesSecurityReducer.kubernetesSecurityScanTriggered]);

    useEffect(() => {
        const processData = () => {
            const groupedData = _.chain(kubernetesSecurityReducer.kubernetesSecurityDashTableData)
                .groupBy('provider')
                .map((items, category) => ({
                    name: getTooltipName(category),
                    value: _.sumBy(items, item => item.criticalCve + item.highCve + item.mediumCve + item.lowCve)
                }))
                .value();

            setIssuesDistribution(groupedData);
        }

        if (!_.isEmpty(kubernetesSecurityReducer.kubernetesSecurityDashTableData)) {
            processData();
        }

    }, [kubernetesSecurityReducer.kubernetesSecurityDashTableData]);

    const getDashboardSummary = (searchedData) => {
        let reqBody = {}
        if (!_.isEmpty(searchedData)) {
            if (_.has(searchedData, "package")) {
                reqBody = {
                    ...reqBody,
                    "componentName": searchedData.package
                }
            }
            if (_.has(searchedData, "version")) {
                reqBody = {
                    ...reqBody,
                    "version": searchedData.version
                }
            }
            if (_.has(searchedData, "cves")) {
                reqBody = {
                    ...reqBody,
                    "cveId": searchedData.cves
                }
            }
        }
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/summary/dashboard`, reqBody)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setOverallCVESummary(op.data.overallCVESummary)
                    dispatch(setKubernetesSecurityDashboardTableData(op.data.overallResults));

                    if (!_.isEmpty(op.data.overallResults)) {
                        const initiatedScanReports = op.data.overallResults.filter((scanResult) => _.has(scanResult, "overallStatus") && scanResult.overallStatus.toLowerCase() === "initiated").map((scanResult) => scanResult.reportId)
                        if (!_.isEmpty(initiatedScanReports)) {
                            dispatch(setKubernetesSecurityScanTriggeredState(true));
                            dispatch(updateKubernetesSecurityTriggeredScans(initiatedScanReports));
                        }
                    }

                } else {
                    // setHasError(true);
                    setOverallCVESummary({})
                    dispatch(setKubernetesSecurityDashboardTableData(null));
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                // setHasError(true);
                setOverallCVESummary({})
                dispatch(setKubernetesSecurityDashboardTableData(null));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTrendFilterChange = (e) => {
        setTrendValue(e.target.value);
    };

    const handleDashboardReload = (e) => {
        // console.log("Handle dashboard reload click");
        window.location.reload();
    }

    const handleiScanClick = (e) => {
        dispatch(setIScanModalState(true));
    }

    const handleSearchChange = (searchParam, searchValue) => {
        setAdvanceSearchInfo({
            ...advanceSearchInfo,
            [searchParam]: !_.isEmpty(searchValue) && (searchParam === "cves" || searchParam === "version") ? searchValue.split(",") : searchValue
        })
    }

    const handleClearFilters = () => {
        setAdvanceSearchInfo({
            package: "",
            version: "",
            cves: ""
        })
    }

    const handleSearchSubmit = () => {
        setIsAdvanceSearchApplied(true)
        getDashboardSummary(advanceSearchInfo)
    }

    const handleResetAllFilters = () => {
        setAdvanceSearchInfo({
            package: "",
            version: "",
            cves: ""
        })
        setIsAdvanceSearchApplied(false)
        getDashboardSummary()
    }

    return (
        <>
            <section className="mx-3 my-2">
                <section className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <h2 style={{ lineHeight: 1 }} className="mb-2 text-white d-flex align-items-center justify-content-start">
                            <SiKubernetes className="me-2" alt="Kubernetes" />
                            Kubernetes Security
                        </h2>
                        <Tooltip title="Scan a repository">
                            <button className="btn btn-warning py-1 ms-3" onClick={handleiScanClick}>
                                <TbGridScan className={'me-2'} />

                                iScan
                            </button>
                        </Tooltip>
                        <Tooltip title="Refresh">
                            <button className="btn btn-primary py-1 ms-2" onClick={handleDashboardReload}>
                                <FontAwesomeIcon icon={faRotateRight} />
                            </button>
                        </Tooltip>

                    </div>
                    {!_.isEmpty(subscribedProductsReducer.subscribedProducts)
                        && !_.isEmpty(subscribedProductsReducer.subscribedProducts.activePlans)
                        && !_.isEmpty(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.KUBERNETES))
                        && <div>
                            <span className="product-plan-text">Plan - <span className={`${getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.KUBERNETES).sProductKey) === 'free' ? 'free-plan' : 'non-free-plan'}`}>
                                {capitalizeFirstLetter(getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.KUBERNETES).sProductKey))}
                            </span>
                            </span>
                        </div>
                    }
                </section>
                {(!_.isEmpty(kubernetesSecurityReducer.kubernetesSecurityDashTableData)
                    || (_.isEmpty(kubernetesSecurityReducer.kubernetesSecurityDashTableData) && isAdvanceSearchApplied))
                    && (
                        <>
                            <section className="mb-5">
                                <div className="row">
                                    <div className="col-lg-6 custom-border-right">
                                        <div className="p-3 ps-0 d-flex align-items-center w-100 h-100">
                                            <div className="card-body">
                                                <div className="d-flex flex-row align-items-start justify-content-between">
                                                    <div className="me-3">
                                                        <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Issues</p>
                                                        <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{(!_.isEmpty(overallCVESummary) && overallCVESummary.totalCve) ? overallCVESummary.totalCve : '-'}</h1>
                                                    </div>
                                                    <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                                        <div className="me-3 mb-2">
                                                            <DashboardSeverityIndicator severity={'Critical'} loadingRows={false} dashboardData={(!_.isEmpty(overallCVESummary) && overallCVESummary.criticalCve) ? overallCVESummary.criticalCve : '-'} />
                                                        </div>
                                                        <div className="me-3 mb-2">
                                                            <DashboardSeverityIndicator severity={'High'} loadingRows={false} dashboardData={(!_.isEmpty(overallCVESummary) && overallCVESummary.highCve) ? overallCVESummary.highCve : '-'} />
                                                        </div>
                                                        <div className="me-3 mb-2">
                                                            <DashboardSeverityIndicator severity={'Medium'} loadingRows={false} dashboardData={(!_.isEmpty(overallCVESummary, 'mediumCve') && overallCVESummary.mediumCve) ? overallCVESummary.mediumCve : '-'} />
                                                        </div>
                                                        <div className="me-3 mb-2">
                                                            <DashboardSeverityIndicator severity={'Low'} loadingRows={false} dashboardData={(!_.isEmpty(overallCVESummary, 'lowCve') && overallCVESummary.lowCve) ? overallCVESummary.lowCve : '-'} />
                                                        </div>
                                                        <div className="me-3 mb-2">
                                                            <DashboardSeverityIndicator severity={'NA'} loadingRows={false} dashboardData={(!_.isEmpty(overallCVESummary, 'naCve') && overallCVESummary.naCve) ? overallCVESummary.naCve : '-'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-2">
                                            <div className="card-body">
                                                <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                    <h5 className="fw-medium">Issues Trend</h5>
                                                    <div>
                                                        <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                            <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                            <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                            <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ height: 300 }}>

                                                    <TrendGraph trendValue={trendValue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="mb-5">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <ComponentTable data={kubernetesSecurityReducer.kubernetesSecurityDashTableData} advanceSearchInfo={advanceSearchInfo} handleSearchChange={handleSearchChange} handleSearchSubmit={handleSearchSubmit} handleClearFilters={handleClearFilters} isAdvanceSearchApplied={isAdvanceSearchApplied} handleResetAllFilters={handleResetAllFilters} />
                                    </div>
                                </div>
                            </section>
                        </>)}
                {
                    (_.isEmpty(kubernetesSecurityReducer.kubernetesSecurityDashTableData) && !isAdvanceSearchApplied)
                    && <div className="text-center mx-4 mt-4">
                        {kubernetesSecurityReducer.kubernetesSecurityDashTableData === null ? (
                            <CustomResultView
                                isAlert={true}
                                resultPrimaryText={`We encountered an issue retrieving the scanned kubernetes clusters. Please try again later.`}
                            />
                        ) : (!loading &&
                            <>
                                <NoDataFound
                                    title={"No Scans Found"}
                                    desc={`It looks like you haven't scanned any kubernetes clusters yet. Begin by
      scanning your first kubernetes cluster to get started.`}
                                />
                                <Tooltip title="Scan a kubernetes cluster">
                                    <button
                                        className="btn btn-warning py-1 ms-3"
                                        onClick={handleiScanClick}
                                    >
                                        <TbGridScan className={'me-2'} />

                                        iScan
                                    </button>
                                </Tooltip>
                            </>
                        )}
                    </div>}
            </section>
            {loading && <Loader />}
            {kubernetesSecurityReducer.iScanModalState && <IScanModal loading={loading} setLoading={setLoading} />}

        </>
    );



}