import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { GlobalConst } from "../../../shared/appConfig/globalConst";

export default function ProductWiseStats({
    data,
    isCritical
}) {
    const getOption = (result = [], isVulCritical = false) => {
        const seriesData = !isVulCritical 
        ? [
            {
                name: 'Critical',
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
                data: result.map(({ critical }) => critical),
                itemStyle: {
                    color: GlobalConst.CRITICAL_COLOR
                }
            },
            {
                name: 'High',
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
                data: result.map(({ high }) => high),
                itemStyle: {
                    color: GlobalConst.MEDIUM_COLOR
                }
            },
            {
                name: 'Medium',
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
                data: result.map(({ medium }) => medium),
                itemStyle: {
                    color: GlobalConst.HIGH_COLOR
                }
            },
            {
                name: 'Low',
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
                data: result.map(({ low }) => low),
                itemStyle: {
                    color: GlobalConst.LOW_COLOR
                }
            },
        ] : [
            {
                name: 'Critical',
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
                data: result.map(({ critical }) => critical),
                itemStyle: {
                    color: GlobalConst.CRITICAL_COLOR
                }
            }];

        return {
            title: {
                text: !isVulCritical ? "Vulnerability Distribution" : "Vulnerability Distribution(Critical)",
                textStyle: {
                    color: '#B9B8CE',
                    
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                top: 0,
                show: false
            },
            textStyle: {
                color: '#B9B8CE'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '15%',
                containLabel: true,
            },
            xAxis: [{  // Changed from yAxis
                type: 'category',
                data: result.map(({ productKey }) => getLabel(productKey)),
                axisLabel: {
                    interval: 0,
                },
                axisLine: {
                    show: true,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#484753'
                    }
                }
            }],
            yAxis: [{  // Changed from xAxis
                type: 'value',
                min: 0,
                minInterval: 1,
                axisLabel: {
                    interval: 0,
                    fontSize: 12,
                },
                splitLine: {
                    lineStyle: {
                        color: "#484753",
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#B9B8CE'
                    }
                },
            }],
            barWidth: 60,  // Adjusted for vertical orientation
            series: seriesData.map(item => ({
                name: item.name,
                type: 'bar',
                stack: 'total',
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: item.data,
                itemStyle: item.itemStyle,
                barGap: '30%',
            }))
        }
    };

    const [options, setOptions] = useState(getOption());

    useEffect(() => {
        if (!_.isEmpty(data)) {
            const result = Object.values(
                data.reduce((acc, curr) => {
                    if (!acc[curr.productKey]) {
                        acc[curr.productKey] = { ...curr };
                    } else {
                        acc[curr.productKey].critical += curr.critical;
                        acc[curr.productKey].high += curr.high;
                        acc[curr.productKey].medium += curr.medium;
                        acc[curr.productKey].low += curr.low;
                        acc[curr.productKey].total += curr.critical + curr.high + curr.medium + curr.low;
                    }
                    return acc;
                }, {})
            );
            setOptions(getOption([...result].sort((a, b) => b.total - a.total), isCritical));
        }
    }, [data, isCritical]);

    const getLabel = (productKey) => {
        let type = ""
        if (productKey === "sca") {
            type = "SCA"
        } else if (productKey === "sast") {
            type = "SAST"
        } else if (productKey === "dast") {
            type = "Web Security"
        } else if (productKey === "cloud") {
            type = "Cloud Security"
        } else if (productKey === "container") {
            type = "Container Security"
        } else if (productKey === "kubernetes") {
            type = "Kubernetes Security"
        }
        return type
    };

    return (<div style={{ height: '400px' }}>
        <ReactEcharts
            option={options}
            style={{ height: '100%' }}
            opts={{ renderer: 'svg' }} />
    </div>)
}