import React from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import timeZoneList from './timeZone.json';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { getGMTOffset } from '../../shared/helper/genHelper';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import { Dropdown } from 'react-bootstrap';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Scheduler = ({ schedulerList, setSchedulerList }) => {

    const findZoneByGMT = (timeZoneList, targetGMT) => {
        return _.get(_.find(timeZoneList, { gmt: targetGMT }), 'zone', null);
    };

    const handleAddSchedulerClick = () => {
        const newScheduler = {
            id: Date.now(),
            time: dayjs('00:00:00', 'HH:mm:ss'),
            timezone: findZoneByGMT(timeZoneList, `(${getGMTOffset()})`) ? findZoneByGMT(timeZoneList, "(GMT+05:30)") : timeZoneList[0].zone,
            type: GlobalConst.SCHEDULER_TYPE.DAILY
        };

        // // Check for duplicate time and timezone combination
        // const isDuplicate = schedulerList.some(
        //     item =>
        //         item.time.format('HH:mm') === newScheduler.time.format('HH:mm') &&
        //         item.timezone === newScheduler.timezone
        // );

        // if (isDuplicate) {
        //     alert('Scheduler already exists with same config!');
        //     return;
        // }

        setSchedulerList([...schedulerList, newScheduler]);
    };

    const handleRemoveScheduler = (id) => {
        setSchedulerList(schedulerList.filter(item => item.id !== id));
    };

    const handleTimeChange = (time, id) => {
        const updatedList = schedulerList.map(item => {
            if (item.id === id) {
                // Check for duplicate before updating
                const wouldBeDuplicate = schedulerList.some(
                    existingItem =>
                        existingItem.id !== id &&
                        existingItem.time.format('HH:mm') === time.format('HH:mm') &&
                        existingItem.timezone === item.timezone
                );

                if (wouldBeDuplicate) {
                    alert('Scheduler already exists with same config!');
                    return item;
                }

                return { ...item, time };
            }
            return item;
        });

        setSchedulerList(updatedList);
    };

    const handleTimezoneChange = (timezone, id) => {
        const updatedList = schedulerList.map(item => {
            if (item.id === id) {
                // Check for duplicate before updating
                const wouldBeDuplicate = schedulerList.some(
                    existingItem =>
                        existingItem.id !== id &&
                        existingItem.time.format('HH:mm') === item.time.format('HH:mm') &&
                        existingItem.timezone === timezone
                );

                if (wouldBeDuplicate) {
                    alert('Scheduler already exists with same config!');
                    return item;
                }

                return { ...item, timezone };
            }
            return item;
        });

        setSchedulerList(updatedList);
    };

    const handleScheduleTypeChange = (type, id) => {
        const updatedList = schedulerList.map((item) => {
            if (item.id === id) {
                item.type = type;
                if (type === GlobalConst.SCHEDULER_TYPE.WEEKLY) {
                    item.dayOfWeek = 0;
                } else if (type === GlobalConst.SCHEDULER_TYPE.MONTHLY) {
                    item.dateOfMonth = dayjs(new Date()).date();
                } else {
                    delete item.dayOfWeek;
                    delete item.dateOfMonth;
                }
            }
            return item;
        })
        setSchedulerList(updatedList);
    }

    // Handler for day of month change
    const handleDayOfMonthChange = (date, id) => {
        const updatedList = schedulerList.map((item) => {
            if (item.id === id) {
                item = {
                    ...item,
                    dateOfMonth: date
                }
            }
            return item;
        })
        setSchedulerList(updatedList);
    };

    // Handler for week day change
    const handleWeekDayChange = (dayOfWeek, id) => {
        const updatedList = schedulerList.map((item) => {
            if (item.id === id) {
                item.dayOfWeek = dayOfWeek;
            }
            return item;
        })
        setSchedulerList(updatedList);
    };

    const weekdays = [
        { value: 0, label: 'Sunday' },
        { value: 1, label: 'Monday' },
        { value: 2, label: 'Tuesday' },
        { value: 3, label: 'Wednesday' },
        { value: 4, label: 'Thursday' },
        { value: 5, label: 'Friday' },
        { value: 6, label: 'Saturday' },
    ];
    const daysInMonth = [...Array(31).keys()].map(i => i + 1);
    const schedulerTypes = [
        {
            value: GlobalConst.SCHEDULER_TYPE.DAILY,
            label: 'Daily',
        },
        {
            value: GlobalConst.SCHEDULER_TYPE.WEEKLY,
            label: 'Weekly',
        },
        {
            value: GlobalConst.SCHEDULER_TYPE.MONTHLY,
            label: 'Monthly',
        }
    ];

    return (
        <div className="">
            {!_.isEmpty(schedulerList) && schedulerList.map((scheduler) => (
                <div key={scheduler.id} className="mb-3">
                    <div className="row align-items-center">
                        <div className='col-sm-2'>
                            <select
                                id='schedulerType'
                                className="form-select"
                                value={!_.isEmpty(scheduler.type) ? scheduler.type : GlobalConst.SCHEDULER_TYPE.DAILY}
                                onChange={(e) => { handleScheduleTypeChange(e.target.value, scheduler.id) }}
                            >
                                {schedulerTypes.map((el, index) => {
                                    return <option key={`schedule-type-${index}`} value={`${el.value}`}>{el.label}</option>;
                                })}
                            </select>
                        </div>
                        {scheduler.type === GlobalConst.SCHEDULER_TYPE.MONTHLY && <div className="col-sm-2 pe-0">
                            <Dropdown className='days-month-dropdown'>
                                <Dropdown.Toggle
                                    variant="outline-secondary"
                                    className="text-white w-100 text-start d-flex justify-content-between align-items-center"
                                    size="sm"
                                >
                                    {scheduler.dateOfMonth}
                                    <FontAwesomeIcon
                                        icon={faAngleDown}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    style={{
                                        maxHeight: 200,
                                        overflow: "hidden",
                                        overflowY: "auto",
                                        width: "100%"
                                    }}
                                >
                                    {daysInMonth.map(
                                        (el, index) => (
                                            <Dropdown.Item
                                                key={`day-of-month-${index}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDayOfMonthChange(el,scheduler.id)
                                                }}
                                                as={"div"}
                                            >
                                                {el}
                                            </Dropdown.Item>
                                        )
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}
                        {scheduler.type === GlobalConst.SCHEDULER_TYPE.WEEKLY && <div className='col-sm-2 pe-0'>
                            <select
                                id='daysOfWeek'
                                className="form-select"
                                value={scheduler.dayOfWeek}
                                onChange={(e) => { handleWeekDayChange(e.target.value, scheduler.id) }}
                            >
                                {weekdays.map((el, index) => {
                                    return <option key={`week-day-${index}`} value={`${el.value}`}>{el.label}</option>;
                                })}
                            </select>
                        </div>}
                        <div className="col-sm-3 pe-0">
                            <TimePicker
                                format="h:mm A"
                                use12Hours
                                minuteStep={15}
                                className="form-control"
                                value={scheduler.time}
                                onChange={(time) => handleTimeChange(time, scheduler.id)}
                            />
                        </div>
                        <div className={"pe-0 " + (!_.isEmpty(scheduler.type) && scheduler.type !== GlobalConst.SCHEDULER_TYPE.DAILY ? "col-sm-4" : "col-sm-6")}>
                            <select
                                className="form-select"
                                value={scheduler.timezone}
                                onChange={(e) => handleTimezoneChange(e.target.value, scheduler.id)}
                            >
                                {timeZoneList.map((el, index) => {
                                    return <option key={`zonelist-${index}`} value={`${el.zone}`}>{el.gmt} - {el.zone}</option>;
                                })}
                            </select>
                        </div>
                        <div className="col-sm-1 text-end">
                            <CiCircleMinus
                                className="text-danger"
                                style={{ fontSize: 22, cursor: 'pointer' }}
                                onClick={() => handleRemoveScheduler(scheduler.id)}
                            />
                        </div>
                    </div>
                </div>
            ))}

            <div className="mb-3">
                <button
                    type='button'
                    onClick={handleAddSchedulerClick}
                    className="p-2 text-center d-flex mb-0 align-items-center justify-content-center w-100"
                    style={{ border: '1px dashed rgb(73 80 87)', cursor: 'pointer', borderRadius: 8 }}
                >
                    <CiCirclePlus style={{ fontSize: '22px' }} className='me-2' /> Add scheduler
                </button>
            </div>
        </div>
    );
};

export default Scheduler;