import { message, Space, Table, Tag } from "antd";
import { getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import NoDataFound from "./noDataFound";
import { MdOutlineError } from "react-icons/md";
import _ from "lodash"
import { useEffect, useState } from "react";
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import Loader from "../../shared/sharedComponents/loader";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import ViewDetailsModal from "./viewDetailsModal";


const firstLevelRowRender = (record, setIsModalVisible, setModalData) => {
    let subData = []
    if (!_.isEmpty(record) && !_.isEmpty(record.alerts)) {
        let newData = [];

        _.forEach(record.alerts, (row, i) => {
            // console.log("Row", row)
            newData.push({
                ...row,
                cweId: row.cweid,
                title: row.title,
                description: row.description,
                status: row.status
            })
        });
        subData = newData;
    }
    else {
        subData = [];
    }

    subData = subData.map((row, index) => {

        return {
            key: `owasp-table-${index}`,
            sno: index + 1,
            ...row,
        };
    });

    const columns = [{
        title: <><span className='me-3'>Vulnerability</span></>,
        dataIndex: 'title',
        key: 'title',
        width: "10%"
    },
    {
        title: "Description",
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
        width: "20%"
    },
    {
        title: "Cwe Id",
        dataIndex: 'cweId',
        key: 'cweId',
    },
    {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
        align: "center",
        render: (text) => {
            return !_.isEmpty(text) ? <span className="d-inline ms-2">
                {text.toLowerCase() === 'pass' ? (
                    <CheckCircleFilled style={{ color: 'green' }} className="me-2" />
                ) : (
                    <WarningFilled style={{ color: getStrokeColor('Critical') }} className="me-2" />
                )}
                {text}
            </span> : <span>-</span>
        }
    },
    {
        title: "Action",
        key: 'action',
        fixed: "right",
        width: 160,
        align: "center",
        render: (_, row) => <Space>
            <a href='#'
                className="me-2"
                onClick={(e) => {
                    e.preventDefault();
                    setIsModalVisible(true);
                    setModalData(row);
                }}
            >View Detail</a>
        </Space>,
    }
    ]
    return (<Table columns={columns} dataSource={subData}
        size="small"
        locale={{ emptyText: <NoDataFound title={'No Data'} /> }} 
        pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            position: ["bottom", "left"],
            className: "searchPagePagination px-0",
            pageSizeOptions: [10, 20, 25],
        }}/>
    )
}

export default function OwaspTabView({ reportId }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState(false);

    useEffect(() => {
        let reqBody = {
            reportId: reportId
        }
        setLoading(true)
        axios.post(`${GlobalConst.API_URL}/auth/pentest/asset/report/owasp-view`, reqBody).then(op => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                let newData = [];
                _.forEach(op.data.categories, (el, key) => {
                    if (el.status.toLowerCase() === "fail") {
                        let categoryObj = {
                            ...el,
                            key: key.toString(),
                            "name": el.name,
                            "status": el.status,
                            "total": el.totalCount,
                            "critical": el.severityCounts.critical,
                            "high": el.severityCounts.high,
                            "medium": el.severityCounts.medium,
                            "low": el.severityCounts.low,
                        }
                        newData.push(categoryObj)
                    }

                })
                setData(newData)
            }
        }).catch((e) => {
            if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data) && !_.isEmpty(e.response.data.errorMessage)) {
                message.error(e.response.data.errorMessage);
            }
            console.log(e)
        })
            .finally(() => {
                setLoading(false)
            })
    }, [reportId])

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const columns = [{
        title: "Title",
        dataIndex: "name",
        key: "name"
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: 'center'
    },
    {
        title: 'Critical',
        dataIndex: 'critical',
        key: 'critical',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {row.critical}
                </Tag>
            </div>);
        }
    },
    {
        title: 'High',
        dataIndex: 'high',
        key: 'high',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {row.high}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Medium',
        dataIndex: 'medium',
        key: 'medium',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {row.medium}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Low',
        dataIndex: 'low',
        key: 'low',
        align: 'center',
        render: (val, row) => {
            return (<div className="d-flex align-items-center justify-content-center flex-column">
                <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {row.low}
                </Tag>
            </div>);
        }
    },
    {
        title: 'Status',
        key: 'status',
        width: 100,
        render: (_, record) => (
            <span>
                <MdOutlineError style={{ color: getStrokeColor('Critical') }} className="me-2" />
                {record.status}
            </span>
        ),
    },]

    if (loading) {
        return (<Loader />)
    } else {
        return (<section className="my-2">
            <div>
                <Table
                    className='custom-table'
                    columns={columns}
                    dataSource={data}
                    size="small"
                    locale={{ emptyText: <NoDataFound title={'No Data'}/> }}
                    expandable={{
                        expandedRowRender: (record) => firstLevelRowRender(record, setIsModalVisible, setModalData),
                    }} 
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        position: ["bottom", "left"],
                        className: "searchPagePagination px-0",
                        pageSizeOptions: [10, 20, 25],
                    }}
                />
            </div>
            {isModalVisible && <ViewDetailsModal
                visible={isModalVisible}
                onClose={handleCloseModal}
                modalData={modalData}
            />}
        </section>)
    }

}