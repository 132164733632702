import { Progress, Radio, Tooltip } from "antd";
import ScanModal from "./scanModal";
import {
  setContainerScanModalState,
  setNoScannedRecords,
  setScanGuidResponse,
  setScannedContainers,
} from "../../shared/redux/reducers/containerSecurityReducer";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useEffect, useState } from "react";
import axios from "axios";
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import Loader from "../welcomeScreen/loader";
import ConListScannedDataTable from "./ConListScannedDataTable";
import StackedGraph from "../scaDashboard/StackedGraph";
import TidyTreeView from "../scaDashboard/tidyTree";
import Dropdown from "react-bootstrap/Dropdown";
import {
  faFilter,
  faRegistered,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullLoader from "../../shared/sharedComponents/loader";
import moment from "moment";
import TrendGraph from "./trendGraph";
import TabLoader from "../../shared/sharedComponents/tabLoader";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { setAllIntegratedSystems } from "../../shared/redux/reducers/integrationReducer";
import CustomResultView from "../../shared/sharedComponents/customResultView";
import sastWelcomeImg from '../../assets/img/sast-welcome-img.svg';
import { capitalizeFirstLetter, getPlanTypeFromProductKey, getProductSubscription } from "../../shared/helper/genHelper";
import { TbGridScan } from "react-icons/tb";
import { FaDocker } from "react-icons/fa6";

export const ContainerSecurityDashboard = () => {
  const [loadingDashboardData, setLoadingDashboardData] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [loadingTree, setLoadingTree] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [trendData, setTrendData] = useState({});
  const [trendValue, setTrendValue] = useState("7");
  const [loadingTrendData, setLoadingTrendData] = useState(false);
  const [advanceSearchInfo, setAdvanceSearchInfo] = useState({
    package: "",
    version: "",
    cves: ""
  })
  const [isAdvanceSearchApplied, setIsAdvanceSearchApplied] = useState(false)
  const dispatch = useDispatch();
  const iScanModalState = useSelector(
    (state) => state.contSecurityReducer.iScanModalState
  );
  const loadingRows = useSelector(
    (state) => state.contSecurityReducer.scanGuidResponse
  );
  const reduxState = useSelector((state) => state);
  const subscribedProductsReducer = useSelector(state => state.productsReducer);
  const halfCircleColor = ["#785add", "#2db46b", "#3f80e2"];

  useEffect(() => {
    if (!_.isEmpty(reduxState.authReducer.token)) {
      getDashboardData(true, true);
      getUserIntegrations();
    }
  }, [reduxState.authReducer.token]);

  useEffect(() => {
    if (!_.isEmpty(reduxState.authReducer.token)) {
      getTrendData(trendValue);
    }
  }, [trendValue, reduxState.authReducer.token]);

  useEffect(() => {
    let timer = "";
    if (!_.isEmpty(reduxState.contSecurityReducer.scanGuidResponse) && reduxState.contSecurityReducer.scanGuidResponse[0].scan !== 1) {
      timer = setInterval(async () => {
        let resp = await getScanStatus(
          reduxState.contSecurityReducer.scanGuidResponse
        ); // Get the status response of all guids
        // Once you receive the scan status loop though the whole object received and check for the status of each and every record being scanned.

        if (!_.isEmpty(resp)) {
          let completedAll = true;
          _.forIn(resp, function (value, key) {
            if (
              value.status.toLowerCase().toLowerCase() === "initiated" ||
              value.status.toLowerCase() === "scanning"
            ) {
              completedAll = false;
            }
          });

          if (completedAll) {
            getDashboardData(true, false);
            dispatch(setScanGuidResponse([]));
            clearInterval(timer);
          }
          //1) now loop through all the scanner records and update the one which are complete of failed.
          let clonedScannedRecords = _.cloneDeep(
            reduxState.contSecurityReducer.scannedContainers
          );
          let newDataSet = [];

          clonedScannedRecords.forEach((el) => {
            // check for the record in the scanned records which has status as scanning or initiated
            if (
              el.rowStatus.toLowerCase() === "initiated" ||
              el.rowStatus.toLowerCase() === "scanning"
            ) {
              // find the current record in the response status and see if the scan is complete
              let foundRec = {};
              _.forIn(resp, function (value, key) {
                if (
                  (value.status.toLowerCase() === "completed" ||
                    value.status.toLowerCase() === "failed") &&
                  value.location === el.url
                ) {
                  foundRec = value;
                  return false;
                }
              });
              if (!_.isEmpty(foundRec)) {
                if (foundRec.status.toLowerCase() === "failed") {
                  newDataSet.push({
                    ...el,
                    rowStatus: foundRec.status,
                    total: 0,
                    critical: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    na: 0,
                    reportId: foundRec.reportId,
                    source: foundRec.source,
                    scanDate: foundRec.scannerResponseEntity.scanDate,
                    errorMessage: foundRec.errorMessage,
                  });
                } else {
                  newDataSet.push({
                    ...el,
                    rowStatus: foundRec.status,
                    source: foundRec.source,
                    total: foundRec.scannerResponseEntity.totalCve,
                    critical:
                      foundRec.scannerResponseEntity.cveCountDetails.CRITICAL,
                    high: foundRec.scannerResponseEntity.cveCountDetails.HIGH,
                    medium:
                      foundRec.scannerResponseEntity.cveCountDetails.MEDIUM,
                    low: foundRec.scannerResponseEntity.cveCountDetails.LOW,
                    na:
                      foundRec.scannerResponseEntity.totalCve -
                      (foundRec.scannerResponseEntity.cveCountDetails.CRITICAL +
                        foundRec.scannerResponseEntity.cveCountDetails.HIGH +
                        foundRec.scannerResponseEntity.cveCountDetails.MEDIUM +
                        foundRec.scannerResponseEntity.cveCountDetails.LOW),
                    reportId: foundRec.reportId,
                    scanDate: foundRec.scannerResponseEntity.scanDate,
                    errorMessage: "",
                  });
                }
              } else {
                newDataSet.push(el);
              }
            } else {
              // We don't need to create a new array instead we can update the existing record.
              newDataSet.push(el);
            }
          });
          if (!_.isEmpty(newDataSet)) {
            dispatch(setScannedContainers(newDataSet));
          }
        }
      }, 5 * 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [reduxState.contSecurityReducer.scanGuidResponse]);

  // This function will only get the status of the
  const getScanStatus = async (allGuid) => {
    let reqBody = [];
    allGuid.forEach((el) => {
      reqBody.push(el.uuid);
    });

    return await axios
      .post(`${GlobalConst.API_URL}/auth/foss/report/status`, reqBody)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          return op.data;
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
        return null;
      });
  };

  const getDashboardData = (
    loaderStatus,
    reloadTable,
    traceabilityFlag = false
  ) => {
    setLoadingDashboardData(loaderStatus);
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/user/scan-stats`, {
        userId: reduxState.authReducer.userDetails.email,
        showOnlyTracingData: traceabilityFlag,
        showContainerOnly: true,
      })
      .then((op) => {
        setLoadingDashboardData(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          setDashboardData(op.data);
          if (!_.isEmpty(op.data.assetList)) {
            // get the first record and fire the api to get the report details so that we can get latest reports vtrace diagram.
            for (const [key, value] of Object.entries(op.data.assetList)) {
              if (
                value.status.toLowerCase() === "completed" &&
                value.totalCve > 0
              ) {
                handleVTraceClick(value.reportId);
                break;
              }
            }
            let newData = [];

            let guidArray = [];
            Object.entries(op.data.assetList).forEach(([key, value], ind) => {
              let name = "";
              if (isValidHttpUrl(key)) {
                var pathname = new URL(key).pathname;
                pathname = pathname.substring(1);
                name = pathname;
              } else {
                name = key;
              }

              newData.push({
                key: `dataRow-${ind}`,
                serialNumber: ind + 1,
                name: decodeURIComponent(name),
                owner: "",
                org: "",
                total: value.totalCve,
                critical: value.CRITICAL,
                high: value.HIGH,
                medium: value.MEDIUM,
                low: value.LOW,
                na:
                  value.totalCve -
                  (value.CRITICAL + value.HIGH + value.MEDIUM + value.LOW),

                type: value.subAssetType,
                url: key,
                assetType: value.assetType,
                subAssetType: value.subAssetType,
                reportId: value.reportId,
                scanDate: value.scanDate,
                scanningRow: false,
                branch: value.branch,
                rowStatus: value.status,
                ecosystem: value.ecosystem,
                source: value.source,
                errorMessage:
                  value.status.toLowerCase() === "failed"
                    ? value.errorMessage
                    : "",
                registryType: value.registryType,
              });
              if (
                value.status.toLowerCase() === "initiated" ||
                value.status.toLowerCase() === "scanning"
              ) {
                guidArray.push({
                  uuid: value.reportId,
                  urlType: value.subAssetType,
                  location: key,
                  assetType: value.assetType,
                  assetSubtype: value.subAssetType,
                  source: value.source,
                });
              }
            });
            dispatch(setScanGuidResponse(guidArray)); // update the guid state in redux for the scans which are still in initiate or scanning state
            dispatch(setScannedContainers(newData)); // Update the new set with the new scanned records data to populate on the dashboard screen.
            dispatch(setNoScannedRecords(false));
          } else {
            dispatch(setNoScannedRecords(true));
          }
        } else {
          dispatch(setNoScannedRecords(true));
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
        setLoadingDashboardData(false);
        dispatch(setNoScannedRecords(null));
      });
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleVTraceClick = (rowId) => {
    setLoadingTree(true);
    axios
      .get(`${GlobalConst.API_URL}/auth/foss/report/${rowId}`)
      .then((op) => {
        // setLoading(false);
        // console.log("Output is ::", op);
        setLoadingTree(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          // setReportDetails(op.data);
          setSearchResults(op.data.scannerResponseEntity);
        }
      })
      .catch((e) => {
        setLoadingTree(false);
        console.log("Exception: ", e);
      });
  };

  const getName = (key) => {
    let name = "";
    if (isValidHttpUrl(key)) {
      var pathname = new URL(key).pathname;
      pathname = pathname.substring(1);
      name = pathname;
    } else {
      name = key;
    }
    return name;
  };

  const handleTrendFilterChange = (e) => {
    setTrendValue(e.target.value);
  };

  const getTrendData = (trendValue) => {
    let postData = {
      userId: reduxState.authReducer.userDetails.email,
      showContainerOnly: true,
      days: trendValue ? trendValue : "7",
    };
    setLoadingTrendData(true);
    setTrendData({});
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/user/asset-scan-trend`, postData)
      .then((op) => {
        // console.log("Output:", op);
        if (
          !_.isEmpty(op) &&
          !_.isEmpty(op.data) &&
          !_.isEmpty(op.data.content)
        ) {
          const trendData = [];
          const sortedDates = Object.keys(op.data.content).sort((a, b) =>
            moment(a).diff(moment(b))
          );

          sortedDates.forEach((scanDate) => {
            const {
              CRITICAL: totalCriticalCount,
              HIGH: totalHighCount,
              LOW: totalLowCount,
              MEDIUM: totalMediumCount,
              totalCve: totalVulnerabilityCount,
            } = op.data.content[scanDate];
            trendData.push({
              scanDate,
              totalVulnerabilityCount,
              totalCriticalCount,
              totalHighCount,
              totalMediumCount,
              totalLowCount,
            });
          });

          setTrendData(trendData);
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
      })
      .finally((e) => {
        setLoadingTrendData(false);
      });
  };

  const getUserIntegrations = () => {
    axios
      .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          dispatch(setAllIntegratedSystems(op.data));
        }
      })
      .catch((e) => {
        console.log("Exception:", e);
      });
  };

  const handleDashboardReload = () => {
    window.location.reload();
  };

  const handleSearchChange = (searchParam, searchValue) => {
    setAdvanceSearchInfo({
      ...advanceSearchInfo,
      [searchParam]: !_.isEmpty(searchValue) && (searchParam === "cves" || searchParam === "version") ? searchValue.split(",") : searchValue
    })
  }

  const handleClearFilters = () => {
    setAdvanceSearchInfo({
      package: "",
      version: "",
      cves: ""
    })
  }

  const handleSearchSubmit = () => {
    setIsAdvanceSearchApplied(true)
    getSearchedComponentCveRepos(advanceSearchInfo)
  }

  const getSearchedComponentCveRepos = (searchedData) => {
    let reqBody = {
      userId: reduxState.authReducer.userDetails.email,
      showContainerOnly: true
    }

    if (searchedData.package) {
      reqBody = {
        ...reqBody,
        "componentName": searchedData.package
      }
    }
    if (searchedData.version) {
      reqBody = {
        ...reqBody,
        "version": searchedData.version
      }
    }
    if (searchedData.cves) {
      reqBody = {
        ...reqBody,
        "cveId": searchedData.cves
      }
    }

    // "componentName" : "spring",
    // "cveId" : ["CVE-2022-22965"],
    // "version" : ["5.1.4"]
    setLoadingDashboardData(true);
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/advance/user/scan-stats`, reqBody)
      .then((op) => {
        setLoadingDashboardData(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          setDashboardData(op.data);
          if (!_.isEmpty(op.data.assetList)) {
            let newData = [];

            let guidArray = [];
            Object.entries(op.data.assetList).forEach(([key, value], ind) => {
              let name = "";
              if (isValidHttpUrl(key)) {
                var pathname = new URL(key).pathname;
                pathname = pathname.substring(1);
                name = pathname;
              } else {
                name = key;
              }

              newData.push({
                key: `dataRow-${ind}`,
                serialNumber: ind + 1,
                name: decodeURIComponent(name),
                owner: "",
                org: "",
                total: value.totalCve,
                critical: value.CRITICAL,
                high: value.HIGH,
                medium: value.MEDIUM,
                low: value.LOW,
                na:
                  value.totalCve -
                  (value.CRITICAL + value.HIGH + value.MEDIUM + value.LOW),

                type: value.subAssetType,
                url: key,
                assetType: value.assetType,
                subAssetType: value.subAssetType,
                reportId: value.reportId,
                scanDate: value.scanDate,
                scanningRow: false,
                branch: value.branch,
                rowStatus: value.status,
                ecosystem: value.ecosystem,
                source: value.source,
                errorMessage:
                  value.status.toLowerCase() === "failed"
                    ? value.errorMessage
                    : "",
                registryType: value.registryType,
              });
              if (
                value.status.toLowerCase() === "initiated" ||
                value.status.toLowerCase() === "scanning"
              ) {
                guidArray.push({
                  uuid: value.reportId,
                  urlType: value.subAssetType,
                  location: key,
                  assetType: value.assetType,
                  assetSubtype: value.subAssetType,
                  source: value.source,
                });
              }
            });
            dispatch(setScanGuidResponse(guidArray)); // update the guid state in redux for the scans which are still in initiate or scanning state
            dispatch(setScannedContainers(newData)); // Update the new set with the new scanned records data to populate on the dashboard screen.
            dispatch(setNoScannedRecords(false));
          } else {
            dispatch(setNoScannedRecords(true));
          }
        } else {
          dispatch(setNoScannedRecords(true));
        }
      })
      .catch((e) => {
        dispatch(setNoScannedRecords(true));
        setLoadingDashboardData(false);
        console.log("Exception: ", e);
      })
  }

  const handleResetAllFilters = () => {
    setAdvanceSearchInfo({
      package: "",
      version: "",
      cves: ""
    })
    setIsAdvanceSearchApplied(false)
    getDashboardData(true, true)
  }

  return (
    <>
      <section className="mx-3">
        <div className="d-flex align-items-start justify-content-between mb-2">
          <h2 className="text-white">
            <FaDocker className="me-2" />
            Container Security
            <Tooltip title="Scan a repository">
              <button
                className="btn btn-warning py-1 ms-3"
                onClick={() => {
                  dispatch(setContainerScanModalState(true))
                }}
              >
                <TbGridScan className={'me-2'} />
                iScan
              </button>
            </Tooltip>
            <Tooltip title="Refresh">
              <button
                className="btn btn-primary py-1 ms-2"
                onClick={handleDashboardReload}
              >
                <FontAwesomeIcon icon={faRotateRight} />
              </button>
            </Tooltip>
          </h2>
          {!_.isEmpty(subscribedProductsReducer.subscribedProducts) && !_.isEmpty(subscribedProductsReducer.subscribedProducts.activePlans) &&
            !_.isEmpty(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.CONTEK))
            && <div><span className="product-plan-text">Plan - <span className={`${getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.CONTEK).sProductKey) === 'free' ? 'free-plan' : 'non-free-plan'}`}>{capitalizeFirstLetter(getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.CONTEK).sProductKey))}</span></span></div>
          }
        </div>
        {(reduxState.contSecurityReducer.noScannedRecords === false ||
          (reduxState.contSecurityReducer.noScannedRecords === true && isAdvanceSearchApplied)) 
          ? <>
          <div className="mb-4">
            <div className="row">
              <div className="col-sm-6">
                <div className="d-flex align-items-center justify-content-start h-100">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="px-2 d-flex align-items-center justify-content-start">
                      <div className="total-header">
                        <div className="d-flex flex-column">
                          <p
                            className="head-tag-subtext my-0"
                            style={{
                              color: "#9a9a9a",
                              fontSize: "90%",
                              fontWeight: 500,
                            }}
                          >
                            Total vulnerabilities
                          </p>
                          <h1 className="head-tag text-white">
                            {!_.isEmpty(dashboardData)
                              ? dashboardData.dashboardStats.totalCve
                              : "-"}
                          </h1>
                          {!_.isEmpty(loadingRows) && (
                            <div className="loader-line"></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="d-flex flex-wrap align-items-center justify-content-center">
                        <div className="py-2 pe-2 text-center">
                          <DashboardSeverityIndicator
                            severity={"Critical"}
                            loadingRows={
                              !_.isEmpty(loadingRows) ? true : false
                            }
                            dashboardData={
                              !_.isEmpty(dashboardData)
                                ? dashboardData.dashboardStats.CRITICAL
                                : "-"
                            }
                          />
                        </div>
                        <div className="py-2 pe-2 text-center">
                          <DashboardSeverityIndicator
                            severity={"High"}
                            loadingRows={
                              !_.isEmpty(loadingRows) ? true : false
                            }
                            dashboardData={
                              !_.isEmpty(dashboardData)
                                ? dashboardData.dashboardStats.HIGH
                                : "-"
                            }
                          />
                        </div>
                        <div className="py-2 pe-2 text-center">
                          <DashboardSeverityIndicator
                            severity={"Medium"}
                            loadingRows={
                              !_.isEmpty(loadingRows) ? true : false
                            }
                            dashboardData={
                              !_.isEmpty(dashboardData)
                                ? dashboardData.dashboardStats.MEDIUM
                                : "-"
                            }
                          />
                        </div>
                        <div className="py-2 pe-2 text-center">
                          <DashboardSeverityIndicator
                            severity={"Low"}
                            loadingRows={
                              !_.isEmpty(loadingRows) ? true : false
                            }
                            dashboardData={
                              !_.isEmpty(dashboardData)
                                ? dashboardData.dashboardStats.LOW
                                : "-"
                            }
                          />
                        </div>

                        {!_.isEmpty(dashboardData) &&
                          dashboardData.dashboardStats.totalCve -
                          (dashboardData.dashboardStats.LOW +
                            dashboardData.dashboardStats.HIGH +
                            dashboardData.dashboardStats.CRITICAL +
                            dashboardData.dashboardStats.MEDIUM) >
                          0 ? (
                          <div className="py-2 text-center">
                            <DashboardSeverityIndicator
                              severity={"NA"}
                              loadingRows={
                                !_.isEmpty(loadingRows) ? true : false
                              }
                              dashboardData={
                                !_.isEmpty(dashboardData)
                                  ? dashboardData.dashboardStats.totalCve -
                                  (dashboardData.dashboardStats.LOW +
                                    dashboardData.dashboardStats.HIGH +
                                    dashboardData.dashboardStats.CRITICAL +
                                    dashboardData.dashboardStats.MEDIUM)
                                  : "-"
                              }
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="p-2">
                  <div className="card-body">
                    <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                      <h5 className="fw-medium">Vulnerability Trend</h5>
                      <div>
                        <Radio.Group
                          value={trendValue}
                          size="small"
                          buttonStyle="solid"
                          onChange={(e) => handleTrendFilterChange(e)}
                        >
                          <Radio.Button value="7" style={{ fontSize: 12 }}>
                            1 Week
                          </Radio.Button>
                          <Radio.Button value="14" style={{ fontSize: 12 }}>
                            2 Weeks
                          </Radio.Button>
                          <Radio.Button value="30" style={{ fontSize: 12 }}>
                            1 Month
                          </Radio.Button>
                        </Radio.Group>
                      </div>
                    </div>
                    <div style={{ height: 300 }}>
                      {_.isEmpty(trendData) ? (
                        loadingTrendData === true ? (
                          <div className="d-flex align-items-center justify-content-center h-100 position-relative">
                            <TabLoader />
                          </div>
                        ) : (
                          <NoDataFound
                            title={"No Trend Data Found"}
                            desc={"We could not find any trend data"}
                          />
                        )
                      ) : (
                        <TrendGraph trendData={trendData} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section>
            <div className="card custom-card">
              <div className="card-body py-0">
                {loadingDashboardData ? (
                  <div
                    className={
                      "card custom-card p-2 px-3 text-left mb-2 mt-3"
                    }
                    style={{
                      background: "#1e464d",
                      border: "1px solid #2c6a74",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-start">
                      <Loader />{" "}
                      <span className="ms-2">
                        Loading previously triggered scans...
                      </span>
                    </div>
                  </div>
                ) : (
                  <ConListScannedDataTable advanceSearchInfo={advanceSearchInfo} handleSearchChange={handleSearchChange} handleSearchSubmit={handleSearchSubmit} handleClearFilters={handleClearFilters} isAdvanceSearchApplied={isAdvanceSearchApplied} handleResetAllFilters={handleResetAllFilters} />
                )}
                {!_.isEmpty(dashboardData) &&
                  !_.isEmpty(dashboardData.top3AssetsByCve) && (
                    <>
                      <hr />

                      <div className="row mt-10 d-flex align-items-start justify-content-center">
                        <div className="col-sm-6 custom-border-right">
                          <div className="">
                            <div className="mb-0" style={{ minHeight: 270 }}>
                              <div className="card-header align-items-center d-flex border-bottom-0">
                                <h4 className="card-title flex-grow-1 mb-0">
                                  Risk zones
                                </h4>
                              </div>

                              <div className="card-body pb-0">
                                <div className="text-white">
                                  <div className="row d-flex align-items-center justify-content-start ">
                                    {!_.isEmpty(dashboardData) &&
                                      !_.isEmpty(
                                        dashboardData.top3AssetsByCve
                                      ) &&
                                      Object.entries(
                                        dashboardData.top3AssetsByCve
                                      ).map(([key, value], index) => {
                                        return (
                                          <div className="col-sm-4" key={key}>
                                            <div className="p-3">
                                              <Tooltip title={key}>
                                                <div className="d-flex align-items-start justify-content-start flex-column">
                                                  <h6
                                                    style={{
                                                      fontWeight: 400,
                                                      fontSize: 12,
                                                      minHeight: 35,
                                                    }}
                                                    className="text-white mb-2"
                                                  >
                                                    <i>
                                                      {key.split("/").pop()}
                                                    </i>
                                                  </h6>
                                                  <div className="d-flex align-items-center">
                                                    <Progress
                                                      type="dashboard"
                                                      percent={
                                                        (value * 100) /
                                                        dashboardData
                                                          .dashboardStats
                                                          .totalCve
                                                      }
                                                      strokeColor={
                                                        halfCircleColor[index]
                                                      }
                                                      trailColor="#2b313e"
                                                      strokeWidth={12}
                                                      size={125}
                                                      format={(percent) => (
                                                        <span className="text-white">
                                                          {parseInt(value)}
                                                          <br />{" "}
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color:
                                                                "rgb(154, 154, 154)",
                                                            }}
                                                          >
                                                            Vulnerabilities
                                                          </span>
                                                        </span>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="">
                            <div className="mb-0" style={{ minHeight: 270 }}>
                              <div className="card-header align-items-center d-flex border-bottom-0">
                                <h4 className="card-title flex-grow-1 mb-0">
                                  Top 3 components by vulnerabilities
                                </h4>
                              </div>

                              <div className="card-body">
                                <div style={{ width: "100%", height: 200 }}>
                                  <StackedGraph
                                    data={dashboardData.top3ComponentsByCve}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      {!_.isEmpty(searchResults) && (
                        <div className="">
                          <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              vTrace{" "}
                              <FontAwesomeIcon
                                className="ms-1"
                                style={{ fontSize: 15 }}
                                icon={faRegistered}
                              />
                            </h4>
                          </div>

                          <div className="card-body">
                            <div className="text-white">
                              <div className="d-flex align-items-center justify-content-start">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="outline-secondary"
                                    disabled={loadingTree}
                                    className="text-white"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon={faFilter}
                                    />{" "}
                                    {getName(searchResults.assetUrl)}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: 200,
                                      overflow: "hidden",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {!_.isEmpty(
                                      reduxState.contSecurityReducer
                                        .scannedContainers
                                    ) &&
                                      reduxState.contSecurityReducer.scannedContainers.map(
                                        (el, index) => {
                                          if (
                                            el.rowStatus.toLowerCase() ===
                                            "completed" &&
                                            el.total > 0
                                          ) {
                                            return (
                                              <Dropdown.Item
                                                key={`select-dropdown-${index}`}
                                                href="#"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleVTraceClick(
                                                    el.reportId
                                                  );
                                                }}
                                              >
                                                {el.name}
                                              </Dropdown.Item>
                                            );
                                          }
                                        }
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>{" "}
                                {loadingTree ? (
                                  <div className="ms-3 d-flex align-items-center justify-content-start">
                                    <Loader />{" "}
                                    <span className="ms-2">
                                      Loading vTrace...
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <TidyTreeView dataSet={searchResults} />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
              </div>
            </div>
          </section>
        </>


          : (
            <div className="text-center mx-4 mt-4">
              {!reduxState.contSecurityReducer.noScannedRecords ? (
                <CustomResultView
                  isAlert={true}
                  resultPrimaryText={`We encountered an issue retrieving the scanned containers. Please try again later.`}
                />
              ) : !_.isEmpty(loadingRows) ? (
                <>
                  <img src={sastWelcomeImg} alt="welcome" className="mb-3" style={{ width: 200 }} />
                  <p>Scan started please wait till the scan is complete</p>
                  <div className="loader-line"></div>
                </>
              ) : (
                <>
                  <NoDataFound
                    title={"No Scans Found"}
                    desc={`It looks like you haven't scanned any containers yet. Begin by
      scanning your first container to get started.`}
                  />
                  <Tooltip title="Scan a container">
                    <button
                      className="btn btn-warning py-1 ms-3"
                      onClick={() => dispatch(setContainerScanModalState(true))}
                    >
                      <TbGridScan className={'me-2'} />
                      iScan
                    </button>
                  </Tooltip>
                </>
              )}
            </div>
          )}

      </section>
      {iScanModalState && <ScanModal />}
      {loadingDashboardData && <FullLoader />}
    </>
  );
};