import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Header from "./commonAssets/scannerAssets/header";
import Footer from "./commonAssets/scannerAssets/footer";
import Sidebar from "./commonAssets/userAssets/sidebar";
import { useSelector, useDispatch } from 'react-redux';
import PageLoader from '../sharedComponents/pageLoader';
import "../../assets/css/styles.css";
import "../../assets/css/above_md_styles.css";
import WelcomeScreen from "../../pages/welcomeScreen";
import ScanDetails from "../../pages/scaDashboard/scanDetails";
import UserPrefrences from "../../pages/userPrefrences";
import Downloads from '../../pages/downloads';
import IntegrationsListPage from '../../pages/integrationsListPage';
import IntegrateAzurePage from '../../pages/integrationsListPage/integrateAzure';
import IntegrateGCPPage from "../../pages/integrationsListPage/integrateGCP";
import TeamPage from "../../pages/team";
import ApplyAutofix from "../../pages/applyAutofix";
import CloudSecurityDashboard from "../../pages/cloudSecurityDashboard";
import PlanAndPricingPage from "../../pages/planAndPricing";
import SastDashboard from "../../pages/sastDashboard";
import SastAdvanceDashboard from "../../pages/sastAdvanceDashboard";
import DastDashboard from "../../pages/dastDashboard";
import DastAdvanceDashboard from "../../pages/dastAdvanceDashboard";
import IntegrateGithubPage from "../../pages/integrationsListPage/integrateGithub";
import { AWSRegistrySettings, AzureRegistrySettings, DockerRegistrySettings, GoogleCloudRegistrySettings, IBMRegistrySettings, JFrogRegistrySettings, OracleRegistrySettings } from "../../pages/integrationsListPage/integrateContainerRegistry";
import { DashboardSummary } from "../../pages/threatVisionDashboard/dashboardSummary";
import Dependency from "../../pages/threatVisionDashboard/dependency";
import axios from "axios";
import _ from "lodash";
import ScaDashboard from "../../pages/scaDashboard";
import CloudSecurityAdvanceDashboard from "../../pages/cloudSecurityAdvanceDashboard";
import { ContainerSecurityDashboard } from "../../pages/containerSecurity/containerSecurityDashboard";
import ContainerScanDetails from "../../pages/containerSecurity/scanDetails";
import { GlobalConst } from "../appConfig/globalConst";
import { validateActivePlans } from "../helper/genHelper";
import { setSubscribedProducts } from "../redux/reducers/productsReducer";
import Bitbucket from "../../pages/integrationsListPage/bitbucket";
import IntegrateKubernetes from "../../pages/integrationsListPage/integrateKubernetes";
import KubernetesDashboard from "../../pages/kubernetesDashboard";
import KubernetesSecurityAdvanceDashboard from "../../pages/kubernetesAdvancedDashboard";
import IntegrateAWSPage from "../../pages/integrationsListPage/integrateAWS";
import KubernetesSaas from "../../pages/integrationsListPage/kubernetesSaas";
import UnifiedDashboard from "../../pages/unifiedDashboard";
import DashboardPyramid from "../../pages/unifiedDashboard/dashboardPyramid";
import VisaeroScanner from "../../pages/visaeroScanner";
import APISecurityDashboard from "../../pages/apiSecurityDashboard";
import APISecurityAdvancedDashboard from "../../pages/apiSecurityAdvanceDashboard";
import { NoSubscription } from "../sharedComponents/noSubscription";
import UnifiedReportSummary from "../../pages/unifiedDashboard/reportsSummary/unifiedReportSummary";
import PenTestingDashboard from "../../pages/pentestingDashboard";
import PenTestAdvanceDashboard from "../../pages/pentestingAdvDashboard";

export default function UserLayout(props) {

  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [userPrefrences, setUserPrefrences] = useState(null);

  const handleMobileSidebarClose = () => setShowMobileSidebar(false);
  const handleMobileSidebarShow = () => setShowMobileSidebar(true);
  const authReducer = useSelector(state => state.authReducer);
  const productsReducer = useSelector(state => state.productsReducer);
  const dispatch = useDispatch()

  useEffect(() => {
    if (!_.isEmpty(authReducer.userDetails) && !_.isEmpty(authReducer.userDetails.email)) {
      getPrefrences();
      getSubscription();
    }
  }, [authReducer]);

  const getPrefrences = () => {
    const postObj = {
      userId: authReducer.userDetails.email,
    };
    axios
      .post(
        `${GlobalConst.API_URL}/auth/foss/user/get-preference`,
        postObj
      )
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          setUserPrefrences(op.data);
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
      });
  };

  const getSubscription = () => {
    // call to get the user details and check for which subscriptions he has made.
    if (!_.isEmpty(authReducer.userDetails.email)) {
      const postData = { "email": authReducer.userDetails.email };
      axios
        .post(`${GlobalConst.API_URL}/auth/foss/user/subscriptions/get`, postData)
        .then(op => {
          if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
            if (!_.isEmpty(op.data.activePlans)) {
              if (validateActivePlans(op.data)) {
                dispatch(setSubscribedProducts(op.data));
              }
              else {
                dispatch(setSubscribedProducts([]));
              }
            } else {
              dispatch(setSubscribedProducts([]));
            }

          }
        })
        .catch(e => {
          console.log("Execption: ", e);
        });
    }
  }

  const routes = [
    {
      path: '/manual-scanner',
      component: VisaeroScanner,
      title: 'Uploader',
    },
    {
      path: '/unified-dashboard',
      component: UnifiedDashboard,
      title: 'Pyramid Dashboard'
    },
    {
      path: '/dashboard',
      component: DashboardPyramid,
      title: 'Dashboard'
    },
    {
      path: '/welcome-screen',
      component: WelcomeScreen,
      title: 'Welcome to Scopy | Scopy Sec1'
    },
    {
      path: '/sca-dashboard',
      component: ScaDashboard,
      title: 'SCA Dashboard | Scopy Sec1'
    },
    {
      path: '/dashboard-scan-details/:id',
      component: ScanDetails,
      title: 'SCA Advance Dashboard | Scopy Sec1'
    },
    {
      path: '/user-prefrences',
      component: UserPrefrences,
      title: 'Configure Prefrences | Scopy Sec1'
    },
    {
      path: '/fix-vulnerabilities/:id',
      component: ApplyAutofix,
      title: 'Apply Autofix | Scopy Sec1'
    },
    {
      path: '/downloads',
      component: Downloads,
      title: 'Downloads | Scopy Sec1'
    },
    {
      path: '/integrations-list/azure',
      component: IntegrateAzurePage,
      title: 'Azure Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/gcp',
      component: IntegrateGCPPage,
      title: 'GCP Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/gcp/:id',
      component: IntegrateGCPPage,
      title: 'GCP Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/aws',
      component: IntegrateAWSPage,
      title: 'AWS Cloud Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/aws/:id',
      component: IntegrateAWSPage,
      title: 'AWS Cloud Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/github',
      component: IntegrateGithubPage,
      title: 'GitHub Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/docker-registry',
      component: DockerRegistrySettings,
      title: 'Docker Container Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/docker-registry/:id',
      component: DockerRegistrySettings,
      title: 'Docker Container Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/ibm-registry',
      component: IBMRegistrySettings,
      title: 'IBM Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/ibm-registry/:id',
      component: IBMRegistrySettings,
      title: 'IBM Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/google-registry',
      component: GoogleCloudRegistrySettings,
      title: 'Google Cloud Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/google-registry/:id',
      component: GoogleCloudRegistrySettings,
      title: 'Google Cloud Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/jfrog-registry',
      component: JFrogRegistrySettings,
      title: 'JFrog Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/jfrog-registry/:id',
      component: JFrogRegistrySettings,
      title: 'JFrog Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/azure-registry',
      component: AzureRegistrySettings,
      title: 'Azure Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/azure-registry/:id',
      component: AzureRegistrySettings,
      title: 'Azure Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/aws-registry',
      component: AWSRegistrySettings,
      title: 'AWS Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/aws-registry/:id',
      component: AWSRegistrySettings,
      title: 'AWS Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/oracle-registry',
      component: OracleRegistrySettings,
      title: 'Oracle Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/oracle-registry/:id',
      component: OracleRegistrySettings,
      title: 'Oracle Registry Settings | Scopy Sec1'
    },
    {
      path: '/integrations-list/bitbucket',
      component: Bitbucket,
      title: 'Bitbucket Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/k8s-config/:id',
      component: KubernetesSaas,
      title: 'Kubernetes Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/k8s-config',
      component: KubernetesSaas,
      title: 'Kubernetes Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list/k8s',
      component: IntegrateKubernetes,
      title: 'Kubernetes Integration | Scopy Sec1'
    },
    {
      path: '/integrations-list',
      component: IntegrationsListPage,
      title: 'Sec1 Integrations | Scopy Sec1'
    },
    {
      path: '/plans-and-pricing',
      component: PlanAndPricingPage,
      title: 'Plans and pricing | Scopy Sec1'
    },
    {
      path: '/team',
      component: TeamPage,
      title: 'Team | Scopy Sec1'
    },
    {
      path: '/cloud-security-advance-dashboard/:id',
      component: CloudSecurityAdvanceDashboard,
      title: 'Cloud Security Advance Dashboard | Scopy Sec1'
    },
    {
      path: '/cloud-security-dashboard',
      component: CloudSecurityDashboard,
      title: 'Cloud Security Dashboard | Scopy Sec1'
    },

    {
      path: '/sast-dashboard',
      component: SastDashboard,
      title: 'SAST Dashboard | Scopy Sec1'
    },
    {
      path: '/sast-advance-dashboard/:id',
      component: SastAdvanceDashboard,
      title: 'SAST Advance Dashboard | Scopy Sec1'
    },
    {
      path: '/web-security-dashboard',
      component: DastDashboard,
      title: 'Web Security Dashboard | Scopy Sec1'
    },
    {
      path: '/web-security-advance-dashboard/:id',
      component: DastAdvanceDashboard,
      title: 'Web Security Advance Dashboard | Scopy Sec1'
    },
    {
      path: '/ai-threat-vision/:id',
      component: Dependency,
      title: 'AI Threat Vision Advance Dashboard | Scopy Sec1'
    },
    {
      path: '/ai-threat-vision',
      component: DashboardSummary,
      title: 'AI Threat Vision | Scopy Sec1'
    },
    {
      path: '/container-security',
      component: ContainerSecurityDashboard,
      title: 'Container Security | Scopy Sec1'
    },
    {
      path: '/container-security/:id',
      component: ContainerScanDetails,
      title: 'Container Security Advance Dashboard | Scopy Sec1'
    },
    {
      path: '/api-security-dashboard',
      component: APISecurityDashboard,
      title: 'API Security Dashboard | Scopy Sec1'
    },
    {
      path: '/api-security-advanced-dashboard/:id',
      component: APISecurityAdvancedDashboard,
      title: 'API Security Advanced Dashboard | Scopy Sec1'
    },
    {
      path: '/k8s-dashboard',
      component: KubernetesDashboard,
      title: 'Kubernetes Dashboard | Scopy Sec1'
    },
    {
      path: '/k8s-advance-dashboard/:id/:integrationId',
      component: KubernetesSecurityAdvanceDashboard,
      title: 'Kubernetes Advance Dashboard | Scopy Sec1'
    },
    {
      path: '/unified-dashboard-reports',
      component: UnifiedReportSummary,
      title: 'Unified Report Summary'
    },
    {
      path: '/penetration-testing-dashboard',
      component: PenTestingDashboard,
      title: 'Penetration Testing Dashboard | Scopy Sec1'
    },
    {
      path: '/penetration-testing-advanced-dashboard/:id',
      component: PenTestAdvanceDashboard,
      title: 'Penetration Testing Advanced Dashboard | Scopy Sec1'
    },
    {
      path: '/',
      component: DashboardPyramid,
      title: 'Dashboard | Scopy Sec1'
    }
  ];

  const subscriptionRequiredRoutes = {
    sca: {
      routes: ['/sca-dashboard', '/dashboard-scan-details', '/fix-vulnerabilities'],
      productKey: GlobalConst.PRODUCT_KEYS.SCA,
    },
    sast: {
      routes: ['/sast-dashboard', '/sast-advance-dashboard'],
      productKey: GlobalConst.PRODUCT_KEYS.SAST,
    },
    dast: {
      routes: ['/web-security-dashboard', '/web-security-advance-dashboard'],
      productKey: GlobalConst.PRODUCT_KEYS.DAST,
    },
    aiThreatVision: {
      routes: ['/ai-threat-vision'],
      productKey: GlobalConst.PRODUCT_KEYS.THREAT_VISION,
    },
    containerSecurity: {
      routes: ['/container-security'],
      productKey: GlobalConst.PRODUCT_KEYS.CONTEK,
    },
    apiSecurity: {
      routes: ['/api-security-dashboard', '/api-security-advanced-dashboard'],
      productKey: GlobalConst.PRODUCT_KEYS.API,
    },
    k8sSecurity: {
      routes: ['/k8s-dashboard', '/k8s-advance-dashboard'],
      productKey: GlobalConst.PRODUCT_KEYS.KUBERNETES,
    },
    cloud: {
      routes: ['/cloud-security-advance-dashboard', '/cloud-security-dashboard'],
      productKey: GlobalConst.PRODUCT_KEYS.CLOUD_SECURITY,
    },
  }


  const getSubscriptionForRoute = (currentRoute) => {
    const subscriptionRouteInfo = _.find(Object.values(subscriptionRequiredRoutes), ({ routes: subscriptionProductPaths }) => _.some(subscriptionProductPaths, path => path.includes(currentRoute)))
    if (!_.isEmpty(subscriptionRouteInfo)) {
      if (!_.isEmpty(productsReducer.subscribedProducts)) {
        const isProductSubscribed = _.some(productsReducer.subscribedProducts.activePlans, plan => plan.sProductKey.includes(subscriptionRouteInfo.productKey.toLowerCase()))
        if (isProductSubscribed) {
          return {
            productKey: subscriptionRouteInfo.productKey,
            isSubscribed: true
          };
        } else {
          return {
            productKey: subscriptionRouteInfo.productKey,
            isSubscribed: false
          };
        }
      }
    }
    return null
  }

  const location = useLocation();

  const getCurrentRoute = () => {
    return routes.find(route => {
      if (route.path === location.pathname) return true;
      if (route.path.includes(':')) {
        const pathParts = route.path.split('/');
        const currentParts = location.pathname.split('/');
        if (pathParts.length !== currentParts.length) return false;
        return pathParts.every((part, index) => part.startsWith(':') || part === currentParts[index]);
      }
      return false;
    });
  };

  const currentRoute = getCurrentRoute();

  return (
    <div>
      <Helmet>
        <title>{currentRoute ? currentRoute.title : 'Scopy Sec1'}</title>
      </Helmet>
      <div className="container-fluid text-white bg-customDark p-0">
        <div className="d-flex align-items-start justify-content-start flex-column w-100">
          <Sidebar userPrefrences={userPrefrences} />
          <div className="content-area">
            <Header {...props} handleMobileSidebarClose={handleMobileSidebarClose} showMobileSidebar={showMobileSidebar} handleMobileSidebarShow={handleMobileSidebarShow} />
            <div className="px-2 py-4" style={{ minHeight: 'calc(100vh - 131px)' }}>
              <Switch>
                {routes.map((route, index) => (
                  <Route key={index} path={route.path} render={(props) => {
                    const subscription = getSubscriptionForRoute(route.path);
                    return !_.isEmpty(subscription) && !subscription.isSubscribed ? <NoSubscription productType={subscription.productKey} /> : <route.component {...props} />
                  }} exact />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <PageLoader />
      <Footer />
    </div>
  );
}