import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Tooltip, Radio, Button } from 'antd';
import axios from "axios";
import { GlobalConst } from "../../appConfig/globalConst";
import _ from 'lodash';
import InlineLoader from '../inlineLoader';
import { useSelector, useDispatch } from "react-redux";
import { removeGitSuffix } from '../../helper/genHelper';
import SelectRepositoryDataTable from "./selectRepositoryDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { setUserListPageNumber, setAllUserRepoList } from "../../redux/reducers/scannerReducer";


export default function SelectRepository({ loadingRepos, selectedRepo, setSelectedRepo, integratedSystems, urlType }) {

    const dispatch = useDispatch();
    const [selectedFilter, setSelectedFilter] = useState('unscanned');
    const [data, setData] = useState([]);
    const [loadingReposList, setLoadingReposList] = useState(false);
    const [disableLoadMore, setDisableLoadMore] = useState(false);

    const reduxState = useSelector(state => state);

    // useEffect(() => {
    //     // console.log(":Called here", reduxState);
    //     if (!_.isEmpty(reduxState.scannerReducer.allUserRepoList) && reduxState.scannerReducer.allUserRepoList.length > 0) {
    //         setData(reduxState.scannerReducer.allUserRepoList);

    //         let sCount = 0, uSCount = 0;
    //         reduxState.scannerReducer.allUserRepoList.forEach((repo) => {
    //             const isPresent = _.find(reduxState.scannerReducer.scannedRecords, function (o) { return removeGitSuffix(o.url) === removeGitSuffix(repo.url); });
    //             if (!_.isEmpty(isPresent)) {
    //                 sCount = sCount + 1;
    //             }
    //             else {
    //                 uSCount = uSCount + 1;
    //             }
    //         });
    //         setScannedCount(sCount);
    //         setUnScannedCount(uSCount);

    //     }
    // }, [reduxState.scannerReducer.allUserRepoList]);
    // useEffect(() => {
    //     if (!_.isEmpty(reduxState.scannerReducer.allUserRepoList) && reduxState.scannerReducer.allUserRepoList.length > 0) {
    //         setSelectedRepo([]);
    //         switch (selectedFilter) {
    //             case 'scanned':
    //                 let scannedList = [];
    //                 reduxState.scannerReducer.allUserRepoList.forEach((repo) => {
    //                     const isPresent = _.find(reduxState.scannerReducer.scannedRecords, function (o) { 
    //                         if(urlType !== 'github')
    //                             return o.url === repo.url;
    //                         else
    //                             return removeGitSuffix(o.url) === removeGitSuffix(repo.url);
    //                     });
    //                     if (!_.isEmpty(isPresent)) {
    //                         // console.log("Is Present::", isPresent);
    //                         scannedList.push({ ...repo, scanDate: isPresent.scanDate });
    //                     }
    //                 });
    //                 console.log("Scanned List::", scannedList)
    //                 if (!_.isEmpty(scannedList)) {
    //                     setData(scannedList);
    //                 }
    //                 else {
    //                     setData([]);
    //                 }
    //                 break;
    //             case 'unscanned':
    //                 let unscannedList = [];
    //                 reduxState.scannerReducer.allUserRepoList.forEach((repo) => {
    //                     console.log(repo.url)
    //                     const isPresent = _.find(reduxState.scannerReducer.scannedRecords, function (o) {
    //                         console.log(urlType, o.url === repo.url ? 'match' : '')
    //                         if(urlType !== 'github')
    //                             return o.url === repo.url;
    //                         else
    //                             return removeGitSuffix(o.url) === removeGitSuffix(repo.url);
    //                 });
    //                     if (_.isEmpty(isPresent)) {
    //                         unscannedList.push({ ...repo, scanDate: '' });
    //                     }
    //                 });
    //                 console.log("Unscanned List::", unscannedList)
    //                 if (!_.isEmpty(unscannedList)) {
    //                     setData(unscannedList);
    //                 }
    //                 else {
    //                     setData([]);
    //                 }
    //                 break;
    //             default:
    //                 let allScannedList = [];
    //                 reduxState.scannerReducer.allUserRepoList.forEach((repo) => {
    //                     const isPresent = _.find(reduxState.scannerReducer.scannedRecords, function (o) { 
    //                         if(urlType !== 'github')
    //                             return o.url === repo.url;
    //                         else
    //                             return removeGitSuffix(o.url) === removeGitSuffix(repo.url);
    //                     });
    //                     if (!_.isEmpty(isPresent)) {
    //                         allScannedList.push({ ...repo, scanDate: isPresent.scanDate });
    //                     }
    //                     else {
    //                         allScannedList.push({ ...repo, scanDate: '' });
    //                     }
    //                 });
    //                 console.log("All scanned List::", allScannedList)
    //                 if (!_.isEmpty(allScannedList)) {
    //                     setData(allScannedList);
    //                 }
    //                 else {
    //                     setData([]);
    //                 }
    //                 break;
    //         }
    //     }
    // }, [selectedFilter])

    useEffect(() => {
        const initialRepoCount = reduxState.scannerReducer.allUserRepoList.length;
        setDisableLoadMore(initialRepoCount % 20 !== 0);
      }, [reduxState.scannerReducer.allUserRepoList]);

    const handleRepoItemClick = (e, element) => {
        e.preventDefault();

        // check if the current element is not present in the existing list if present then remove it else add it
        if (!_.isEmpty(selectedRepo)) {

            let isPresent = false;
            selectedRepo.forEach((el, ind) => {
                if (el.url === element.url) {
                    isPresent = true;
                }
            })
            if (isPresent) {
                // since the element is present now remove the element from list
                let filter = [];
                filter = _.filter(selectedRepo, (o) => { return o.url !== element.url });
                setSelectedRepo([...filter]);
            }
            else {
                setSelectedRepo([...selectedRepo, element]);
            }
        }
        else {
            setSelectedRepo([element]);
        }
    }

    const getRepoCheckStatus = (repo) => {

        if (!_.isEmpty(selectedRepo)) {
            let isPresent = false;
            selectedRepo.forEach((el) => {
                if (el.url === repo.url) {
                    isPresent = true;
                }
            })
            return isPresent;
        }
        else {
            return false;
        }

    }

    const handleLoadMoreClick = () => {
        // console.log("I am load more click here");

        const page = reduxState.scannerReducer.userListPageNumber + 1;

        dispatch(setUserListPageNumber(page));

        setLoadingReposList(true);
        let reqBody = {
            "urlType": urlType,
            "owner": reduxState.authReducer.userDetails.email,
            "page": page,
            "recordsPerPage": GlobalConst.GIT_REPO_LIST_PAGE_COUNT
        }

        if(urlType === 'bitbucket'){
            if(!_.isEmpty(integratedSystems) && !_.isEmpty(integratedSystems['bitbucket']))
                reqBody = {
                    ...reqBody,
                    "owner": integratedSystems['bitbucket'].targetorg,
                }
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, reqBody)
            .then(op => {
                setLoadingReposList(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    // setRepositoryList(op.data.data);
                    // setRepositoryListCopy(op.data.data);
                    dispatch(setAllUserRepoList([...op.data.data, ...reduxState.scannerReducer.allUserRepoList]));
                    if (op.data.data.length < 20) {
                        setDisableLoadMore(true);
                    }
                }
                else {
                    // show user the error message that there is no repo list.
                    // setRepositoryList([]);
                    // setRepositoryListCopy([]);
                    // dispatch(setAllUserRepoList([]));
                    setDisableLoadMore(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e.response);
                setLoadingReposList(false);
                // setRepositoryList([]);
                // setRepositoryListCopy([]);
                // dispatch(setAllUserRepoList([]));
            });



    }

    const handleRadioChange = (el) => {
        setSelectedFilter(el);
    }

    // Helper function to check if a repo is scanned
    const isRepoScanned = useCallback((repo, scannedRecords) => {
        return _.find(scannedRecords, (o) => 
        urlType !== 'github'
            ? o.url === repo.url
            : removeGitSuffix(o.url) === removeGitSuffix(repo.url)
        );
    }, [urlType]);
  
    // Calculate counts on demand
    const { scannedCount, unScannedCount } = useMemo(() => {
        const { allUserRepoList, scannedRecords } = reduxState.scannerReducer;
        return allUserRepoList.reduce(
        (acc, repo) => {
            isRepoScanned(repo, scannedRecords) ? acc.scannedCount++ : acc.unScannedCount++;
            return acc;
        },
        { scannedCount: 0, unScannedCount: 0 }
        );
    }, [isRepoScanned, reduxState.scannerReducer]);
  
    // Filter repositories based on selected filter
    const filteredData = useMemo(() => {
        const { allUserRepoList, scannedRecords } = reduxState.scannerReducer;
        
        if (_.isEmpty(allUserRepoList)) return [];
    
        switch (selectedFilter) {
        case 'scanned':
            return allUserRepoList.reduce((acc, repo) => {
            const scannedRepo = isRepoScanned(repo, scannedRecords);
            if (scannedRepo) acc.push({ ...repo, scanDate: scannedRepo.scanDate });
            return acc;
            }, []);
        case 'unscanned':
            return allUserRepoList.filter(repo => !isRepoScanned(repo, scannedRecords))
            .map(repo => ({ ...repo, scanDate: '' }));
        default:
            return allUserRepoList.map(repo => {
            const scannedRepo = isRepoScanned(repo, scannedRecords);
            return { ...repo, scanDate: scannedRepo ? scannedRepo.scanDate : '' };
            });
        }
    }, [selectedFilter, reduxState.scannerReducer, isRepoScanned]);
    
    // Single useEffect to update data
    useEffect(() => {
        setData(filteredData);
        setSelectedRepo([]);
    }, [filteredData]);

    if (loadingRepos) {
        return (
            <div className='card custom-card p-2 px-3 text-left mb-2' style={{ background: '#1e464d', border: '1px solid #2c6a74' }}>
                <div className="d-flex align-items-start justify-content-start text-white"><InlineLoader /> <span className="ms-2">Loading repositories...</span></div>
            </div>
        );
    }
    else if (_.isEmpty(reduxState.scannerReducer.allUserRepoList)) {
        return (
            <Alert message="No Repositories found in your account" type="info" showIcon />
        );
    }
    else {
        return (

            <div className="py-2 rounded" >
                <div className="d-flex align-items-end justify-content-between mb-2">
                    <div>
                        {/* <div className="d-flex align-items-center justify-content-start mb-2">
                            <div style={{ width: `${(scannedCount * 100) / (scannedCount + unScannedCount)}%`, height: 25, borderRadius: '7px 0 0 7px', color: "#212121", fontWeight: 600, cursor: 'pointer' }} onClick={() => handleRadioChange('scanned')} className={`bg-success d-flex align-items-center justify-content-center  ${!scannedCount ? "d-none" : ""}`}>{scannedCount}</div>
                            <div style={{ width: `${(unScannedCount * 100) / (scannedCount + unScannedCount)}%`, height: 25, borderRadius: '0 7px 7px 0', color: "#212121", fontWeight: 600, cursor: 'pointer' }} onClick={() => handleRadioChange('unscanned')} className={`bg-danger d-flex align-items-center justify-content-center   ${!unScannedCount ? "d-none" : ""}`}>{unScannedCount}</div>
                        </div> */}

                        <div className="d-flex align-items-center justify-content-start px-2">
                            <Tooltip title="show only scanned repos" color={'#444'}><Radio name="filter" className="me-4 text-success radio-success" onChange={() => handleRadioChange('scanned')} checked={selectedFilter === 'scanned'} style={{ color: '#adb5bd' }}>scanned repos 
                            {/* <span className="ps-1">{`(${scannedCount})`}</span> */}
                            </Radio></Tooltip >
                            <Tooltip title="show only unscanned repos" color={'#444'}><Radio name="filter" className="me-4 text-danger radio-danger" onChange={() => handleRadioChange('unscanned')} checked={selectedFilter === 'unscanned'} style={{ color: '#adb5bd' }}>unscanned repos                             
                                {/* <span className="ps-1">{`(${unScannedCount})`}</span> */}
                            </Radio></Tooltip >
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 scroll-style">
                        <div className="row">
                            <div className="col-sm-8">
                                <div style={{ maxHeight: 450, overflow: "hidden", overflowY: 'scroll' }}>
                                    <SelectRepositoryDataTable
                                        dataSet={data}
                                        selectedFilter={selectedFilter}
                                        handleRepoItemClick={handleRepoItemClick}
                                        selectedRepo={selectedRepo}
                                        getRepoCheckStatus={getRepoCheckStatus}
                                    />
                                    {!disableLoadMore && <div className="w-100 text-center py-3 position-sticky bottom-0">
                                        <Button type="primary" loading={loadingReposList} onClick={() => handleLoadMoreClick()}>Load More <FontAwesomeIcon className="ms-2" icon={faArrowDown} /></Button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}