import { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
// import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { GoLink } from "react-icons/go";
import { faArrowLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Radio, Tooltip } from 'antd';
import moment from 'moment';

import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import VulnerabilityView from './vulnerabilityView';
import URLDiscoveryView from './urlDiscoveryView';
import NetworkView from './networkView';
import SSLTabView from "./SSLCheck/sslChecksView";
import OwaspTabView from './owaspView';
import TrendGraph from './trendGraph';
import Loader from '../../shared/sharedComponents/loader';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import WelcomeLoader from "../welcomeScreen/loader";
import PentestHistoryView from './historyView';


export default function PenTestAdvanceDashboard() {

    const { id } = useParams();
    const reduxStore = useSelector(state => state);

    const [loading, setLoading] = useState(false);
    const [activeTabsKey, setActiveTabsKey] = useState('vulnerabilityView');
    const [reportDetails, setReportDetails] = useState({});
    const [trendValue, setTrendValue] = useState('7');
    const [userPrefrences, setUserPrefrences] = useState(null);

    useEffect(() => {
        if (!_.isEmpty(id)) {
            getVulnerabilityViewData(id)
        }
    }, [id])

    useEffect(() => {
        if (!_.isEmpty(reduxStore) && !_.isEmpty(reduxStore.authReducer) && !_.isEmpty(reduxStore.authReducer.userDetails) && !_.isEmpty(reduxStore.authReducer.userDetails.email)) {
            getPrefrences(reduxStore.authReducer.userDetails.email);
        }
    }, [reduxStore])

    const getPrefrences = (userId) => {
        const postObj = { userId: userId };
        axios
            .post(
                `${GlobalConst.API_URL}/auth/foss/user/get-preference`,
                postObj
            )
            .then((op) => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setUserPrefrences(op.data);
                }
            })
            .catch((e) => { });
    };

    const getVulnerabilityViewData = (reportId) => {
        const postBody = {
            "reportId": reportId
        };
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/pentest/asset/report/vulnerability-view`, postBody)
            .then(op => {
                setLoading(false);

                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setReportDetails(op.data);
                } else {
                    setReportDetails({})
                }
            })
            .catch(e => { setLoading(false); setReportDetails(null) })
    }

    const getTotal = (vulCount) => {
        return (vulCount.critical + vulCount.high + vulCount.medium + vulCount.low);
    }

    const goBack = (e) => {
        if (window.opener && !window.opener.closed) {
            window.opener.focus();
            window.close();
        } else {
            window.location.href = '/penetration-testing-dashboard';
        }
    }

    const findZoneByGMT = (timeZoneList, targetGMT) => {
        return _.get(_.find(timeZoneList, { gmt: targetGMT }), 'zone', null);
    };

    const handleDashboardReload = () => {
        window.location.reload();
    };

    return (
        <section className="mx-3">
            <section className="mb-3 d-flex align-items-center justify-content-start">
                <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                    <a className='me-2' onClick={goBack} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a><span className='ps-2' style={{ borderLeft: '1px solid rgba(108, 117, 125, 15%' }}>Pen Testing Scan Report</span>
                    <Tooltip title="Refresh">
                        <button className="btn btn-primary py-1 ms-4" onClick={handleDashboardReload}>
                            <FontAwesomeIcon icon={faRotateRight} />
                        </button>
                    </Tooltip>
                </h2>
            </section>
            {!_.isEmpty(reportDetails) &&
                <>
                    <section>
                        <div className="d-flex align-items-center justify-content-start">
                            <span><GoLink className="me-1" style={{ fontSize: 12 }} /></span>
                            <span className="me-2"><a href={reportDetails.assetUrl} target='_blank' > {reportDetails.assetUrl}</a></span>
                            <span className="text-secondary"><small><i>(<strong>Last Scan:</strong>  {moment.utc(reportDetails.scanDateTime).local().fromNow()})</i></small></span>
                        </div>
                    </section>
                    <section className='mb-3'>
                        <div className="row">
                            <div className="col-lg-6 custom-border-right ">
                                <div className='py-3 d-flex flex-column justify-content-center w-100 h-100'>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <div className="card-body">
                                            <div className="d-flex flex-row align-items-start justify-content-between">
                                                <div className="me-3">
                                                    <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Vulnerabilities</p>
                                                    <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{getTotal(reportDetails.vulnerabilityCounts)}</h1>
                                                </div>
                                                <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Critical'} loadingRows={loading} dashboardData={reportDetails.vulnerabilityCounts.critical} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'High'} loadingRows={loading} dashboardData={reportDetails.vulnerabilityCounts.high} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Medium'} loadingRows={loading} dashboardData={reportDetails.vulnerabilityCounts.medium} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Low'} loadingRows={loading} dashboardData={reportDetails.vulnerabilityCounts.low} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 ">
                                <div className="p-2">
                                    <div className="card-body">
                                        <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                            <h5 className="fw-medium">Vulnerability Trend</h5>
                                        </div>
                                        <div style={{ height: 300 }}>
                                            <TrendGraph reportDetails={reportDetails} trendValue={trendValue} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section style={{ position: 'relative' }}>
                        <Tabs
                            className="mb-3 report-tabs"
                            activeKey={activeTabsKey}
                            onSelect={(k) => setActiveTabsKey(k)}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <Tab eventKey="vulnerabilityView" title="Vulnerability View">
                                <VulnerabilityView reportDetails={reportDetails} reportId={id} />
                            </Tab>

                            <Tab eventKey="fileView" title="URL Discovery">
                                <URLDiscoveryView reportDetails={reportDetails} userPrefrences={userPrefrences} reportId={id} />
                            </Tab>
                            <Tab eventKey="owaspView" title="OWASP Top 10">
                                <OwaspTabView reportId={id} />
                            </Tab>
                            <Tab eventKey="networkView" title="Network Ports">
                                <NetworkView reportId={id} />
                            </Tab>
                            <Tab eventKey="sslCheckView" title="SSL Checks">
                                <SSLTabView reportId={id} />
                            </Tab>
                            <Tab eventKey="history" title="History">
                                <PentestHistoryView reportDetails={reportDetails} id={id} />
                            </Tab>
                        </Tabs>
                    </section>
                </>
            }
            {loading && <Loader />}
        </section>
    );
}