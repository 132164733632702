import { useEffect, useState } from "react"
import _ from "lodash";


export default function DocumentViewer({ docData, docType }) {
    const [docURL, setDocURL] = useState("");

    useEffect(() => {
        const url = URL.createObjectURL(docData);
        setDocURL(url);
        // Cleanup
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [docData, docType]);

    return !_.isEmpty(docURL) ? <div style={{ width: '100%', height: '300px' }}>
        <object
            data={docURL}
            className="w-100"
            type={docType} 
            height="300"
        >
            <p>
                You don't have a appropriate plugin, but you can
                <a href={docURL}>download the document file. </a>
            </p>
        </object>
    </div> : null
}