import { Button, Input, Space, Switch, Table, message } from "antd";
import Loader from "../../shared/sharedComponents/loader";
import NoDataFound from "./noDataFound"
import { useEffect, useRef } from "react";
import _ from "lodash";
import { useState } from "react";
import axios from "axios"
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export default function NetworkView({ reportId }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [page, setPage] = useState(1);
    const searchInput = useRef(null);
    const [showAllComponents, setShowAllComponents] = useState(false);

    useEffect(() => {
        let reqBody = {
            reportId: reportId
        }
        setLoading(true)
        axios.post(`${GlobalConst.API_URL}/auth/pentest/asset/report/fetch/network-ports`, reqBody).then(op => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                if (op.data.networkPorts) {
                    let newData = [];
                    for (const networkInfo of op.data.networkPorts) {
                        newData.push({
                            "protocol": networkInfo.protocol,
                            "portid": networkInfo.portid,
                            "state": networkInfo.state,
                            "name": networkInfo.service.name,
                            "product": networkInfo.service.product
                        })
                    }
                    setData(newData)
                }
            }
        }).catch((e) => {
            if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data) && !_.isEmpty(e.response.data.errorMessage)) {
                message.error(e.response.data.errorMessage);
            }
            console.log(e)
        })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const getColumnSearchProps = (dataIndex, columnName, si) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={si}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                clearFilters();
                                setSearchText("");
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => si.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex && !_.isEmpty(searchText)
                ? highlightText(text)
                : !_.isEmpty(text) ? text : <span className="text-center">-</span>
    });

    const highlightText = (text) => {
        return getHighlighted(text);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getHighlighted = (text) => {
        return (
            <Highlighter
                highlightStyle={{
                    backgroundColor: "#ffc069",
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        );
    };

    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
    }

    const columns = [
        {
            title: "Sno",
            dataIndex: "sno",
            key: "sno",
            width: 120,
            onCell: () => ({
                style: {
                    paddingLeft: '50px'
                }
            }),
            onHeaderCell: () => ({
                style: {
                    paddingLeft: '50px'
                }
            })
        },
        {
            title: 'Port',
            dataIndex: "portid",
            key: 'portid',
            width: 180,
            sorter: (a, b) => a.portid - b.portid,
            sortDirections: ["descend"],
            render: (text) => {
                return !_.isEmpty(text) ? text : <span className="text-center">-</span>
            },
            onHeaderCell: () => ({
                style: {
                    paddingLeft: '50px'
                }
            }),
            onCell: () => ({
                style: {
                    paddingLeft: '50px'
                }
            })
        },
        {
            title: 'Protocol',
            dataIndex: "protocol",
            key: 'protocol',
            width: 130,
            ...getColumnSearchProps("protocol", "protocol", searchInput),
        },
        {
            title: 'State',
            dataIndex: "state",
            key: 'state',
            width: 150,
        },
        {
            title: 'Name',
            dataIndex: "name",
            key: 'name',
            ellipsis: true,
            ...getColumnSearchProps("name", "name", searchInput),
        },
        {
            title: 'Product',
            dataIndex: "product",
            key: 'product',
            ...getColumnSearchProps("product", "product", searchInput),
        }]

    const finalData = data.map((row, index) => ({
        ...row,
        sno: index + 1,
    }));
    const filteredData = showAllComponents ? finalData : finalData.filter(item => item.state.toLowerCase() === 'open');

    if (loading) {
        return (<Loader />)
    } else {
        return (<section className="my-2">
            <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2'>
                    <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show All
                  </div>
            <div>
                <Table
                    className='custom-table'
                    columns={columns}
                    dataSource={filteredData}
                    size="small"
                    locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        position: ["bottom", "left"],
                        className: "searchPagePagination px-0",
                        pageSizeOptions: [10, 20, 25],
                    }}
                />
            </div>
        </section>)
    }
}