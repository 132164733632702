import { Space, Table, Tag, Tooltip } from "antd";
import { getAssetName, getFontColor, getStrokeColor } from "../../../shared/helper/genHelper";
import { getAssetIcon, getRegistryAssetIcon, getTooltipName } from "../../../shared/helper/getAssetImagesHelper";
import TableColumnSearch from "../../../shared/sharedComponents/antdTableComponents/tableColumnSearch";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineDashboard } from "react-icons/ai";
import NoDataFound from "../../../shared/sharedComponents/noDataFound";
import { useHistory, useLocation } from 'react-router-dom';

export default function UnifiedSummaryTable({
    data,
    isCritical
}) {
    const { getColumnSearchProps } = TableColumnSearch();
    const history = useHistory();

    const openChildPage = (e, record) => {
        e.preventDefault();
        if (record.productKey === "sca") {
            history.push(`/dashboard-scan-details/${record.reportId}`);
        } else if (record.productKey === "sast") {
            history.push(`/sast-advance-dashboard/${record.reportId}`);
        } else if (record.productKey === "dast") {
            history.push(`/web-security-advance-dashboard/${record.reportId}`);
        } else if (record.productKey === "cloud") {
            history.push(`/cloud-security-advance-dashboard/${record.reportId}`);
        } else if (record.productKey === "container") {
            history.push(`/container-security/${record.reportId}`);
        } else if (record.productKey === "kubernetes") {
            history.push(`/k8s-advance-dashboard/${record.reportId}/${record.integrationId}`);
        }
    }

    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const customNameRender = (text, record, { highlightComponent }) => (
        record.productKey === "sca" ? <span>
            {!_.isEmpty(record) && !_.isEmpty(record.url) && isValidHttpUrl(record.url)
                ? <a
                    title={record.url}
                    href={record.subAssetType === 'azure-scm' ? `${record.url}?version=GB${record.branch}` : record.url}
                    target="_blank"
                    style={{
                        color: "inherit"
                    }}
                >
                    {getAssetIcon(record.subAssetType)}
                </a>
                :
                getAssetIcon(record.subAssetType)
            }
            <span>
                <a onClick={(e) => openChildPage(e, record)} href="#" className="me-3 details-hover-animate" style={{ fontSize: 14 }}>
                    {highlightComponent || text}
                </a>
            </span>
        </span>
            : record.productKey === "sast" ? (
                <>
                    <Tooltip title={<span className="d-flex align-items-start jsutify-content-center flex-column"><strong>GitHub repository</strong><span>{record.assetUrl}</span></span>}>
                        <a href={record.assetUrl} target="_blank" rel="noreferrer" className="text-secondary" style={{
                            color: "inherit !important"
                        }}>
                            {getAssetIcon(record.assetType)}
                        </a>
                    </Tooltip>
                    <a onClick={(e) => openChildPage(e, record)} href="#" className="me-3 details-hover-animate">
                        {highlightComponent || getAssetName(record.assetUrl)}
                    </a>
                    {record.scanStatus.toLowerCase() === 'failed' &&
                        <Tooltip title={record.errorMessage}>
                            <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
                        </Tooltip>
                    }
                </>
            )
                : record.productKey === "container" ? <span>
                    {!_.isEmpty(record) && !_.isEmpty(record.url) && isValidHttpUrl(record.url) ? (
                        <a
                            title={record.url}
                            href={
                                record.subAssetType === "azure-scm"
                                    ? `${record.url}?version=GB${record.branch}`
                                    : record.url
                            }
                            target="_blank"
                            style={{
                                color: "inherit",
                            }}
                        >
                            {getRegistryAssetIcon(record.registryType)}
                        </a>
                    ) : (
                        getRegistryAssetIcon(record.registryType)
                    )}

                    <span title={record.url} className="ps-2">
                        {!_.isEmpty(record) ?
                            <a
                                href="#"
                                target="_blank"
                                onClick={(e) => openChildPage(e, record)} >
                                {highlightComponent || text}
                            </a> : text}
                    </span>
                </span>
                    : record.productKey === "cloud" ? <>
                        <Tooltip title={getTooltipName(record.integrationType)}>
                            <span className="text-secondary">
                                {getAssetIcon(record.integrationType)}
                            </span>
                        </Tooltip>
                        <a
                            onClick={(e) => openChildPage(e, record)}
                            href="#"
                            className="me-2 details-hover-animate"
                        >
                            {highlightComponent || text}
                        </a>
                    </>
                        : record.productKey === "kubernetes" ?
                            <a onClick={(e) => openChildPage(e, record)} href="#" className={`me-2 details-hover-animate ${_.has(record, "overallStatus") && record.overallStatus.toLowerCase() === "initiated"
                                ? "disabled"
                                : ""
                                }`}>
                                {getAssetIcon(record.provider)}
                                {text}
                            </a>
                            : <>
                                <Tooltip title={"Website"}>
                                    <span className="text-secondary" style={{
                                        color: "inherit !important"
                                    }}>
                                        <FontAwesomeIcon
                                            title={"Website"}
                                            icon={faGlobe}
                                            className="me-2"
                                        />
                                    </span>
                                </Tooltip>
                                <a onClick={(e) => openChildPage(e, record)} href="#" className="me-3 details-hover-animate" style={{ fontSize: 14 }}>
                                    {highlightComponent || text}
                                </a>
                            </>);

    const getType = (productKey) => {
        let type = ""
        if (productKey === "sca") {
            type = "SCA"
        } else if (productKey === "sast") {
            type = "SAST"
        } else if (productKey === "dast") {
            type = "Web Security"
        } else if (productKey === "cloud") {
            type = "Cloud Security"
        } else if (productKey === "container") {
            type = "Container Security"
        } else if (productKey === "kubernetes") {
            type = "Kubernetes Security"
        }
        return type
    }

    let columns = [
        {
            title: 'Sno',
            dataIndex: "sno",
            key: 'sno',
            width: 40,
        },
        {
            title: "Project",
            dataIndex: 'name',
            key: 'name',
            width: 290,
            vAlign: "top",
            ellipsis: true,
            ...getColumnSearchProps('projectId', customNameRender, 'Unified Reports')
        },
        {
            title: "Type",
            key: 'name',
            width: 120,
            vAlign: "top",
            render: (_, record) => {
                return getType(record.productKey)
            }
        },
        {
            title: "Total",
            dataIndex: "total",
            width: 75,
            sorter: (a, b) => a.total - b.total,
            sortDirections: ["descend"],
            align: (val, row) => {
                return row.colSpan ? "left" : "center";
            },
            render: (val, row) => {
                return val ? (
                    <div className="d-flex align-items-start justify-content-center flex-column">
                        <span
                            style={{
                                color: "#adb5bd",
                                borderRadius: 50,
                                width: 40,
                                border: "none",
                                boxShadow: "none",
                                fontSize: 14,
                            }}
                            className={"me-0 text-center d-inline-block"}
                        >
                            {row.total}
                        </span>
                    </div>
                ) : (
                    <span
                        style={{
                            color: "#adb5bd",
                            borderRadius: 50,
                            width: 40,
                            border: "none",
                            boxShadow: "none",
                            fontSize: 14,
                        }}
                        className={"me-0 text-center d-inline-block"}
                    >
                        0
                    </span>
                );
            },
        },
        {
            title: "Critical",
            dataIndex: "critical",
            width: 95,
            sorter: (a, b) => a.critical - b.critical,
            sortDirections: ["descend"],
            align: "center",
            render: (val, row) => {
                if (val) {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("CRITICAL"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("CRITICAL")}
                                className={"mx-auto text-center"}
                            >
                                {row.critical}
                            </Tag>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("CRITICAL"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("CRITICAL")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        </div>
                    );
                }
            },
        },
        {
            title: "High",
            dataIndex: "high",
            width: 85,
            sorter: (a, b) => a.high - b.high,
            sortDirections: ["descend"],
            align: "center",
            render: (val, row) => {
                if (val) {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("HIGH"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("HIGH")}
                                className={"mx-auto text-center"}
                            >
                                {row.high}
                            </Tag>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("HIGH"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("HIGH")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        </div>
                    );
                }
            },
        },
        {
            title: "Medium",
            dataIndex: "medium",
            width: 95,
            sorter: (a, b) => a.medium - b.medium,
            sortDirections: ["descend"],
            align: "center",
            render: (val, row) => {
                if (val) {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("MEDIUM"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("MEDIUM")}
                                className={"mx-auto text-center"}
                            >
                                {row.medium}
                            </Tag>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("MEDIUM"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("MEDIUM")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        </div>
                    );
                }
            },
        },
        {
            title: "Low",
            dataIndex: "low",
            width: 85,
            sorter: (a, b) => a.low - b.low,
            sortDirections: ["descend"],
            align: "center",
            render: (val, row) => {
                if (val) {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("LOW"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("LOW")}
                                className={"mx-auto text-center"}
                            >
                                {row.low}
                            </Tag>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("LOW"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("LOW")}
                                className={"mx-auto text-center"}
                            >
                                {0}
                            </Tag>
                        </div>
                    );
                }
            },
        },
        {
            title: "NA",
            dataIndex: "na",
            width: 85,
            sorter: (a, b) => a.na - b.na,
            sortDirections: ["descend"],
            align: "center",
            render: (val, row) => {
                if (val) {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("NA"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("NA")}
                                className={"mx-auto text-center"}
                            >
                                {row.na}
                            </Tag>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <Tag
                                style={{
                                    color: getFontColor("NA"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14,
                                }}
                                color={getStrokeColor("NA")}
                                className={"mx-auto text-center"}
                            >
                                {0}
                            </Tag>
                        </div>
                    );
                }
            },
        },
        {
            title: "Action",
            key: 'action',
            fixed: "right",
            width: 80,
            render: (_, row) => <Space>
                <a onClick={(e) => openChildPage(e, row)} href={`#`} className={`me-2 details-hover-animate`}>
                    <Tooltip title="Project Dashboard">
                        <AiOutlineDashboard style={{ fontSize: 20 }} />
                    </Tooltip>
                </a>
            </Space>,
        },
    ]

    if (isCritical) {
        const initialCols = columns.slice(0, 3);
        const totalCol = columns.slice(3, 4);
        const criticalCol = columns.slice(4, 5);
        const nextCol1 = columns.slice(5, 8);
        const nextCol2 = columns.slice(8);
        columns = [...initialCols, ...criticalCol, ...nextCol1, ...totalCol, ...nextCol2]
    }

    const finalDataTotal = [...data].sort((a, b) => b.total - a.total).map((row, index) => ({
        ...row,
        key: `dataRow-${index}`,
        sno: index + 1,
    }));

    return (<Table
        size='small'
        className='custom-table mb-0'
        columns={columns}
        dataSource={finalDataTotal}
        pagination={{
            showTotal: (total, range) => {
                return (
                    <span className="text-white">
                        {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                    </span>
                );
            },
            defaultPageSize: 10,
            showSizeChanger: true,
            position: ["bottom", "left"],
            className: "searchPagePagination px-0",
            pageSizeOptions: [10, 20, 25],
        }}
        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
    />)
}