import React, { forwardRef, useState } from 'react';
import { Table, Tooltip, Tag, Popover } from 'antd';
import { MdOutlineRefresh } from "react-icons/md";
import { AiOutlineDashboard } from "react-icons/ai";
import { TiWarning } from "react-icons/ti";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RiQrScan2Line } from "react-icons/ri";
import { TbBinaryTree } from 'react-icons/tb';
import gcpIcon from '../../assets/img/scopy-tech-icons/gcp.svg';
import awsIcon from '../../assets/img/scopy-tech-icons/aws.svg';
import azureIcon from '../../assets/img/scopy-tech-icons/azure.svg';
import oracleIcon from '../../assets/img/techIcons/oracle.png';
import _ from 'lodash';
import moment from "moment";
import NoDataFound from '../../shared/sharedComponents/noDataFound';
import Loader from '../welcomeScreen/loader';
import { formatScanKey, getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTimesCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getAssetIcon, getTooltipName } from '../../shared/helper/getAssetImagesHelper';
import { addUpdateKubernetesSecurityDashboardTableData, setKubernetesSecurityScanTriggeredState } from '../../shared/redux/reducers/kubernetesSecurityReducer';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
// import { ScanStatusIndicator } from '../../shared/sharedComponents/scanStatusIndicator';
import { useHistory } from "react-router-dom";
import AdvancedSearch from '../scaDashboard/advancedSearch';

const RefreshIcon = forwardRef((props, ref) => (
    <span ref={ref}>
        <MdOutlineRefresh {...props} />
    </span>
));

const DashboardIcon = forwardRef((props, ref) => (
    <span ref={ref}>
        <AiOutlineDashboard {...props} />
    </span>
));

const ComponentTable = ({ data: dataSet,
    advanceSearchInfo,
    handleSearchChange,
    handleSearchSubmit,
    handleClearFilters,
    handleResetAllFilters,
    isAdvanceSearchApplied
}) => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory()

    const handleReScanClick = (e, toScanRow) => {
        e.preventDefault();
        let isDemoUser = localStorage.getItem('isDemoUser');
        if (isDemoUser && isDemoUser === 'true') {
            alert("Scan has been disabled for this account");
        }
        else {
            triggerRescan(toScanRow);
        }
    }


    const triggerRescan = (toScanRow) => {
        if (!_.isEmpty(toScanRow)) {
            dispatch(setKubernetesSecurityScanTriggeredState(true));
            const postBody = {
                "integrationId": toScanRow.integrationId,
                "targetSystem": toScanRow.integrationType
            };
            axios
                .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/ascan`, postBody)
                .then((op) => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        dispatch(addUpdateKubernetesSecurityDashboardTableData(op.data))
                    }
                })
                .catch((e) => { });
        }
        else {
            // show a alert message to tell that invalid row selected to scan
        }

    }

    const handleAdvanceDashboardBtnClick = (e, reportId, integrationId) => {
        e.preventDefault();
        history.push(`/k8s-advance-dashboard/${reportId}/${integrationId}`, '_blank');
    }

    const handleTreeBtnClick = (e, reportId, integrationId) => {
        e.preventDefault();
        history.push(`/k8s-advance-dashboard/${reportId}/${integrationId}?viewTree=true`, '_blank');
    }


    const getCurrentRunningScan = (reportScan) => {
        for (const [scanType, scanObject] of Object.entries(reportScan.scanStatuses)) {
            if (scanType !== "k8s_scan") {
                if (scanObject.status.toUpperCase() === "INITIATED") {
                    const lastUnderscoreIndex = scanType.lastIndexOf('_');
                    const firstPart = scanType.slice(0, lastUnderscoreIndex);
                    // Convert the first part to camelCase
                    const camelCase = firstPart.split('_')
                        .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
                        .join('');
                    return camelCase
                }
            }

        }
        return ""
    }

    const getScanStatus = (status, statuses) => {
        if (!_.isEmpty(status)) {
            if (status.toLowerCase() === 'failed') {
                return <Tooltip title={scanStatusList(statuses)}>
                    <span><FontAwesomeIcon className="text-danger" icon={faTimesCircle} /></span>
                </Tooltip>;
            }
            else if (status.toLowerCase() === 'unknown') {
                return '';
            }
            else if (status.toLowerCase() === 'partial') {
                return <Tooltip title={scanStatusList(statuses)} trigger={'click'}>
                    <span><TiWarning className="text-warning" /></span>
                </Tooltip>;
            }
        }
        else {
            return '';
        }
    }

    const scanStatusList = (scanStatuses) => {
        if (!scanStatuses || Object.keys(scanStatuses).length === 0) {
            return null;
        }

        return (
            <ul className="list-group list-group-flush">
                {Object.entries(scanStatuses).filter(([key]) => !"k8s_scan".includes(key)).map(([key, value]) => (
                    <li key={key} className="list-group-item bg-transparent p-2">
                        <div className='d-flex align-items-center justify-content-start'>
                            {value.status.toLowerCase() === 'completed' ? <div className='me-1'><IoCheckmarkDoneSharp className='text-success' /></div> : <div className='me-1'><IoIosCloseCircleOutline className='text-danger' /></div>}
                            <div><strong>{formatScanKey(key)}</strong></div>
                        </div>
                        {value.errorMessage && (
                            <div>
                                Error: {value.errorMessage}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    const hide = () => {
        setVisible(false);
    };

    const handleOpenChange = (newVisible) => {
        setVisible(newVisible);
    };

    const dataTableColumns = [
        {
            title: 'Sno',
            dataIndex: 'sno',
            width: 60
        },
        {
            title: 'Cluster Name',
            dataIndex: 'clusterName',
            key: 'clusterName',
            width: 280,
            render: (text, record, rowIndex) => {
                return (
                    <>
                        <a onClick={(e) => handleAdvanceDashboardBtnClick(e, record.reportId, record.integrationId)} href="#" className={`me-2 details-hover-animate ${_.has(record, "overallStatus") && record.overallStatus.toLowerCase() === "initiated"
                            ? "disabled"
                            : ""
                            }`}>
                            {getAssetIcon(record.provider)}
                            {text}
                        </a>
                        {(!_.isEmpty(record.overallStatus) && (record.overallStatus.toLowerCase() === 'failed') || record.overallStatus.toLowerCase() === 'unknown' || record.overallStatus.toLowerCase() === 'partial') &&
                            <span>
                                {getScanStatus(record.overallStatus, record.scanStatuses)}
                            </span>
                        }
                        {/* {_.has(record, "overallStatus") && record.overallStatus.toLowerCase() === "failed" && <span>
                            <Tooltip title={record.overallErrorMessage}>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        className="ms-2 text-danger"
                                    />
                                </span>
                            </Tooltip>
                        </span>} */}
                    </>)
            }
        },
        {
            title: 'Total',
            dataIndex: 'totalCve',
            key: 'totalCve',
            align: 'center',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Critical',
            dataIndex: 'criticalCve',
            key: 'criticalCve',
            align: 'center',
            render: (text, record) => (<Tag
                style={{
                    color: getFontColor("CRITICAL"),
                    borderRadius: 50,
                    width: 40,
                    fontSize: 14,
                }}
                color={getStrokeColor("CRITICAL")}
                className={"mx-auto text-center"}
            >
                {text}
            </Tag>)
        },
        {
            title: 'High',
            dataIndex: 'highCve',
            key: 'highCve',
            align: 'center',
            render: (text, record) => (<Tag
                style={{
                    color: getFontColor("HIGH"),
                    borderRadius: 50,
                    width: 40,
                    fontSize: 14,
                }}
                color={getStrokeColor("HIGH")}
                className={"mx-auto text-center"}
            >
                {text}
            </Tag>
            )
        },
        {
            title: 'Medium',
            dataIndex: 'mediumCve',
            key: 'mediumCve',
            align: 'center',
            render: (text, record) => (<Tag
                style={{
                    color: getFontColor("MEDIUM"),
                    borderRadius: 50,
                    width: 40,
                    fontSize: 14,
                }}
                color={getStrokeColor("MEDIUM")}
                className={"mx-auto text-center"}
            >
                {text}
            </Tag>
            )
        },
        {
            title: 'Low',
            dataIndex: 'lowCve',
            key: 'lowCve',
            align: 'center',
            render: (text, record) => (<Tag
                style={{
                    color: getFontColor("LOW"),
                    borderRadius: 50,
                    width: 40,
                    fontSize: 14,
                }}
                color={getStrokeColor("LOW")}
                className={"mx-auto text-center"}
            >
                {text}
            </Tag>
            )
        },
        {
            title: 'NA',
            dataIndex: 'naCve',
            key: 'naCve',
            align: 'center',
            render: (text, record) => (<Tag
                style={{
                    color: getFontColor("NA"),
                    borderRadius: 50,
                    width: 40,
                    fontSize: 14,
                }}
                color={getStrokeColor("NA")}
                className={"mx-auto text-center"}
            >
                {text}
            </Tag>
            )
        },
        {
            title: 'Last Scan',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
            sortDirections: ["ascend", "descend"],
            align: 'center',
            render: (text, record) => (_.has(record, "overallStatus") && record.overallStatus.toLowerCase() === "initiated" ? (<div className='d-flex align-items-center justify-content-center'>
                <div className=''>
                    Scanning <span>{getCurrentRunningScan(record)}</span>
                    <div className='loader-moving-line'></div>
                </div>
            </div>) : (moment.utc(text).local().fromNow()
            )),
            ellipsis: true,
        },
        {
            title: <span>Actions<span className="ms-4">
                <Popover
                    open={visible}
                    trigger="click"
                    title={
                        <div className='d-flex justify-content-end'>
                            <span className='ms-auto px-2'
                            >
                                <FontAwesomeIcon icon={faClose} onClick={hide} style={{ cursor: "pointer", fontSize: 18 }} />
                            </span>
                        </div>}
                    content={
                        <AdvancedSearch advanceSearchInfo={advanceSearchInfo} handleSearchChange={handleSearchChange} handleSearchSubmit={() => {
                            hide();
                            handleSearchSubmit()
                        }} handleClearFilters={handleClearFilters} />}
                    placement="leftTop"
                    onOpenChange={handleOpenChange}>
                    <span
                        className={(isAdvanceSearchApplied ? "text-danger" : "text-primary")} style={{ cursor: "pointer" }}>
                        Advanced Search
                        {isAdvanceSearchApplied && <FontAwesomeIcon icon={faClose} style={{ fontSize: 14 }} className="ms-1" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation()
                            handleResetAllFilters()
                        }} />}</span>
                </Popover>
            </span>
            </span>,
            fixed: 'right',
            width: 220,
            render: (text, record) => (
                <>
                    <Tooltip title={'Rescan'}>
                        <a
                            onClick={(e) => handleReScanClick(e, record)}
                            className={`me-3 details-hover-animate ${_.has(record, "overallStatus") && record.overallStatus.toLowerCase() === "initiated"
                                ? "disabled"
                                : ""
                                }`}
                        >
                            <RiQrScan2Line style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                    <Tooltip title="Advance Dashboard">
                        <a onClick={(e) => handleAdvanceDashboardBtnClick(e, record.reportId, record.integrationId)} href={`#`}
                            className={`me-3 details-hover-animate ${_.has(record, "overallStatus") && record.overallStatus.toLowerCase() === "initiated"
                                ? "disabled"
                                : ""
                                }`}>
                            <DashboardIcon style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                    <Tooltip title="Visualize Cluster">
                        <a onClick={(e) => handleTreeBtnClick(e, record.reportId, record.integrationId)} href={`#`}
                            className={`details-hover-animate ${_.has(record, "overallStatus") && record.overallStatus.toLowerCase() === "initiated"
                                ? "disabled"
                                : ""
                                }`}>
                            <TbBinaryTree style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                </>
            ),
        }
    ];


    const data = dataSet.map((row, index) => ({
        key: `k8-security-table-${index}`,
        sno: index + 1,
        ...row,
    }));
    return (
        <div>

            <Table
                size='small'
                className='custom-table mb-0 fixedScrollCustomTable table-hover'
                dataSource={!_.isEmpty(data) ? data : []}
                columns={dataTableColumns}
                // pagination={false}
                locale={{
                    emptyText: <NoDataFound title={'No Data'} />
                }}
                pagination={(!_.isEmpty(data) && data.length > 10) ? {
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {range[0]}-{range[1]} of {total && total.format()}{" "}
                                issues
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                } : false}
            />
        </div>
    );

};
export default ComponentTable;