
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faFileCode, faFileZipper } from "@fortawesome/free-solid-svg-icons";
import { faBitbucket, faDocker, faGithub } from "@fortawesome/free-brands-svg-icons";
import azureScm from '../../assets/img/scopy-tech-icons/azure-scm.svg';
import aws from '../../assets/img/scopy-tech-icons/aws.svg';
import gcp from '../../assets/img/scopy-tech-icons/gcp.svg';
import azure from '../../assets/img/scopy-tech-icons/azure.svg';
import oracle from '../../assets/img/techIcons/oracle.png';
// container icons
import jfrog from "../../assets/img/scopy-tech-icons/jfrog.svg";
import ibm from "../../assets/img/scopy-tech-icons/ibm.svg";
import azureRepos from "../../assets/img/azure-repos.svg";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import { Tooltip } from "antd";

export function getAssetIcon(assetName) {
    switch (assetName) {
        case "github":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faGithub}
                    className="me-2"
                />
            );
        case "bitbucket":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faBitbucket}
                    className="me-2"
                />
            );
        case "archive":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faFileZipper}
                    className="me-2"
                />

            );
        case "file-cli":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faFileCode}
                    className="me-2"
                />
            );
        case "sbom":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faFileCode}
                    className="me-2"
                />
            );
        case "container":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faDocker}
                    className="me-2"
                />
            );
        case "docker":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faDocker}
                    className="me-2"
                />
            );
        case "azure-scm":
            return (
                <img src={azureScm} title={assetName} className="me-2" style={{ width: 13 }} />
            );
        case "aws":
            return (
                <img src={aws} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "google":
            return (
                <img src={gcp} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "gcp":
            return (
                <img src={gcp} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "azure":
            return (
                <img src={azure} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "oracle":
            return (
                <img src={oracle} title={assetName} className="me-2" style={{ width: 18 }} />
            );

        default:
            return (
                <Tooltip title="Unable to identify the artifact">
                    <span>
                        <FontAwesomeIcon icon={faCircleQuestion} className="me-2" />
                    </span>
                </Tooltip>
            );
    }
};


export function getTooltipName(assetName) {

    switch (assetName) {
        case 'aws':
            return 'AWS Cloud';
        case 'google':
            return 'Google Cloud';
        case 'gcp':
            return 'Google Cloud Platform';
        default:
            return 'N/A';
    }
}

export const getRegistryAssetIcon = (registryType) => {
    const card = {
      alt: "title",
    };
    switch (registryType) {
      case "ibmcr":
        return (
          <Tooltip title="IBM">
            <img
              src={ibm}
              alt={"IBM"}
              className="img-fluid"
              style={{ width: 18 }}
            />
          </Tooltip>
        );
      case "jfrog":
        return (
          <Tooltip title="AWS">
            <img
              src={jfrog}
              alt={"Jfrog"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "awsecr":
        return (
          <Tooltip title="AWS">
            <img
              src={awsCi}
              alt={"AWS"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "azurecr":
        return (
          <Tooltip title="Azure">
            <img
              src={azureRepos}
              alt={"Azure"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "dockerhub":
        return (
          <Tooltip title="Docker">
            <FontAwesomeIcon
              title="docker"
              icon={faDocker}
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "gcr":
        return (
          <Tooltip title="Google">
            <img
              src={googleCloud}
              alt={"Google"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Unable to identify the artifact">
            <span>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </span>
          </Tooltip>
        );
    }
  };