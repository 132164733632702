import React, { useState, useEffect } from "react";
import { faLayerGroup, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faRegistered } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Progress, message, Upload, Tooltip, Switch } from "antd";
import _ from "lodash";
import FormData from "form-data";
import Loader from "../welcomeScreen/loader";
import { useHistory } from "react-router-dom";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";

import ddos from "../../assets/img/ddos.png";
import chip from "../../assets/img/chip.png";

import { GlobalConst } from "../../shared/appConfig/globalConst";
import {
  setScanModalViewState,
  setScanGuidResponse,
  setScannedRecords,
  setAllUserRepoList,
  setNoScannedRecords,
  setCurrentDashboardPageNumber,
  setSanningFromDrag,
} from "../../shared/redux/reducers/scannerReducer";
import { removeGitSuffix, getUserDisplayName, getProductSubscription, getPlanTypeFromProductKey, capitalizeFirstLetter } from "../../shared/helper/genHelper";
// import { setLoginModalViewStatus } from "../../shared/redux/reducers/authReducer";
import TidyTreeView from "./tidyTree";
import DonutHighChart from "./donutHighChart";
import StackedGraph from "./StackedGraph";
import RepoListScannedDataTable from "./RepoListScannedDataTable";
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import { TbGridScan } from "react-icons/tb";

export default function ScaDashboard() {


  const [searchResults, setSearchResults] = useState({});
  const history = useHistory();
  const halfCircleColor = ["#785add", "#2db46b", "#3f80e2"];
  let intervalRecord = null;

  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const loadingRows = useSelector(
    (state) => state.scannerReducer.scanGuidResponse
  );
  const scanGuidResponse = useSelector(
    (state) => state.scannerReducer.scanGuidResponse
  );
  const subscribedProductsReducer = useSelector(state => state.productsReducer);

  const [loadingTree, setLoadingTree] = useState(false);

  const [data, setData] = useState([]);
  // const [stopScanning, setStopScanning] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  // const [isScanningFromDrag, setIsScanningFromDrag] = useState(false);
  const [loadingDashboardData, setLoadingDashboardData] = useState(false);
  const [isEnableTraceibility, setIsEnableTraceibility] = useState(false);
  const [userPrefrences, setUserPrefrences] = useState(null);
  const [advanceSearchInfo, setAdvanceSearchInfo] = useState({
    package: "",
    version: "",
    cves: ""
  })
  const [isAdvanceSearchApplied, setIsAdvanceSearchApplied] = useState(false)

  const { Dragger } = Upload;

  // useEffect(() => {
  //   async function isSessionActive() {
  //     let ls = await localStorage.getItem("__t");
  //     if (_.isEmpty(ls)) {
  //       history.push("/");
  //       window.location.href = GlobalConst.BASE_URL + '/login';
  //       // dispatch(setLoginModalViewStatus(true));
  //     }
  //   }
  //   isSessionActive();
  // }, []);

  useEffect(() => {
    // When the user reaches here for the first time load the result and then start the scanning of the loaded results.
    if (!_.isEmpty(reduxState.authReducer.token)) {
      // if (reduxState.authReducer.userDetails.isFirstTimeLogin) {
      getRepoList(); // journey when user is logged in for the first time in the system.
      // }
      // else {
      getDashboardData(true, true); // journey when the user is logged in for the next subsequent times.
      // }
    } else {
      // Show the login popup here.
      // console.log("triggered else::");
      // dispatch(setLoginModalViewStatus(true));
    }
  }, [
    reduxState.authReducer.token,
    reduxState.authReducer.userDetails.isFirstTimeLogin,
  ]);

  useEffect(() => {
    // console.log("Auth State:", authState);
    let userId =
      !_.isEmpty(reduxState.authReducer) &&
        !_.isEmpty(reduxState.authReducer.userDetails) &&
        !_.isEmpty(reduxState.authReducer.userDetails.email)
        ? reduxState.authReducer.userDetails.email
        : "";

    if (!_.isEmpty(userId)) {
      getPrefrences(userId);
    }
  }, [reduxState.authReducer]);

  const handleDashboardReload = () => {

    window.location.reload();

  }

  //     useEffect(()=>{
  // console.log("repo list:", reduxState.scannerReducer.allUserRepoList)
  //     },[reduxState.scannerReducer.allUserRepoList]);

  const draggerProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    className: "custom-dragger-upload",
    customRequest: (p1, p2, p3) => {
      // console.log("I am fired here", p1, p2, p3)
    },
    onChange(info) {
      // console.log("I am the file uploaded", info);

      // const FormData = require('form-data');
      // const fs = require('fs');
      message.info(
        `${info.file.name} file uploaded successfully, scanning in progress.`
      );

      let presentRecord = _.find(
        reduxState.scannerReducer.scannedRecords,
        function (o) {
          return removeGitSuffix(o.url) === removeGitSuffix(info.file.name);
        }
      );

      if (!_.isEmpty(presentRecord)) {
        // when there is a record found so we need to move the record to the first place and trigger the scan
        // console.log("I found the record now and i will shift the record and trigger the scan now.",);

        dispatch(setCurrentDashboardPageNumber(1)); // change the page number on the dashboard.
        var clonedRecords = _.cloneDeep(
          reduxState.scannerReducer.scannedRecords
        );

        // console.log("State records:", reduxState.scannerReducer.scannedRecords);

        // get the record on top of the array...

        presentRecord = {
          ...presentRecord,
          rowStatus: "INITIATED",
        };
        // console.log("The present record again:", presentRecord);
        // _.pull(clonedRecords, presentRecord); // Remove the object from the array
        clonedRecords = _.remove(
          clonedRecords,
          (o) => o.key !== presentRecord.key
        );
        // console.log("ShiftedArray:", clonedRecords);
        clonedRecords.unshift(presentRecord); // Add the object to the beginning of the array

        // console.log("Moved Array: ", clonedRecords);

        // reset the key of the array by modifying the keys.
        let newData = [];

        if (!_.isEmpty(clonedRecords)) {
          clonedRecords.forEach((el, ind) => {
            newData.push({
              ...el,
              key: `dataRow-${ind}`,
              serialNumber: ind + 1,
            });
          });
        }
        // console.log("New data Created::", newData);

        dispatch(setScannedRecords(newData));
        // let requestList = [];
        // requestList.push({
        //     location: presentRecord.url,
        //     urlType: "github"
        // });
        // dispatch(setLoadingRows(requestList));
        // triggerScan(requestList);
      } else {
        let newRecord = {
          key: `dataRow-new`,
          serialNumber: 1,
          name: decodeURIComponent(info.file.name),
          owner: "",
          org: "",
          total: 0,
          critical: 0,
          high: 0,
          medium: 0,
          low: 0,
          na: 0,
          type: "github",
          url: info.file.name,
          assetType: "file",
          subAssetType: "sbom",
          branch: '',
          reportId: "",
          scanDate: "",
          scanningRow: false,
          rowStatus: "INITIATED",
          ecosystem: [],
          source: null
        };

        let updatedScanRecords = [
          { ...newRecord },
          ...reduxState.scannerReducer.scannedRecords,
        ];
        let newData = [];

        if (!_.isEmpty(updatedScanRecords)) {
          updatedScanRecords.forEach((el, ind) => {
            newData.push({
              ...el,
              key: `dataRow-${ind}`,
              serialNumber: ind + 1,
            });
          });
        }

        dispatch(setScannedRecords(newData)); // Update the redux state array to load the first record on top.
      }

      let data = new FormData();
      // console.log("I am the file obj", info.file.originFileObj);
      data.append("file", info.file.originFileObj);

      let config = {
        method: "post",
        // maxBodyLength: Infinity,
        url: `${GlobalConst.API_URL}/auth/foss/scan/file`,
        headers: { "content-type": "multipart/form-data" },
        data: data,
      };
      // console.log("Configuration::", config);
      // setIsScanningFromDrag(true);
      dispatch(setSanningFromDrag(true));
      axios
        .request(config)
        .then((response) => {
          // console.log("I am the response here::", response.data);
          if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
            // Add the file to the scannerList here.

            // update the uuid in the table.

            dispatch(setScanGuidResponse(response.data));

            // message.success(`${info.file.name} file scanning complete.`);
          }
          // setIsScanningFromDrag(false);
          // console.log(JSON.stringify(response.data));
        })
        .catch((e) => {
          console.log("Exception", e);
          // setIsScanningFromDrag(false);
          dispatch(setSanningFromDrag(false));
          message.error(`${info.file.name} is not a valid file.`);
        });

      // setTimeout(() => { getDashboardData() }, 500);
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const getDashboardData = (loaderStatus, reloadTable, traceabilityFlag = false) => {
    setLoadingDashboardData(loaderStatus);
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/user/scan-stats`, {
        userId: reduxState.authReducer.userDetails.email,
        showOnlyTracingData: traceabilityFlag
      })
      .then((op) => {
        setLoadingDashboardData(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {

          setDashboardData(op.data); // Set the dashboard dataset to load the dashboard values.


          if (reloadTable === true) {
            if (!_.isEmpty(op.data.assetList)) {


              // get the first record and fire the api to get the report details so that we can get latest reports vtrace diagram.
              for (const [key, value] of Object.entries(op.data.assetList)) {
                if (value.status.toLowerCase() === 'completed' && value.totalCve > 0) {
                  handleVTraceClick(value.reportId);
                  break;
                }
              }

              let newData = [];

              let guidArray = [];
              Object.entries(op.data.assetList).forEach(([key, value], ind) => {

                let name = "";
                if (isValidHttpUrl(key)) {
                  var pathname = new URL(key).pathname;
                  pathname = pathname.substring(1);
                  name = pathname;
                } else {
                  name = key;
                }

                newData.push({
                  key: `dataRow-${ind}`,
                  serialNumber: ind + 1,
                  name: decodeURIComponent(name),
                  owner: "",
                  org: "",
                  total: value.totalCve,
                  critical: value.CRITICAL,
                  high: value.HIGH,
                  medium: value.MEDIUM,
                  low: value.LOW,
                  na: (value.totalCve - (value.CRITICAL + value.HIGH + value.MEDIUM + value.LOW)),

                  type: value.subAssetType,
                  url: key,
                  assetType: value.assetType,
                  subAssetType: value.subAssetType,
                  reportId: value.reportId,
                  scanDate: value.scanDate,
                  scanningRow: false,
                  branch: value.branch,
                  rowStatus: value.status,
                  ecosystem: value.ecosystem,
                  source: value.source,
                  errorMessage:
                    value.status.toLowerCase() === 'failed' ? value.errorMessage : "",
                });
                if (value.status.toLowerCase() === 'initiated' || value.status.toLowerCase() === 'scanning') {
                  guidArray.push({
                    uuid: value.reportId,
                    urlType: value.subAssetType,
                    location: key,
                    assetType: value.assetType,
                    assetSubtype: value.subAssetType,
                    source: value.source,
                  });
                }
              });
              dispatch(setScanGuidResponse(guidArray)); // update the guid state in redux for the scans which are still in initiate or scanning state
              dispatch(setScannedRecords(newData)); // Update the new set with the new scanned records data to populate on the dashboard screen.
              dispatch(setNoScannedRecords(false));
              // setData(newData);
            } else {
              dispatch(setNoScannedRecords(true));
            }
          }
        } else {
          dispatch(setNoScannedRecords(true));
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
        setLoadingDashboardData(false);
        dispatch(setNoScannedRecords(true));
      });
  };

  const getRepoList = () => {
    // user will reach here when is logs in for the first time this below api will get all the repo list of the user.
    const requestBody = {
      urlType: "github",
      "page": reduxState.scannerReducer.userListPageNumber,
      "recordsPerPage": GlobalConst.GIT_REPO_LIST_PAGE_COUNT
    };
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, requestBody)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          // once you get all the repo result from the backend start processing the data.
          dispatch(setAllUserRepoList(op.data.data)); // Store the data in the reduxStore for future use.
          if (reduxState.authReducer.userDetails.isFirstTimeLogin) {
            processData(op.data.data); // Once you get all the data start processing the data.
          }
          // localStorage.setItem("fl", 'false');
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
      });
  };

  const scanRecords = (toScanRecords) => {
    // Trigger the scanning (when user is a first time user)
    if (!_.isEmpty(toScanRecords)) {
      let requestList = [];
      toScanRecords.forEach((el) => {
        requestList.push({
          location: el.url,
          urlType: "github",
        });
      });
      let requestBody = {
        urlType: "github",
        scanRequestList: requestList,
      };
      axios
        .post(
          `${GlobalConst.API_URL}/auth/foss/ascan?enableSbom=true`,
          requestBody
        )
        .then((op) => {
          if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
            // This api initiates all the scans and then sends the GUID against all the initiated scans.
            dispatch(setScanGuidResponse(op.data));
          }
        })
        .catch((e) => {
          console.log("Exception: ", e);
        });
    } else {
      // show user an error message saying no data to scan.
    }
  };

  const processData = async (allRepoList) => {
    if (!_.isEmpty(allRepoList)) {
      // prepare new data here to match the row records.
      let newData = [];
      allRepoList.forEach((el, ind) => {
        if (ind < GlobalConst.SCAN_REPO_LIMIT) {
          let name = "";
          if (isValidHttpUrl(el.name)) {
            var pathname = new URL(el.name).pathname;
            name = pathname;
          } else {
            name = el.name;
          }
          newData.push({
            key: `dataRow-${ind}`,
            serialNumber: ind + 1,
            name: decodeURIComponent(name),
            owner: el.owner,
            org: el.group,
            total: 0,
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            na: 0,
            type: "github",
            url: el.url,
            branch: el.branch,
            assetType: el.assetType ? el.assetType : "",
            subAssetType: el.subAssetType ? alert.subAssetType : "",
            reportId: "",
            scanDate: el.scanDate ? el.scanDate : null,
            ecosystem: el.ecosystem,
            scanningRow: false,
            rowStatus: "INITIATED",
            errorMessage: "",
            source: el.source
          });
        }
      });
      // await setData(newData);
      dispatch(setScannedRecords(newData)); // note: this data is only few records as set in the SCAN_REPO_LIMIT variable in global constants.
      scanRecords(newData); // once the data is prepared scan the records by initiating the scan api
    } else {
      // show an error message to the user saying we didnt find any repositories in your account.
    }
  };

  const handleiScanClick = () => {
    dispatch(setScanModalViewState(true));
  };

  const getName = (key) => {
    let name = "";
    if (isValidHttpUrl(key)) {
      var pathname = new URL(key).pathname;
      pathname = pathname.substring(1);
      name = pathname;
    } else {
      name = key;
    }
    return name;
  };

  const handleVTraceClick = (rowId) => {
    setLoadingTree(true);
    axios
      .get(`${GlobalConst.API_URL}/auth/foss/report/${rowId}`)
      .then((op) => {
        // setLoading(false);
        // console.log("Output is ::", op);
        setLoadingTree(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          // setReportDetails(op.data);
          setSearchResults(op.data.scannerResponseEntity);
        }
      })
      .catch((e) => {
        setLoadingTree(false);
        console.log("Exception: ", e);
      });
  };

  const getDataVal = (index, val) => {
    // console.log("Val::", val);
    if (!_.isEmpty(val) && !_.isEmpty(val.cveTypes)) {
      return val.cveTypes[0].cveType;
    }
    else {
      return (val.cveId) ? val.cveId : '-';
    }
    // switch (index) {
    //   case 0:
    //     return "Data theft";
    //   case 1:
    //     return "Unauthorized access";
    //   case 2:
    //     return "Business disruption";
    //   default:
    //     return "Data theft";
    // }
  };


  const getPackageDetails = (val, type, artifact) => {
    if (val) {
      let splitedPkg = val.split(":");
      switch (type) {
        case "artifact":
          let sText = splitedPkg[1].split('/');
          return sText[2];
        case "group":
          let splitedText = splitedPkg[1].split('/');
          return splitedText[1];
        case 'getEcosystem':
          let ecosystem = splitedPkg[1].split('/');
          return ecosystem[0];
        case 'packageName':
          // if (artifact === "maven") {
          //   let strSplit = splitedPkg[1].split('/');
          //   return `${strSplit[0]}/${strSplit[1]}:${strSplit[2]}`;
          // }
          // else {
          //   // return splitedPkg[1];
          //   // console.log(splitedPkg[1].split(/\/(.*)/s));
          //   let splitTxt = splitedPkg[1].split(/\/(.*)/s)
          //   return splitTxt[1];
          // }
          // console.log(splitedPkg[1]);
          if (!_.isEmpty(splitedPkg[1])) {

            let finalPackage = [];
            finalPackage = splitedPkg[1].split('/');
            if (!_.isEmpty(finalPackage)) {
              return finalPackage.pop();
            }
            else {
              return finalPackage[0];
            }


          }
          else {
            return splitedPkg[1];
          }
        // return splitedPkg[1];
        case 'getVersion':
          let version = splitedPkg[1].split('@')
          return version[1];
        default:
          // console.log("Here", splitedPkg[1]);
          return splitedPkg[1];
      }
    }
    else {
      return ('');
    }
  }

  const onChangeEnableTracebility = (checked) => {
    setIsEnableTraceibility(checked);
    if (checked) {
      getDashboardData(true, true, true);
    } else {
      getDashboardData(true, true);
    }
  }

  const getPrefrences = (userId) => {
    const postObj = {
      userId: userId,
    };
    axios
      .post(
        `${GlobalConst.API_URL}/auth/foss/user/get-preference`,
        postObj
      )
      .then((op) => {
        // console.log("Output: ", op);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          setUserPrefrences(op.data);
          // setErrorFetchingPrefrences(true);
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
      });
  };

  const handleSearchChange = (searchParam, searchValue) => {
    setAdvanceSearchInfo({
      ...advanceSearchInfo,
      [searchParam]: !_.isEmpty(searchValue) && (searchParam === "cves" || searchParam === "version") ? searchValue.split(",") : searchValue
    })
  }

  const handleClearFilters = () => {
    setAdvanceSearchInfo({
      package: "",
      version: "",
      cves: ""
    })
  }

  const handleSearchSubmit = () => {
    setIsAdvanceSearchApplied(true)
    getSearchedComponentCveRepos(advanceSearchInfo)
  }

  const getSearchedComponentCveRepos = (searchedData) => {
    let reqBody = {
      userId: reduxState.authReducer.userDetails.email,
    }

    if (searchedData.package) {
      reqBody = {
        ...reqBody,
        "componentName": searchedData.package
      }
    }
    if (searchedData.version) {
      reqBody = {
        ...reqBody,
        "version": searchedData.version
      }
    }
    if (searchedData.cves) {
      reqBody = {
        ...reqBody,
        "cveId": searchedData.cves
      }
    }

    // "componentName" : "spring",
    // "cveId" : ["CVE-2022-22965"],
    // "version" : ["5.1.4"]
    setLoadingDashboardData(true);
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/advance/user/scan-stats`, reqBody)
      .then((op) => {
        setLoadingDashboardData(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          if (!_.isEmpty(op.data.assetList)) {
            let newData = [];
            let guidArray = [];
            Object.entries(op.data.assetList).forEach(([key, value], ind) => {
              let name = "";
              if (isValidHttpUrl(key)) {
                var pathname = new URL(key).pathname;
                pathname = pathname.substring(1);
                name = pathname;
              } else {
                name = key;
              }

              newData.push({
                key: `dataRow-${ind}`,
                serialNumber: ind + 1,
                name: decodeURIComponent(name),
                owner: "",
                org: "",
                total: value.totalCve,
                critical: value.CRITICAL,
                high: value.HIGH,
                medium: value.MEDIUM,
                low: value.LOW,
                na: (value.totalCve - (value.CRITICAL + value.HIGH + value.MEDIUM + value.LOW)),

                type: value.subAssetType,
                url: key,
                assetType: value.assetType,
                subAssetType: value.subAssetType,
                reportId: value.reportId,
                scanDate: value.scanDate,
                scanningRow: false,
                branch: value.branch,
                rowStatus: value.status,
                ecosystem: value.ecosystem,
                source: value.source,
                errorMessage:
                  value.status.toLowerCase() === 'failed' ? value.errorMessage : "",
              });
            });
            dispatch(setScannedRecords(newData));
            dispatch(setNoScannedRecords(false));
          } else {
            dispatch(setNoScannedRecords(true));
          }
        }
      })
      .catch((e) => {
        dispatch(setNoScannedRecords(true));
        setLoadingDashboardData(false);
        console.log("Exception: ", e);
      })
  }

  const handleResetAllFilters = () => {
    setAdvanceSearchInfo({
      package: "",
      version: "",
      cves: ""
    })
    setIsAdvanceSearchApplied(false)
    getDashboardData(true, true)
  }
  return (
    <>
      <section className="mx-3">
        {!_.isEmpty(reduxState) &&
          !_.isEmpty(reduxState.authReducer.userDetails.isFirstTimeLogin) && (
            <section
              className="card custom-card p-2 px-3 text-left mb-2"
              style={{ background: "#1e464d", border: "1px solid #2c6a74" }}
            >
              {/* <FontAwesomeIcon className='mb-3' icon={faGithub} fontSize={60} /> */}
              {/* <h1 className="text-white mb-1">First time setup</h1> */}
              <p className="mb-0">
                <strong> Hi {getUserDisplayName(reduxState.authReducer.userDetails)}! </strong> You have logged in for first
                time. To change the default settings, please{" "}
                <a href={"/user-prefrences"}>click here</a>
              </p>

              {/* <p></p> */}
              {/* <p><button className='btn btn-primary btn-lg'><FontAwesomeIcon icon={faCogs} className='me-2' />Let's configure</button></p> */}
            </section>
          )}
        <div className="d-flex align-items-start justify-content-between mb-2">
          <div className="d-flex">
            <h2 className="text-white">
              <FontAwesomeIcon icon={faLayerGroup} className="me-2" />SCA Dashboard{" "}
              <Tooltip title="Scan a repository">
                <button
                  className="btn btn-warning py-1 ms-3"
                  onClick={handleiScanClick}
                >
                  <TbGridScan className={'me-2'} />
                  iScan
                </button>
              </Tooltip>
              <Tooltip title="Refresh">
                <button
                  className="btn btn-primary py-1 ms-2"
                  onClick={handleDashboardReload}
                >
                  <FontAwesomeIcon icon={faRotateRight} />
                </button>
              </Tooltip>
            </h2>
            {!_.isEmpty(userPrefrences) && userPrefrences["run.traceability"] === "true" && <div className="d-flex align-items-center ps-2 gap-2">
              <Switch onChange={onChangeEnableTracebility} checked={isEnableTraceibility} className='me-2' />
              <Tooltip title="Toggle traceability">
                <p className="mb-0">{!isEnableTraceibility ? "Enable Traceability" : "Disable Traceability"}</p>
              </Tooltip>
            </div>}
          </div>
          {!_.isEmpty(subscribedProductsReducer.subscribedProducts) && !_.isEmpty(subscribedProductsReducer.subscribedProducts.activePlans) &&
            !_.isEmpty(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.SCA))
            && <div><span className="product-plan-text">Plan - <span className={`${getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.SCA).sProductKey) === 'free' ? 'free-plan' : 'non-free-plan'}`}>{capitalizeFirstLetter(getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.SCA).sProductKey))}</span></span></div>
          }
        </div>


        <div className="mb-4">
          <div className="row">
            <div
              className="col-sm-6"
              style={{ borderRight: "1px solid #2b313e" }}
            >
              <div className="d-flex align-items-center justify-content-start h-100">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="px-2 d-flex align-items-center justify-content-start">
                    <div className="total-header">
                      <div className="d-flex flex-column">
                        <p
                          className="head-tag-subtext my-0"
                          style={{
                            color: "#9a9a9a",
                            fontSize: "90%",
                            fontWeight: 500,
                          }}
                        >
                          Total vulnerabilities
                        </p>
                        <h1 className="head-tag text-white">
                          {!_.isEmpty(dashboardData)
                            ? dashboardData.dashboardStats.totalCve
                            : "-"}
                        </h1>
                        {!_.isEmpty(loadingRows) && (
                          <div className="loader-line"></div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                      <div className="py-2 pe-2 text-center">
                        <DashboardSeverityIndicator severity={'Critical'} loadingRows={!_.isEmpty(loadingRows) ? true : false} dashboardData={!_.isEmpty(dashboardData) ? dashboardData.dashboardStats.CRITICAL : "-"} />
                      </div>
                      <div className="py-2 pe-2 text-center">
                        <DashboardSeverityIndicator severity={'High'} loadingRows={!_.isEmpty(loadingRows) ? true : false} dashboardData={!_.isEmpty(dashboardData) ? dashboardData.dashboardStats.HIGH : "-"} />
                      </div>
                      <div className="py-2 pe-2 text-center">
                        <DashboardSeverityIndicator severity={'Medium'} loadingRows={!_.isEmpty(loadingRows) ? true : false} dashboardData={!_.isEmpty(dashboardData) ? dashboardData.dashboardStats.MEDIUM : "-"} />
                      </div>
                      <div className="py-2 pe-2 text-center">
                        <DashboardSeverityIndicator severity={'Low'} loadingRows={!_.isEmpty(loadingRows) ? true : false} dashboardData={!_.isEmpty(dashboardData) ? dashboardData.dashboardStats.LOW : "-"} />
                      </div>

                      {(!_.isEmpty(dashboardData) && (dashboardData.dashboardStats.totalCve - (dashboardData.dashboardStats.LOW + dashboardData.dashboardStats.HIGH + dashboardData.dashboardStats.CRITICAL + dashboardData.dashboardStats.MEDIUM)) > 0)
                        ?
                        <div className="py-2 text-center">
                          <DashboardSeverityIndicator severity={'NA'} loadingRows={!_.isEmpty(loadingRows) ? true : false} dashboardData={!_.isEmpty(dashboardData) ? (dashboardData.dashboardStats.totalCve - (dashboardData.dashboardStats.LOW + dashboardData.dashboardStats.HIGH + dashboardData.dashboardStats.CRITICAL + dashboardData.dashboardStats.MEDIUM)) : "-"} />
                        </div> : ""}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-sm-3"
              style={{ borderRight: "1px solid #2b313e" }}
            >
              <h5 className="text-white text-center">
                Vulnerability Distribution
              </h5>
              <DonutHighChart />
              {/* {!_.isEmpty(reduxState.scannerReducer.allUserRepoList) && <p className="mb-0 text-center" style={{ color: '#adb5bd' }}>
                                Total <b>{!_.isEmpty(reduxState.scannerReducer.allUserRepoList) ? reduxState.scannerReducer.allUserRepoList.length : '0'}</b> GIT repos found - <b>{!_.isEmpty(reduxState.scannerReducer.scannedRecords) ? reduxState.scannerReducer.scannedRecords.length : '0'}</b> scanned
                            </p>} */}
            </div>
            <div className="col-sm-3">
              <Dragger {...draggerProps}>
                <div
                  className={`radar-panel ${reduxState.scannerReducer.scanningFromDragStatus ? "red-scanner" : ""}`}
                >
                  <div className="radar-scanner"></div>
                </div>
                <p className="text-center mt-2 mb-0 text-muted">
                  Drop any file to scan
                </p>
              </Dragger>
            </div>
          </div>
        </div>

        <section>
          {/* <div className="alert alert-primary-custom mb-4" role="alert"> */}
          {/* if less then 5 then all your repositories */}

          {/* </div> */}

          <div className="card custom-card">
            {/* <div className="card-header align-items-center d-flex"> */}
            {/* <h4 className="card-title mb-0 flex-grow-1"></h4> */}
            {/* <a href={'#'}>View all <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></a> */}
            {/* </div> */}

            <div className="card-body py-0">
              {!_.isEmpty(reduxState) &&
                reduxState.authReducer.userDetails.isFirstTimeLogin && (
                  <p className="my-2 ms-3 mb-0">
                    <b style={{ fontSize: 16 }}>{data.length}</b> repositories
                    found, automatic scan triggered for all.{" "}
                    <Tooltip title="Found 17 branches with 44 vulnerabilities">
                      <img src={chip} className="ms-2" style={{ width: 18 }} />
                    </Tooltip>
                  </p>
                )}

              {loadingDashboardData ? (
                <div
                  className={"card custom-card p-2 px-3 text-left mb-2 mt-3"}
                  style={{
                    background: "#1e464d",
                    border: "1px solid #2c6a74",
                    width: "100%",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-start">
                    <Loader />{" "}
                    <span className="ms-2">
                      Loading previously triggered scans...
                    </span>
                  </div>
                </div>
              ) : (
                <RepoListScannedDataTable getDashboardData={getDashboardData} advanceSearchInfo={advanceSearchInfo} handleSearchChange={handleSearchChange} handleSearchSubmit={handleSearchSubmit} handleClearFilters={handleClearFilters} isAdvanceSearchApplied={isAdvanceSearchApplied} handleResetAllFilters={handleResetAllFilters}/>
              )}

              {!_.isEmpty(dashboardData) &&
                !_.isEmpty(dashboardData.top3AssetsByCve) && (
                  <>
                    <hr />

                    <div className="row mt-10 d-flex align-items-start justify-content-center">
                      <div className="col-sm-6 custom-border-right">
                        <div className="">
                          <div className="mb-0" style={{ minHeight: 270 }}>
                            <div className="card-header align-items-center d-flex border-bottom-0">
                              <h4 className="card-title flex-grow-1 mb-0">
                                Risk zones
                              </h4>
                              {/* <a href={"#"}>
                                View all{" "} */}
                              {/*<FontAwesomeIcon className="ms-2" icon={faArrowRight} />*/}
                              {/* </a> */}
                            </div>

                            <div className="card-body pb-0">
                              <div className="text-white">
                                <div className="row d-flex align-items-center justify-content-start ">
                                  {!_.isEmpty(dashboardData) &&
                                    !_.isEmpty(dashboardData.top3AssetsByCve) &&
                                    Object.entries(
                                      dashboardData.top3AssetsByCve
                                    ).map(([key, value], index) => {
                                      // console.log(key, value, index);
                                      return (
                                        <div className="col-sm-4" key={key}>
                                          <div className="p-3">
                                            <Tooltip title={key}>
                                              <div className="d-flex align-items-start justify-content-start flex-column">
                                                <h6
                                                  style={{
                                                    fontWeight: 400,
                                                    fontSize: 12,
                                                    minHeight: 35
                                                  }}
                                                  className="text-white mb-2"
                                                >
                                                  <i>{key.split("/").pop()}</i>
                                                </h6>
                                                <div className="d-flex align-items-center">
                                                  {/* <h2 className="text-white" style={{ fontWeight: 200, fontSize: 55, lineHeight: 1 }}>120</h2> */}
                                                  <Progress
                                                    // className="progress-bar-animated"
                                                    type="dashboard"
                                                    percent={
                                                      (value * 100) /
                                                      dashboardData
                                                        .dashboardStats.totalCve
                                                    }
                                                    strokeColor={
                                                      halfCircleColor[index]
                                                    }
                                                    trailColor="#2b313e"
                                                    strokeWidth={12}
                                                    size={125}
                                                    format={(percent) => (
                                                      <span className="text-white">
                                                        {parseInt(value)}
                                                        <br />{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color:
                                                              "rgb(154, 154, 154)",
                                                          }}
                                                        >
                                                          Vulnerabilities
                                                        </span>
                                                      </span>
                                                    )}
                                                  />
                                                </div>
                                                {/* <div
                                                  style={{ color: "#616161" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(217 74 71)",
                                                    }}
                                                    className="me-2"
                                                  >
                                                    <FontAwesomeIcon
                                                      className="me-1"
                                                      icon={faArrowUp}
                                                      style={{
                                                        transform:
                                                          "rotate(45deg)",
                                                      }}
                                                    />
                                                    35
                                                  </span>
                                                  since last scan
                                                </div> */}
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="">
                          <div className="mb-0" style={{ minHeight: 270 }}>
                            <div className="card-header align-items-center d-flex border-bottom-0">
                              <h4 className="card-title flex-grow-1 mb-0">
                                Top 3 components by vulnerabilities
                              </h4>
                              {/* <a href={"#"}>
                                View all{" "} */}
                              {/*<FontAwesomeIcon className="ms-2" icon={faArrowRight} />*/}
                              {/* </a> */}
                            </div>

                            <div className="card-body">
                              <div style={{ width: "100%", height: 200 }}>
                                <StackedGraph
                                  data={dashboardData.top3ComponentsByCve}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="mt-10">
                      <div className="row">
                        <div className="col-sm-6 custom-border-right-second">
                          <div className="">
                            <div className="card-header align-items-center d-flex border-bottom-0">
                              <h4 className="card-title mb-0 flex-grow-1">
                                Top 3 critical vulnerabilities
                              </h4>
                              {/* <a href={"#"}>
                                View all{" "} */}
                              {/*<FontAwesomeIcon className="ms-2" icon={faArrowRight} />*/}
                              {/* </a> */}
                            </div>

                            <div className="card-body">
                              <div className="text-white">
                                <div className="row">
                                  {/* {console.log("Dashboard Data:", dashboardData)} */}
                                  {!_.isEmpty(dashboardData) &&
                                    !_.isEmpty(dashboardData.top3CriticalCve) &&
                                    Object.entries(
                                      dashboardData.top3CriticalCve
                                    ).map(([key, value], index) => {
                                      return (
                                        <div
                                          key={key}
                                          className="col-sm-4"
                                          style={{
                                            borderRight:
                                              index < 2
                                                ? "1px solid #2b313e"
                                                : "none",
                                          }}
                                        >
                                          <div
                                            className="card p-2 py-0 border-0"
                                            style={{
                                              borderRadius: 8,
                                              background: "transparent",
                                            }}
                                          >
                                            <div className="row">
                                              <div className="col-sm-10">
                                                <Tooltip title={getPackageDetails(key, 'nothing')}>
                                                  <div className="mb-0 text-truncate" style={{ maxWidth: '90%' }}>
                                                    <small className="text-truncate">
                                                      {getPackageDetails(key, 'packageName')}
                                                    </small>
                                                  </div>
                                                </Tooltip>
                                                <div
                                                  className="mt-3 d-flex align-items-center mb-2"
                                                  style={{ maxWidth: 150 }}
                                                >
                                                  {/* <h3 className="text-white">Out-of-bounds Read</h3> */}
                                                  <img
                                                    src={ddos}
                                                    style={{ height: 45 }}
                                                  />
                                                </div>
                                                <div className="mb-0 text-truncate" style={{ maxWidth: '90%' }}>
                                                  {/* <a href={`https://galaxyguard.sec1.io/cve/${value.cveId}/?s=${value.cveId}`} target="_blank"><small>{(!_.isEmpty(value) && !_.isEmpty(value.cveTypes) && !_.isEmpty(value.cveTypes[0]) && !_.isEmpty(value.cveTypes[0].cveType)) ? value.cveTypes[0].cveType : value.cveId}</small></a> */}
                                                  <Tooltip title={getDataVal(index, value)}>
                                                    <a
                                                      href={`https://galaxyguard.sec1.io/cve/${value.cveId}/?s=${value.cveId}`}
                                                      target="_blank"
                                                    >
                                                      <small>
                                                        {getDataVal(index, value)}
                                                      </small>
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                              <div className="highlight-area p-2 d-flex align-items-center justify-content-end col-sm-2">
                                                <p
                                                  className="mb-0 text-center"
                                                  style={{ lineHeight: 1.3 }}
                                                >
                                                  {value.cveScore} <br />{" "}
                                                  Critical
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          (!_.isEmpty(dashboardData) && !_.isEmpty(dashboardData.aiAssistedRemediation)) &&
                          <div className="col-sm-6">
                            <div className="">
                              <div className="card-header align-items-center d-flex border-bottom-0">
                                <h4 className="card-title mb-0 flex-grow-1">
                                  AI assisted remediation{" "}
                                  {/* {console.log("Dashboard Data:", dashboardData)} */}
                                  <img
                                    src={chip}
                                    className="ms-2"
                                    style={{ width: 18 }}
                                  />
                                </h4>
                                {/* <a href={'#'}>View all <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></a> */}
                              </div>

                              <div className="card-body">
                                <div className="text-white">
                                  <table className="table table-dark table-hover table-sm custom-table modified-bootstrap-table">
                                    <thead>
                                      <tr>
                                        <th>Vulnerable Component</th>
                                        <th>Recommended fix</th>
                                        <th>Will fix</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        (!_.isEmpty(dashboardData) && !_.isEmpty(dashboardData.aiAssistedRemediation)) &&

                                        dashboardData.aiAssistedRemediation.map((val, index) => <tr key={`dashboard-ai-recomendation-${index}`}>
                                          <td>{val.packageName}@{val.currentVersion}</td>
                                          <td>{val.recommendedVersion}</td>
                                          <td>{val.vulnerabilityFixCount}</td>
                                        </tr>)

                                      }
                                    </tbody>

                                  </table>
                                  <ul className="list-unstyled">
                                    {/* {
                                      (!_.isEmpty(dashboardData) && !_.isEmpty(dashboardData.aiAssistedRemediation)) &&

                                      dashboardData.aiAssistedRemediation.map((val, index) => <li key={`aiRemediate-${index}`}>{val}</li>)
                                    } */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>}
                      </div>
                    </div>

                    <hr />

                    {!_.isEmpty(searchResults) && (
                      <div className="">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            vTrace{" "}
                            <FontAwesomeIcon
                              className="ms-1"
                              style={{ fontSize: 15 }}
                              icon={faRegistered}
                            />
                          </h4>
                          {/* <a href={'#'}>View all <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></a> */}
                        </div>

                        <div className="card-body">
                          <div className="text-white">
                            <div className="d-flex align-items-center justify-content-start">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="outline-secondary"
                                  disabled={loadingTree}
                                  className="text-white"
                                  size="sm"
                                >
                                  {/* {console.log(searchResults)} */}
                                  {/* {console.log(searchResults.assetUrl)} */}
                                  <FontAwesomeIcon
                                    className="me-2"
                                    icon={faFilter}
                                  />{" "}
                                  {getName(searchResults.assetUrl)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{
                                    maxHeight: 200,
                                    overflow: "hidden",
                                    overflowY: "auto",
                                  }}
                                >
                                  {/* <ul className="list-group m-0" style={{ maxHeight: 200, overflow: 'hidden', overflowY: 'auto' }}> */}
                                  {!_.isEmpty(
                                    reduxState.scannerReducer.scannedRecords
                                  ) &&
                                    reduxState.scannerReducer.scannedRecords.map(
                                      (el, index) => {
                                        if (
                                          el.rowStatus.toLowerCase() === 'completed' &&
                                          el.total > 0
                                        ) {
                                          return (
                                            // <li key={`select-dropdown-${index}`} className="list-group-item p-2 legend-linkGraph border-0">
                                            // <a onClick={(e) => { e.preventDefault(); handleVTraceClick(el.reportId); }} className="dropdown-item" href="#">{el.name}</a>
                                            // </li>
                                            <Dropdown.Item
                                              key={`select-dropdown-${index}`}
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleVTraceClick(el.reportId);
                                              }}
                                            >
                                              {el.name}
                                            </Dropdown.Item>
                                          );
                                        }
                                      }
                                    )}

                                  {/* </ul> */}
                                </Dropdown.Menu>
                              </Dropdown>{" "}
                              {loadingTree ? (
                                <div className="ms-3 d-flex align-items-center justify-content-start">
                                  <Loader />{" "}
                                  <span className="ms-2">
                                    Loading vTrace...
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <TidyTreeView dataSet={searchResults} />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
