import { Empty, Table } from "antd";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import moment from "moment";
import timeZoneList from './timeZone.json';
import _ from "lodash";

export default function ScheduledScans({ data }) {

    const findZoneByGMT = (timeZoneList, targetGMT) => {
        return _.get(_.find(timeZoneList, { gmt: targetGMT }), 'zone', null);
    };

    const columns = [
        {
            title: 'Asset URL',
            dataIndex: 'assetUrl',
            key: 'assetUrl',
            ellipsis: true,
            width: '50%',
        },
        {
            title: 'Next Scan Time',
            dataIndex: 'nextScanTime',
            key: 'nextScanTime',
            width: '50%',
            // render: (text) => moment.utc(text).local().format("ddd, MMM D YYYY, hh:mm a zz")
            render: (text) => {
                const localTimeFormat = moment(text).format("ddd, MMM D YYYY, hh:mm a Z")
                const timeOffset = localTimeFormat.split(" ").pop()
                return `${moment(text).format("ddd, MMM D YYYY, hh:mm a")} ${findZoneByGMT(timeZoneList, `(GMT${timeOffset})`)}`
            }
        },
    ];

    // If data is null or empty, show Empty component
    if (_.isEmpty(data)) {
        return data.length === 0 ? <Empty description="No scheduled scans" /> : null;
    }

    // Add key to each data item if not present
    const dataSource = data.map((item, index) => ({
        key: item.key || `${index}`,
        ...item,
    }));

    return (
        <Table
            size='small'
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            className={"table custom-table mb-0"}
            locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
        />
    );
}