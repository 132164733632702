import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import _ from "lodash";
import { useSelector } from 'react-redux';
import { Button, Alert, Avatar } from 'antd';
import { useComments } from './useComments';
import Loader from '../../../shared/sharedComponents/loader';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';

export default function Comments({
    reportId,
    selectedVulDetails,
    showCommentsView,
    handleCommentsClose,
}) {
    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState([]);
    const reduxState = useSelector(state => state);
    const { loading, data: commentsData, fetchComments, addComment, } = useComments();

    useEffect(() => {
        fetchComments(reportId, selectedVulDetails.id);
    }, [reportId, selectedVulDetails]);

    useEffect(() => {
        if (!_.isEmpty(commentsData)) {

            let newData = [];
            _.forEach(commentsData, function (comment) {
                newData.push({
                    postedAt: moment(comment.addedDateTime),
                    comment: comment.description,
                    postedByEmail: comment.addedBy,
                })
            })
            newData = _.orderBy(newData, ['postedAt'], ["desc"]);
            setComments(newData);
        }
    }, [commentsData])

    const handleAddCommentClick = (e) => {
        e.preventDefault();
        if (!_.isEmpty(newComment)) {
            addComment(reportId, selectedVulDetails.id, newComment);
            setNewComment("");
        }
    }

    return (<>
        <Modal show={showCommentsView} onHide={handleCommentsClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as={'h5'}>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <form onSubmit={handleAddCommentClick}>
                        <input type="text" className="form-control mb-2 px-3 py-4" id="commentTxt" aria-describedby="New Comment" placeholder="Enter New Comment"
                            value={newComment}
                            onChange={(e) => { setNewComment(e.target.value) }}
                        // onKeyDown={(e) => {
                        //     console.log(e.key)
                        //     if (e.key === "Enter") {
                        //         handleAddCommentClick(e)
                        //     }
                        // }}
                        />
                        <div className='d-flex justify-content-start'>
                            <div className='mt-2'>
                                <Button type="default" htmlType={'submit'} className="btn btn-primary-gradient w-100">
                                    Save
                                </Button>
                            </div>

                        </div>
                    </form>
                    <div className='mt-4'>
                        {!_.isEmpty(comments) ? <ul className='d-flex flex-column gap-2 px-0 scroll--simple' style={{ listStyle: "none", maxHeight: 240, overflowY: "auto" }}>
                            {comments.map(({ comment, postedAt, postedByEmail }) => (<li>
                                <div className='d-flex gap-2 border-bottom'>
                                    <Avatar size={"default"} ><span className='text-white'>{_.upperCase(_.head(postedByEmail))}</span></Avatar>
                                    <div>
                                        <p className='mb-2'><span style={{ fontWeight: 800 }}>{`${postedByEmail}`}</span> <span className='ms-2' style={{ opacity: 0.75 }}>{`${moment(postedAt).local().fromNow()} `}</span></p>
                                        <div className=''>
                                            <p className='mb-2'>{comment}</p>
                                        </div>
                                    </div>
                                </div>


                            </li>))}
                        </ul> : <div>No comments</div>}
                    </div>
                </div>
            </Modal.Body>
        </Modal >
        {loading && <Loader />}
    </>)
}