import axios from "axios";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { Table, Tag, Space, Menu, Dropdown, Tooltip, Switch } from "antd";
import { getFontColor, getStrokeColor, extractDomain } from '../../shared/helper/genHelper';
import Loader from "../../shared/sharedComponents/loader";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { LuHardDriveDownload } from "react-icons/lu";
import { MdModeEditOutline } from "react-icons/md";
import Comments from "./comments";
import ViewDetailsModal from "./viewDetailsModal";

export default function VulnerabilityView({ reportDetails, reportId }) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCommentsView, setShowCommentsView] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedVulDetails, setSelectedVulDetails] = useState({});
    const [showAllVul, setShowAllVul] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(reportDetails) && !_.isEmpty(reportDetails.scanReport) && !_.isEmpty(reportDetails.scanReport.alerts)) {
            let newData = [];
            _.forEach(reportDetails.scanReport.alerts, (el, key) => {
                newData.push({
                    ...el,
                    key: key.toString(),
                })
            });
            setData(newData);
        }
    }, [reportDetails])

    const handleReportDownloadClick = async (type) => {
        try {
            setLoading(true);
            const response = await axios.get(`${GlobalConst.API_URL}/auth/pentest/download-report?reportId=${reportId}&type=${type}`, {
                responseType: 'blob'
            });
            setLoading(false);
            if (!_.isEmpty(response) && response.data) {
                const blob = new Blob([response.data], {
                    type: response.headers['content-type'],
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `${reportId}-${type}.${(response.headers['content-type'] === 'application/json' ? "json" : "pdf")}`; // Set your desired filename here
                link.click();
                // Clean up by revoking the blob URL
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }
    }

    const handleDownloadMenuClick = ({ key }) => {
        handleReportDownloadClick(key)
    };

    const handleCommentsShowClose = () => {
        setShowCommentsView(!showCommentsView)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleViewComment = (vulnerabilityData) => {
        setSelectedVulDetails(vulnerabilityData)
        handleCommentsShowClose();
    }

    const handleCreateTicket = (e, reportId, id) => {
        e.preventDefault();
        setLoading(true);
        let postData = {
            reportId: reportId,
            id: id
        }
        axios.post(`${GlobalConst.API_URL}/auth/pentest/create-ticket`, postData).then((op) => {
            setLoading(false);
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                const dataArray = data.map((value) => {
                    if (value.id === id) {
                        return {
                            ...value,
                            ticketingInfo: {
                                ...value.ticketingInfo,
                                "number": op.data[0].ticketNumber,
                                "status": op.data[0].status,
                                "url": op.data[0].ticketUrl
                            }
                        }
                    } else {
                        return value;
                    }
                })
                setData(dataArray);
            }
        }).catch((error) => {
            setLoading(false);
            console.log("error", error);
        })
    }

    const onChangeShowAll = () => {
        setShowAllVul(!showAllVul);
    }

    const updatedData = !showAllVul ? data.filter((vulnerability) => vulnerability.status.toLowerCase() === "open") : data 
    const columns = [
        {
            title: <><span className='me-3'>Vulnerability</span></>,
            dataIndex: 'title',
            key: 'title',
            width: 200,
        },
        {
            title: "Description",
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            width: 230
        },
        {
            title: "Cwe Id",
            dataIndex: 'cweid',
            key: 'cweid',
        },

        {
            title: 'Severity',
            dataIndex: 'risk',
            key: 'severity',
            align: 'center',
            render: (val, row) => {
                switch (val.toLowerCase()) {
                    case 'critical':
                        return (<Tag
                            style={{
                                color: getFontColor("CRITICAL"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("CRITICAL")}
                            className={"mx-auto text-center"}
                        >CRITICAL</Tag>);
                    case 'high':
                        return (<Tag
                            style={{
                                color: getFontColor("HIGH"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("HIGH")}
                            className={"mx-auto text-center"}
                        >
                            HIGH
                        </Tag>);
                    case 'medium':
                        return (<Tag
                            style={{
                                color: getFontColor("MEDIUM"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("MEDIUM")}
                            className={"mx-auto text-center"}
                        >
                            MEDIUM
                        </Tag>);
                    case 'low':
                        return (<Tag
                            style={{
                                color: getFontColor("LOW"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("LOW")}
                            className={"mx-auto text-center"}
                        >
                            LOW
                        </Tag>);
                    default:
                        return (<Tag
                            style={{
                                color: getFontColor("NA"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("NA")}
                            className={"mx-auto text-center"}
                        >
                            NA
                        </Tag>);
                }
            }
        },
        {
            title: 'Status',
            dataIndex: "status",
            key: 'status',
            align: 'center',
            render: (text) => {
                if (!_.isEmpty(text)) {
                    switch (text.toLowerCase()) {
                        case "open":
                            return (<Tag
                                style={{
                                    color: '#fff',
                                    fontWeight: 600,
                                    fontSize: 12,
                                }}
                                color="#1E90FF"
                                className={"mx-auto text-center"}
                            >
                                {text.toUpperCase()}
                            </Tag>);
                        case "closed":
                            return (<Tag
                                style={{
                                    color: '#fff',
                                    fontWeight: 600,
                                    fontSize: 12,
                                }}
                                color="rgb(16, 185, 129)"
                                className={"mx-auto text-center"}
                            >
                                {text.toUpperCase()}
                            </Tag>);
                        default:
                            return "-";
                    }
                }
                return "-";
            }
        },
        {
            title: "Ticket Number",
            key: 'ticketNumber',
            align: 'center',
            onCell: (record) => ({
                colSpan: !_.isEmpty(record) && !_.isEmpty(record.ticketingInfo) && !_.isEmpty(record.ticketingInfo.number) ? 1 : 3,
            }),
            render: (text, record, index) => {
                if (!_.isEmpty(record) && !_.isEmpty(record.ticketingInfo) && !_.isEmpty(record.ticketingInfo.number)) {
                    return <a href={record.ticketingInfo.url} target="_blank" className="me-3 details-hover-animate" style={{ fontSize: 14 }}>{record.ticketingInfo.number}</a>
                } else {
                    return <a href="#" target="_blank" className="me-3 details-hover-animate" style={{ fontSize: 14 }} onClick={(e) => handleCreateTicket(e, reportId, record.id)}>Create Ticket</a>
                }
            }
        },
        {
            title: 'Ticket Status',
            key: 'ticketStatus',
            align: 'center',
            onCell: (record) => {
                return {
                    colSpan: !_.isEmpty(record) && !_.isEmpty(record.ticketingInfo) && !_.isEmpty(record.ticketingInfo.number) ? 1 : 0,
                }
            },
            render: (text, record) => {
                if (!_.isEmpty(record) && !_.isEmpty(record.ticketingInfo)) {
                    switch (record.ticketingInfo.status.toLowerCase()) {
                        case "open":
                            return (<span>TODO</span>);
                        case "in-progress":
                            return (<span
                            >
                                IN PROGRESS
                            </span>);
                        case "completed":
                            return (<span
                            >
                                COMPLETED
                            </span>);
                        default:
                            return "-";
                    }
                }
                return "-";
            }
        },
        {
            title: <><span className='me-3'>Assigned To</span></>,
            key: 'assignedTo',
            align: "center",
            onCell: (record) => ({
                colSpan: !_.isEmpty(record) && !_.isEmpty(record.ticketingInfo) && !_.isEmpty(record.ticketingInfo.number) ? 1 : 0,
            }),
            render: (text, record) => <div>{
                !_.isEmpty(record) && !_.isEmpty(record.ticketingInfo) && !_.isEmpty(record.ticketingInfo.assignedTo)
                    ? record.ticketingInfo.assignedTo
                    : "-"}</div>
        },
        {
            title: "Action",
            key: 'action',
            fixed: "right",
            width: 160,
            align: "center",
            render: (_, row) => <Space>
                <a href='#'
                    className="me-2"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalVisible(true);
                        setModalData(row);
                    }}
                >View Detail</a>
                <a onClick={() => handleViewComment(row)} href={`#`} className={`me-2 details-hover-animate`}>
                    <Tooltip title="Add comment">
                        <MdModeEditOutline style={{ fontSize: 20 }} />
                    </Tooltip>
                </a>
            </Space>,
        }
    ];

    if (loading) {
        return (<Loader />)
    }
    else {

        return (
            <section className="my-2">
                <span style={{ position: 'absolute', top: 0, right: 0 }}>
                    <Dropdown
                        overlay={<Menu selectable
                            onClick={handleDownloadMenuClick}
                            items={[{
                                key: 'executive',
                                label: 'Executive Summary',
                            },
                            {
                                type: 'divider',
                            },
                            {
                                key: 'detailed',
                                label: 'Detailed Report',
                            },
                            {
                                type: 'divider',
                            }, {
                                key: 'json',
                                label: 'JSON',
                            }
                            ]}
                            className={'scroll-style download-report-dropdown'} />}
                        trigger={['click']}>
                        <button className="btn btn-sm custom-button d-flex align-items-center justify-content-center"
                        ><LuHardDriveDownload className="me-2" />Download Report</button>
                    </Dropdown>
                </span>
                <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2'>
                    <Switch onChange={onChangeShowAll} checked={showAllVul} className='me-2' />Show All
                </div>
                <div>
                    <Table
                        className='custom-table'
                        columns={columns}
                        dataSource={updatedData}
                        size="small"
                        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            position: ["bottom", "left"],
                            className: "searchPagePagination px-0",
                            pageSizeOptions: [10, 20, 25],
                        }}
                    />
                </div>
                {showCommentsView && !_.isEmpty(selectedVulDetails) && <Comments
                    reportId={reportId}
                    selectedVulDetails={selectedVulDetails}
                    showCommentsView={showCommentsView}
                    handleCommentsClose={handleCommentsShowClose} />}
                {isModalVisible && <ViewDetailsModal
                    visible={isModalVisible}
                    onClose={handleCloseModal}
                    modalData={modalData}
                    reportId={reportId}
                />}
            </section>
        )
    }

}