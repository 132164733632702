
import { Tag, Space, Table } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
// import NoDataFound from './noDataFound';
import { ViewDetailsModal } from '../../shared/sharedComponents/dastSharedComponents/viewDetailsModalHelper';
import Loader from '../../shared/sharedComponents/loader';
import axios from 'axios';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import NoDataFound from '../../shared/sharedComponents/noDataFound';

export default function URLDiscoveryView({ reportDetails, userPrefrences, reportId }) {

    const [data, setData] = useState([]);

    const [selectedData, setSelectedData] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [modalData, setModalData] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(reportId)) {
            getURLDiscoveryData(reportId)
        }
    }, [reportId])

    const getURLDiscoveryData = (reportId) => {
        const postBody = {
            "reportId": reportId
        };
        setShowLoader(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/pentest/asset/report/fetch/discovered-url`, postBody)
            .then(op => {
                setShowLoader(false);

                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    let newData = [];
                    _.forEach(op.data.urls, function (el) {
                        newData.push({
                            url: el
                        })
                    })
                    setData(newData);
                } else {
                    setData({})
                }
            })
            .catch(e => { setShowLoader(false); setData(null) })
    }

    const columns = [
        {
            title: "Sno",
            key: "sno",
            dataIndex: "sno",
            width: 20,
        },
        {
            title: <><span className='me-3'>Path</span> </>,
            dataIndex: 'url',
            key: 'url',
        }
    ];

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedData(null);
    };

    if (_.isEmpty(data)) {
        return (<>
        {showLoader && <Loader />}
        <NoDataFound title={"No Data Available"} desc={'There are currently no urls to display. Please initiate a scan.'} />
        </>);
    }
    else {
        const updatedData = data.map((record,index) => ({
            ...record,
            sno: index + 1
        }));
        return (
            <section className="my-2">
                <div>
                    <div className='d-flex align-items-center justify-content-start mb-0'></div>
                    <Table
                        className='custom-table'
                        columns={columns}
                        dataSource={updatedData}
                        size="small"
                        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            position: ["bottom", "left"],
                            className: "searchPagePagination px-0",
                            pageSizeOptions: [10, 20, 25],
                        }}
                    />
                </div>
                {showLoader && <Loader />}
            </section>

        )
    }
}