import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import _ from "lodash";

export default function AdvancedSearch({ advanceSearchInfo, handleSearchChange, handleSearchSubmit, handleClearFilters }) {

    const isSearchEmpty = () => _.isEmpty(advanceSearchInfo.cves) && _.isEmpty(advanceSearchInfo.package) && _.isEmpty(advanceSearchInfo.version)

    return (<form onSubmit={(e) => {
        e.preventDefault();
        handleSearchSubmit();
        }}><div style={{ width: 800 }}>

        <div className="d-flex align-items-center gap-2 mb-3">
            <div className="flex-grow-1">
                <label className="form-label mb-1 fw-semibold">Package</label>
                <input type="text" value={advanceSearchInfo.package} placeholder="Enter package (e.g. spring)" className="form-control" onChange={(e) => handleSearchChange("package", e.target.value)} />
            </div>
            {/* <FontAwesomeIcon icon={faPlus} className="mt-4"/> */}
            <div className="flex-grow-1">
                <label className="form-label mb-1 fw-semibold">Version<small>(comma delimitted)</small></label>
                <input type="text" value={advanceSearchInfo.version} placeholder="Enter version (e.g. 1.1.0)" className="form-control" onChange={(e) => handleSearchChange("version", e.target.value)} />
            </div>
        </div>
        <div>
            <label className="form-label mb-1 fw-semibold">CVE number<small>(comma delimitted)</small></label>
            <input type="text" value={advanceSearchInfo.cves} placeholder="Enter CVE number (e.g. CVE-2024-1553)" className="form-control" onChange={(e) => handleSearchChange("cves", e.target.value)} />
        </div>
        <div className="d-flex justify-content-end align-items-center mt-3 gap-3">
            <Button size="small" onClick={handleClearFilters} disabled={isSearchEmpty()}>Clear Filters</Button>
            <Button size={"small"} type="primary" htmlType={'submit'} disabled={isSearchEmpty()}>Show Results</Button>
        </div>
    </div>
    </form>)
}