import { useState } from "react";
import { GlobalConst } from "../../../shared/appConfig/globalConst"
import axios from "axios";
import _ from "lodash";

export const getComments = (pentestReportId, scanVulId) => {
    const postData = {
        reportId: pentestReportId,
        id: scanVulId
    };
    return axios.post(`${GlobalConst.API_URL}/auth/pentest/asset/alert/fetch/comments`, postData)
}

export const createComment = (reportId, id, description) => {
    const postData = {
        reportId: reportId,
        id: id,
        description: description
    };
    return axios.post(`${GlobalConst.API_URL}/auth/pentest/asset/alert/add/comment`, postData)
}

export function useComments() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const fetchComments = async (pentestReportId, scanVulId) => {
        setLoading(true);
        try {
            const response = await getComments(pentestReportId, scanVulId);
            if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                setData(response.data.comments);
            } else {
                setData([]);
            }
        } catch (error) {
            setData(null);
        } finally {
            setLoading(false);
        }
    }

    const addComment = async (reportId, scanVulId, description) => {
        setLoading(true);
        try {
            const response = await createComment(reportId,scanVulId, description);
            if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                fetchComments(reportId, scanVulId);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    return { loading, data, fetchComments, addComment }
}