
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import UnifiedSummaryTable from "./unifiedSummaryTable";
import DashboardSeverityIndicator from "../../../shared/sharedComponents/dashboardSeverityIndicator";
import ProductWiseStats from "./productwiseStats";

export default function UnifiedReportSummary({ }) {
    const [unifiedSummaryData, setUnifiedSummaryData] = useState([]);
    const history = useHistory();
    const unifiedReducerState = useSelector(state => state.unifiedReducer)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const isCritical = queryParams.get("iscritical");

    useEffect(() => {
        if (!_.isEmpty(unifiedReducerState.summaryReports)) {
            let newData = []
            for (const [productKey, data] of Object.entries(unifiedReducerState.summaryReports)) {
                if (productKey === "sca") {
                    Object.entries(data).forEach(([reportUrl, value], index) => {
                        let name = "";
                        if (isValidHttpUrl(reportUrl)) {
                            var pathname = new URL(reportUrl).pathname;
                            pathname = pathname.substring(1);
                            name = pathname;
                        } else {
                            name = reportUrl;
                        }
                        newData.push({
                            productKey,
                            name: decodeURIComponent(name),
                            total: value.totalCve,
                            critical: value.CRITICAL,
                            high: value.HIGH,
                            medium: value.MEDIUM,
                            low: value.LOW,
                            na: (value.totalCve - (value.CRITICAL + value.HIGH + value.MEDIUM + value.LOW)),
                            reportId: value.reportId,
                            type: value.subAssetType,
                            url: reportUrl,
                            assetType: value.assetType,
                            subAssetType: value.subAssetType,
                            reportId: value.reportId,
                            scanDate: value.scanDate,
                            scanningRow: false,
                            branch: value.branch,
                            ecosystem: value.ecosystem,
                            source: value.source,
                            errorMessage:
                                value.status.toLowerCase() === 'failed' ? value.errorMessage : "",
                        })
                    })
                } else if (productKey === "sast") {
                    for (const foundRec of data) {
                        newData.push({
                            productKey,
                            ...foundRec,
                            name: foundRec.assetName,
                            total: foundRec.total,
                            critical: foundRec.critical,
                            high: foundRec.high,
                            medium: foundRec.medium,
                            low: foundRec.low,
                            na: "",
                            reportId: foundRec.reportId,
                        })
                    }
                } else if (productKey === "dast") {
                    for (const foundRec of data) {
                        newData.push({
                            ...foundRec,
                            productKey,
                            name: foundRec.assetUrl,
                            total: foundRec.total,
                            critical: foundRec.critical,
                            high: foundRec.high,
                            medium: foundRec.medium,
                            low: foundRec.low,
                            na: "",
                            reportId: foundRec.reportId,
                        })
                    }
                } else if (productKey === "cloud") {
                    for (const foundRec of data) {
                        newData.push({
                            ...foundRec,
                            productKey,
                            name: foundRec.projectId,
                            total: foundRec.critical + foundRec.high + foundRec.medium + foundRec.low,
                            critical: foundRec.critical,
                            high: foundRec.high,
                            medium: foundRec.medium,
                            low: foundRec.low,
                            na: "",
                            reportId: foundRec.reportId,
                        })
                    }
                } else if (productKey === "container") {
                    Object.entries(data).forEach(([reportName, value], ind) => {
                        let name = "";
                        if (isValidHttpUrl(reportName)) {
                            var pathname = new URL(reportName).pathname;
                            pathname = pathname.substring(1);
                            name = pathname;
                        } else {
                            name = reportName;
                        }

                        newData.push({
                            productKey,
                            name: reportName,
                            serialNumber: ind + 1,
                            name: decodeURIComponent(name),
                            owner: "",
                            org: "",
                            total: value.totalCve,
                            critical: value.CRITICAL,
                            high: value.HIGH,
                            medium: value.MEDIUM,
                            low: value.LOW,
                            na:
                                value.totalCve -
                                (value.CRITICAL + value.HIGH + value.MEDIUM + value.LOW),
                            type: value.subAssetType,
                            url: reportName,
                            assetType: value.assetType,
                            subAssetType: value.subAssetType,
                            reportId: value.reportId,
                            scanDate: value.scanDate,
                            scanningRow: false,
                            branch: value.branch,
                            ecosystem: value.ecosystem,
                            source: value.source,
                            errorMessage:
                                value.status.toLowerCase() === "failed"
                                    ? value.errorMessage
                                    : "",
                            registryType: value.registryType,
                        });
                    })
                } else if (productKey === "kubernetes") {
                    for (const foundRec of data) {
                        newData.push({
                            ...foundRec,
                            productKey,
                            name: foundRec.clusterName,
                            total: foundRec.totalCve,
                            critical: foundRec.criticalCve,
                            high: foundRec.highCve,
                            medium: foundRec.mediumCve,
                            low: foundRec.lowCve,
                            na: foundRec.naCve,
                            reportId: foundRec.reportId,
                        })
                    }
                }
            }
            const sortedData = _.isEmpty(isCritical)
                ? [...newData].sort((a, b) => b.total - a.total)
                : [...newData].filter(({ critical }) => critical > 0).sort((a, b) => b.critical - a.critical);
            setUnifiedSummaryData(sortedData)
        }
    }, [unifiedReducerState.summaryReports])

    const goBack = () => {
        history.goBack()
    }

    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const stats = !_.isEmpty(unifiedSummaryData) ?
        unifiedSummaryData.reduce((acc, { total, critical, high, medium, low }) => {
            acc.total += total ? total : 0;
            acc.critical += critical ? critical : 0;
            acc.high += high ? high : 0;
            acc.medium += medium ? medium : 0;
            acc.low += low ? low : 0;
            return acc
        }, {
            total: 0, critical: 0, high: 0, medium: 0, low: 0
        }) : {
            total: 0, critical: 0, high: 0, medium: 0, low: 0
        };

    return (<section className="mx-3">
        <section className="mb-3 d-flex align-items-center justify-content-start">
            <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                <a className='me-2' href='#' style={{ fontSize: 22 }}><small onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></small></a><span className='ps-2' style={{ borderLeft: '1px solid rgba(108, 117, 125, 15%' }}>Unified Report Summary</span>
            </h2>
        </section>
        <div className="mb-4">
            <div className="row">
                <div
                    className="col-sm-6"
                    style={{ borderRight: "1px solid #2b313e" }}
                >
                    <div className="d-flex align-items-center justify-content-start h-100">
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="px-2 d-flex align-items-center justify-content-start">
                                <div className="total-header">
                                    <div className="d-flex flex-column">
                                        <p
                                            className="head-tag-subtext my-0"
                                            style={{
                                                color: "#9a9a9a",
                                                fontSize: "90%",
                                                fontWeight: 500,
                                            }}
                                        >
                                            Total vulnerabilities
                                        </p>
                                        <h1 className="head-tag text-white">
                                            {!_.isEmpty(stats)
                                                ? stats.total
                                                : "-"}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="px-2">
                                <div className="d-flex flex-wrap align-items-center justify-content-center">
                                    <div className="py-2 pe-2 text-center">
                                        <DashboardSeverityIndicator severity={'Critical'} loadingRows={false} dashboardData={!_.isEmpty(stats) ? stats.critical : "-"} />
                                    </div>
                                    <div className="py-2 pe-2 text-center">
                                        <DashboardSeverityIndicator severity={'High'} loadingRows={false} dashboardData={!_.isEmpty(stats) ? stats.high : "-"} />
                                    </div>
                                    <div className="py-2 pe-2 text-center">
                                        <DashboardSeverityIndicator severity={'Medium'} loadingRows={false} dashboardData={!_.isEmpty(stats) ? stats.medium : "-"} />
                                    </div>
                                    <div className="py-2 pe-2 text-center">
                                        <DashboardSeverityIndicator severity={'Low'} loadingRows={false} dashboardData={!_.isEmpty(stats) ? stats.low : "-"} />
                                    </div>

                                    {(!_.isEmpty(stats) && (stats.total - (stats.low + stats.high + stats.critical + stats.medium)) > 0)
                                        ?
                                        <div className="py-2 text-center">
                                            <DashboardSeverityIndicator severity={'NA'} loadingRows={false} dashboardData={!_.isEmpty(stats) ? (stats.total - (stats.low + stats.high + stats.critical + stats.medium)) : "-"} />
                                        </div> : ""}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    {!_.isEmpty(unifiedSummaryData) &&
                        <ProductWiseStats data={unifiedSummaryData} isCritical={!_.isEmpty(isCritical)} />}
                </div>
            </div>
        </div>
        <section>
            <div className="card custom-card">
                <div className="card-body">
                    <div className="table-responsive">
                        <UnifiedSummaryTable data={unifiedSummaryData} isCritical={!_.isEmpty(isCritical)} />
                    </div>
                </div>
            </div>
        </section>
    </section>)
}