
import { useState } from "react";
import axios from "axios"
import { GlobalConst } from "../appConfig/globalConst";
import _ from "lodash";

const getSupport = (supportInfo) => {
    let postData = {
        "type": supportInfo.product, 
        "customerEmail": supportInfo.email,
        "metadata": {
            company: supportInfo.email,
            source: supportInfo.source
        }
    };

    return axios
            .post(`${GlobalConst.API_URL}/notify/email/internal`, postData)
}

export const useSupport = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const supportAPI = async (supportInfo) => {
        setLoading(true);
        getSupport(supportInfo).then(op => {
            if(!_.isEmpty(op.data)){
                setData(op.data)
            }else{
                setData({});
            }
        })
        .catch(e => {
            setData(null);
            console.log("Exception: ", e)
        }).finally(() => {
            setLoading(false);
        })
    }

    return { data, loading, supportAPI }
}