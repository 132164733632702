import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RiArrowRightUpLine, RiArrowRightDownLine } from "react-icons/ri";
import { SiKubernetes } from "react-icons/si";
import { FaDocker } from "react-icons/fa6";
import { FaCode } from "react-icons/fa6";
import { FaCloud } from "react-icons/fa";
import { VscDebugConsole } from "react-icons/vsc";
import { FaLayerGroup } from "react-icons/fa";
import { calculatePercentageDifference } from '../../shared/helper/genHelper';
import { FiShield } from "react-icons/fi";
import { BiLockAlt } from "react-icons/bi";
import { GrTime } from "react-icons/gr";
import { IoFlashOutline } from "react-icons/io5";
import axios from 'axios';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import _ from 'lodash';
import GetCount from './getCount';
import { useDispatch, useSelector } from 'react-redux';
import SeverityIndicator from './severityIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import UnifiedReportSummary from './reportsSummary/unifiedReportSummary';
import { updateUnifiedReports } from '../../shared/redux/reducers/unifiedReducer';

const DashboardPyramid = () => {

    const history = useHistory();
    const reduxState = useSelector(state => state)
    const [activeSection, setActiveSection] = useState(null);

    const [totalAssets, setTotalAssets] = useState(0);
    const [totalCritical, setTotalCritical] = useState(0);
    const [securityScore, setSecurityScore] = useState(64);
    const [trendCount, setTrendCount] = useState(0);

    const [codeData, setCodeData] = useState({});
    const [scaData, setSCAData] = useState({});
    const [cloudData, setCloudData] = useState({});
    const [containerData, setContainerData] = useState({});
    const [k8sData, setk8sData] = useState({});

    const [webAppData, setWebAppData] = useState({});
    const [apiData, setApiData] = useState({});
    // const [unifiedSummaries, setUnifiedSummaries] = useState({});
    const productsReducer = useSelector(state => state.productsReducer)
    const dispatch = useDispatch()


    useEffect(() => {
        getCodeData();
        getScaData();
        getCloudData();
        getContainerData();

        getDastDataWebApp();
        getDastDataAPIApp();
        getK8sDashboardSummary();
    }, []);

    useEffect(() => {

        let totalAssetCount = 0;
        let totalCriticalCount = 0;
        let totalVulnerabilityCount = 0;
        let total7DaysBackVulnerabilityCount = 0;

        if (!_.isEmpty(codeData)) {
            totalAssetCount = totalAssetCount + codeData.list.applications;
            totalCriticalCount = totalCriticalCount + codeData.severityCount.critical;
            totalVulnerabilityCount = totalVulnerabilityCount + codeData.list.vulnerabilities;
            total7DaysBackVulnerabilityCount = total7DaysBackVulnerabilityCount + codeData.total7DaysBackVulnerabilityCount;
        }
        if (!_.isEmpty(scaData)) {
            totalAssetCount = totalAssetCount + scaData.list.applications;
            totalCriticalCount = totalCriticalCount + scaData.severityCount.critical;
            totalVulnerabilityCount = totalVulnerabilityCount + scaData.list.vulnerabilities;
            total7DaysBackVulnerabilityCount = total7DaysBackVulnerabilityCount + scaData.total7DaysBackVulnerabilityCount;
        }
        if (!_.isEmpty(cloudData)) {
            totalAssetCount = totalAssetCount + cloudData.list.applications;
            totalCriticalCount = totalCriticalCount + cloudData.severityCount.critical;
            totalVulnerabilityCount = totalVulnerabilityCount + cloudData.list.vulnerabilities;
            total7DaysBackVulnerabilityCount = total7DaysBackVulnerabilityCount + cloudData.total7DaysBackVulnerabilityCount;
        }
        if (!_.isEmpty(containerData)) {
            totalAssetCount = totalAssetCount + containerData.list.applications;
            totalCriticalCount = totalCriticalCount + containerData.severityCount.critical;
            totalVulnerabilityCount = totalVulnerabilityCount + containerData.list.vulnerabilities;
            total7DaysBackVulnerabilityCount = total7DaysBackVulnerabilityCount + containerData.total7DaysBackVulnerabilityCount;
        }
        if (!_.isEmpty(webAppData)) {
            totalAssetCount = totalAssetCount + webAppData.list.applications;
            totalCriticalCount = totalCriticalCount + webAppData.severityCount.critical;
            totalVulnerabilityCount = totalVulnerabilityCount + webAppData.list.vulnerabilities;
            total7DaysBackVulnerabilityCount = total7DaysBackVulnerabilityCount + webAppData.total7DaysBackVulnerabilityCount;
        }
        if (!_.isEmpty(apiData)) {
            totalAssetCount = totalAssetCount + apiData.list.applications;
            totalCriticalCount = totalCriticalCount + apiData.severityCount.critical;
            totalVulnerabilityCount = totalVulnerabilityCount + apiData.list.vulnerabilities;
            total7DaysBackVulnerabilityCount = total7DaysBackVulnerabilityCount + apiData.total7DaysBackVulnerabilityCount;
        }
        if (!_.isEmpty(k8sData)) {
            totalAssetCount = totalAssetCount + k8sData.list.applications;
            totalCriticalCount = totalCriticalCount + k8sData.severityCount.critical;
            totalVulnerabilityCount = totalVulnerabilityCount + k8sData.list.vulnerabilities;
            total7DaysBackVulnerabilityCount = total7DaysBackVulnerabilityCount + k8sData.total7DaysBackVulnerabilityCount;
        }

        setTotalCritical(totalCriticalCount);
        setTotalAssets(totalAssetCount);
        setTrendCount(calculatePercentageDifference(totalVulnerabilityCount, total7DaysBackVulnerabilityCount));

    }, [codeData, scaData, cloudData, containerData, webAppData, apiData, k8sData]);


    const getK8sDashboardSummary = () => {
        // setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/summary/dashboard`, {})
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    // console.log(op.data)
                    setk8sData({
                        summary: op.data.overallResults,
                        list: {
                            applications: op.data.overallResults.length,
                            vulnerabilities: op.data.overallCVESummary.totalCve,
                            // total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                            than_last_week: (op.data.overallCVESummary.total7DaysBackCve) ? calculatePercentageDifference(op.data.overallCVESummary.totalCve, op.data.overallCVESummary.total7DaysBackCve) : '-',
                        },
                        total7DaysBackVulnerabilityCount: op.data.overallCVESummary.total7DaysBackCve,
                        severityCount: {
                            critical: op.data.overallCVESummary.criticalCve,
                            high: op.data.overallCVESummary.highCve,
                            medium: op.data.overallCVESummary.mediumCve,
                            low: op.data.overallCVESummary.lowCve
                        }
                    });
                    // setOverallCVESummary(op.data.overallCVESummary)
                    // dispatch(setKubernetesSecurityDashboardTableData(op.data.overallResults));

                    // if (!_.isEmpty(op.data.overallResults)) {
                    //     const initiatedScanReports = op.data.overallResults.filter((scanResult) => _.has(scanResult, "overallStatus") && scanResult.overallStatus.toLowerCase() === "initiated").map((scanResult) => scanResult.reportId)
                    //     if (!_.isEmpty(initiatedScanReports)) {
                    //         dispatch(setKubernetesSecurityScanTriggeredState(true));
                    //         dispatch(updateKubernetesSecurityTriggeredScans(initiatedScanReports));
                    //     }
                    // }

                } else {
                    // setHasError(true);
                    // setOverallCVESummary({})
                    // dispatch(setKubernetesSecurityDashboardTableData(null));
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                // setHasError(true);
                // setOverallCVESummary({})
                // dispatch(setKubernetesSecurityDashboardTableData(null));
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const getDastDataAPIApp = () => {
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/dashboard/summary`, { "scanType": 'api' })
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    // setCodeData(op.data);
                    setApiData({
                        summary: op.data.assetSummaries,
                        list: {
                            applications: op.data.assetSummaries.length,
                            vulnerabilities: op.data.totalVulnerabilityCount,
                            // total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                            than_last_week: (op.data.total7DaysBackVulnerabilityCount) ? calculatePercentageDifference(op.data.totalVulnerabilityCount, op.data.total7DaysBackVulnerabilityCount) : '-',
                        },
                        total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                        severityCount: {
                            critical: op.data.critical,
                            high: op.data.high,
                            medium: op.data.medium,
                            low: op.data.low
                        }
                    });
                } else {
                    // setHasError(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                // setHasError(true);
            })
            .finally(() => {
                // setLoadingRows(false);
            });
    }

    const getDastDataWebApp = () => {
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/dashboard/summary`, { "scanType": 'website' })
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    // setCodeData(op.data);
                    setWebAppData({
                        summary: op.data.assetSummaries,
                        list: {
                            applications: op.data.assetSummaries.length,
                            vulnerabilities: op.data.totalVulnerabilityCount,
                            // total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                            than_last_week: (op.data.total7DaysBackVulnerabilityCount) ? calculatePercentageDifference(op.data.totalVulnerabilityCount, op.data.total7DaysBackVulnerabilityCount) : '-',
                        },
                        total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                        severityCount: {
                            critical: op.data.critical,
                            high: op.data.high,
                            medium: op.data.medium,
                            low: op.data.low
                        }
                    });
                } else {
                    // setHasError(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                // setHasError(true);
            })
            .finally(() => {
                // setLoadingRows(false);
            });
    }

    const getCodeData = () => {
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/dashboard/summary`, {})
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    // setCodeData(op.data);
                    setCodeData({
                        summary: op.data.assetSummaries,
                        list: {
                            applications: op.data.assetSummaries.length,
                            vulnerabilities: op.data.totalVulnerabilityCount,
                            // total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                            than_last_week: (op.data.total7DaysBackVulnerabilityCount) ? calculatePercentageDifference(op.data.totalVulnerabilityCount, op.data.total7DaysBackVulnerabilityCount) : '-',
                        },
                        total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                        severityCount: {
                            critical: op.data.critical,
                            high: op.data.high,
                            medium: op.data.medium,
                            low: op.data.low
                        }
                    });
                } else {
                    // setHasError(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                // setHasError(true);
            })
            .finally(() => {
                // setLoadingRows(false);
            });
    }

    const getScaData = () => {
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/user/scan-stats`, {
                userId: reduxState.authReducer.userDetails.email,
                showOnlyTracingData: false
            })
            .then((op) => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setSCAData({
                        summary: op.data.assetList,
                        list: {
                            applications: _.size(op.data.assetList),
                            vulnerabilities: op.data.dashboardStats.totalCve,
                            // than_last_week: '-',
                            than_last_week: (op.data.total7DaysBackVulnerabilityCount) ? calculatePercentageDifference(op.data.dashboardStats.totalCve, op.data.total7DaysBackVulnerabilityCount) : '-',
                        },
                        total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                        severityCount: {
                            critical: op.data.dashboardStats.CRITICAL,
                            high: op.data.dashboardStats.HIGH,
                            medium: op.data.dashboardStats.MEDIUM,
                            low: op.data.dashboardStats.LOW
                        }
                    });


                }
            })
            .catch((e) => {
                console.log("Exception: ", e);
            });
    }

    const getCloudData = () => {
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/dashboard/summary`, {})
            .then((op) => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    // console.log("totalVulnerabilityCount", op.data.total);
                    // console.log("total7DaysBackVulnerabilityCount", op.data.total7DaysBackVulnerabilityCount);
                    // console.log("%", (op.data.total7DaysBackVulnerabilityCount * 100 / op.data.total));
                    // console.log("calculatePercentageDifference", calculatePercentageDifference(op.data.total, op.data.total7DaysBackVulnerabilityCount));
                    setCloudData({
                        summary: op.data.cloudAssets,
                        list: {
                            applications: _.size(op.data.cloudAssets),
                            vulnerabilities: op.data.total,
                            // total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                            than_last_week: (op.data.total7DaysBackVulnerabilityCount) ? calculatePercentageDifference(op.data.total, op.data.total7DaysBackVulnerabilityCount) : '-',
                        },
                        total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                        severityCount: {
                            critical: op.data.critical,
                            high: op.data.high,
                            medium: op.data.medium,
                            low: op.data.low
                        }
                    });


                }
            })
            .catch((e) => {
                console.log("Exception: ", e);
            });
    }

    const getContainerData = () => {
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/user/scan-stats`, { "userId": reduxState.authReducer.userDetails.email, "showOnlyTracingData": false, "showContainerOnly": true })
            .then((op) => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setContainerData({
                        summary: op.data.assetList,
                        list: {
                            applications: _.size(op.data.assetList),
                            vulnerabilities: op.data.dashboardStats.totalCve,
                            than_last_week: (op.data.total7DaysBackVulnerabilityCount) ? calculatePercentageDifference(op.data.dashboardStats.totalCve, op.data.total7DaysBackVulnerabilityCount) : '-',
                        },
                        total7DaysBackVulnerabilityCount: op.data.total7DaysBackVulnerabilityCount,
                        severityCount: {
                            critical: op.data.dashboardStats.CRITICAL,
                            high: op.data.dashboardStats.HIGH,
                            medium: op.data.dashboardStats.MEDIUM,
                            low: op.data.dashboardStats.LOW
                        }
                    });


                }
            })
            .catch((e) => {
                console.log("Exception: ", e);
            });
    }


    const sections = [
        {
            id: 'web',
            type: 'dastweb',
            name: 'Web Security',
            icon: VscDebugConsole,
            tools: 'DAST',
            width: 30,
            color: '#3B82F6',
            gradient: {
                from: 'rgba(59,130,246,0.6)',
                // from: 'rgba(42,42,42,1)',
                to: 'rgba(19,38,71,1)'
            },
            dataPoint: {
                applications: 152,
                vulnerabilities: 15,
                than_last_week: 12
            },
            severity: 'critical',
            dashboardLink: '/web-security-dashboard',
            productKey: GlobalConst.PRODUCT_KEYS.DAST,
        },
        {
            id: 'api',
            type: 'dastapi',
            name: 'API Security',
            icon: VscDebugConsole,
            tools: 'API Security',
            width: 40,
            color: '#8B5CF6',
            gradient: {
                from: 'rgba(139,92,246,0.6)',
                // from: 'rgba(42,42,42,1)',
                to: 'rgba(47,32,86,1)'
            },
            dataPoint: {
                apis: 152,
                vulnerabilities: 15,
                than_last_week: 12
            },
            severity: 'medium',
            dashboardLink: '/web-security-dashboard',
            productKey: GlobalConst.PRODUCT_KEYS.API,
        },
        {
            id: 'code',
            type: 'sast',
            name: 'Code',
            icon: FaCode,
            tools: 'SAST',
            width: 50,
            color: '#EC4899',
            gradient: {
                from: 'rgba(236,72,153,0.6)',
                // from: 'rgba(42,42,42,1)',
                to: 'rgba(74,21,50,1)'
            },
            dataPoint: {
                repositories: 152,
                vulnerabilities: 15,
                than_last_week: 12
            },
            severity: 'high',
            dashboardLink: '/sast-dashboard',
            productKey: GlobalConst.PRODUCT_KEYS.SAST,
        },
        {
            id: 'deps',
            type: 'sca',
            name: 'Open Source',
            icon: FaLayerGroup,
            tools: 'SCA',
            width: 60,
            color: '#10B981',
            gradient: {
                from: 'rgba(16,185,129,0.6)',
                // from: 'rgba(42,42,42,1)',
                to: 'rgba(7,59,41,1)'
            },
            dataPoint: {
                repositories: 152,
                vulnerabilities: 15,
                than_last_week: 12
            },
            severity: 'low',
            dashboardLink: '/sca-dashboard',
            productKey: GlobalConst.PRODUCT_KEYS.SCA,
        },
        {
            id: 'container',
            type: 'container',
            name: 'Container',
            icon: FaDocker,
            tools: 'Image Scanner',
            width: 70,
            color: '#F59E0B',
            gradient: {
                from: 'rgba(245,158,11,0.6)',
                // from: 'rgba(42,42,42,1)',
                to: 'rgba(73,46,9,1)'
            },
            dataPoint: {
                images: 152,
                vulnerabilities: 15,
                than_last_week: 12
            },
            severity: 'critical',
            dashboardLink: '/container-security',
            productKey: GlobalConst.PRODUCT_KEYS.CONTEK,
        },
        {
            id: 'k8s',
            type: 'k8s',
            name: 'Kubernetes Security',
            icon: SiKubernetes,
            tools: 'KSPM',
            width: 80,
            color: '#d84040',
            gradient: {
                from: 'rgba(216,64,64,0.6)',
                // from: 'rgba(42,42,42,1)',
                to: 'rgba(66,14,17,1)'
            },
            dataPoint: {
                clusters: 152,
                vulnerabilities: 15,
                than_last_week: 12
            },
            severity: 'high',
            dashboardLink: '/k8s-dashboard',
            productKey: GlobalConst.PRODUCT_KEYS.KUBERNETES,
        },
        {
            id: 'cloud',
            type: 'cloud',
            name: 'Cloud Security',
            icon: FaCloud,
            tools: 'Cloud Security',
            width: 90,
            color: '#6366F1',
            gradient: {
                from: 'rgba(99,102,241,0.6)',
                // from: 'rgba(42,42,42,1)',
                to: 'rgba(25,27,63,1)'
            },
            dataPoint: {
                cloud_environments: 152,
                vulnerabilities: 15,
                than_last_week: 12
            },
            severity: 'medium',
            dashboardLink: '/cloud-security-dashboard',
            productKey: GlobalConst.PRODUCT_KEYS.CLOUD_SECURITY,
        }
    ];

    // Custom styles that Bootstrap doesn't provide
    const customStyles = `
    .bg-slate-950 {
      background-color: #020617;
    }
    
    .section-card {
      transition: all 0.3s ease-in-out;
      backdrop-filter: blur(8px);
      border-radius: 0.75rem;
      overflow: hidden;
    }

    .section-card:hover {
      transform: scale(1.1);
    }

    .icon-container {
      padding: 0.5rem;
      border-radius: 0.5rem;
      transition: all 0.3s ease-in-out;
    }

    .gradient-text {
      background: linear-gradient(to right, #60a5fa, #a78bfa, #f472b6);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .pulse-dot {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      animation: pulse 2s infinite;
    }


    .pyramid-bg {
      clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
      background: linear-gradient(180deg, rgba(59,130,246,0.2) 0%, rgba(99,102,241,0.05) 100%);
      height: 100%;
      width: 100%;
      top: -2.5%;
      left: 0%;
    }
  `;

    const getHoverData = (type) => {
        if (type === 'sast') {
            return <GetCount dataPoint={codeData} />
        }
        else if (type === 'sca') {
            return <GetCount dataPoint={scaData} />
        }
        else if (type === 'cloud') {
            return <GetCount dataPoint={cloudData} />
        }
        else if (type === 'container') {
            return <GetCount dataPoint={containerData} />
        }
        else if (type === 'dastweb') {
            return <GetCount dataPoint={webAppData} />
        }
        else if (type === 'dastapi') {
            return <GetCount dataPoint={apiData} />
        }
        else if (type === 'k8s') {
            return <GetCount dataPoint={k8sData} />
        }
    }

    const getSeverity = (type) => {
        if (type === 'sast') {
            return <SeverityIndicator severityData={codeData.severityCount} />
        }
        else if (type === 'sca') {
            return <SeverityIndicator severityData={scaData.severityCount} />
        }
        else if (type === 'cloud') {
            return <SeverityIndicator severityData={cloudData.severityCount} />
        }
        else if (type === 'container') {
            return <SeverityIndicator severityData={containerData.severityCount} />
        }
        else if (type === 'dastweb') {
            return <SeverityIndicator severityData={webAppData.severityCount} />
        }
        else if (type === 'dastapi') {
            return <SeverityIndicator severityData={apiData.severityCount} />
        }
        else if (type === 'k8s') {
            return <SeverityIndicator severityData={k8sData.severityCount} />
        }
    }

    const handleViewSummaries = (isCritical) => {
        const { summary: sca } = scaData
        const { summary: sast } = codeData
        const { summary: dast } = webAppData
        const { summary: kubernetes } = k8sData
        const { summary: cloud } = cloudData
        const { summary: container } = containerData
        dispatch(updateUnifiedReports({
            sca,
            sast,
            dast,
            kubernetes,
            cloud,
            container
        }))
        if (isCritical) {
            history.push(`/unified-dashboard-reports?iscritical=${isCritical}`)

        } else {
            history.push(`/unified-dashboard-reports`)
        }
    }

    return (
        <>
            <style>{customStyles}</style>


            <section className="mx-3 my-2">
                {/* <section className="mb-4">
                    <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 style={{ lineHeight: 1 }} className="mb-2 text-white d-flex align-items-center justify-content-start">
                                <MdDashboard className="me-2" />
                                Pyramid Dashboard
                            </h2>
                        </div>
                    </div>
                </section> */}

                <div className="row mb-3">
                    <div className="col-sm-12">
                        <div className="min-vh-100 text-white">



                            <div className="card custom-card" >
                                <div className="card-body p-5 py-3 mx-auto" style={{ minWidth: '90rem' }}>
                                    <div className="d-flex flex-column align-items-center">
                                        {/* Header */}
                                        <div className="d-flex align-items-center mb-5">
                                            <FiShield className="me-2" size={32} color="#3B82F6" />
                                            <h1 className="h3 mb-0 gradient-text fw-bold">Unified Security</h1>
                                        </div>

                                        {/* Pyramid Container */}
                                        <div className="position-relative w-100">
                                            {/* Background Pyramid */}
                                            <div className="position-absolute w-100 h-100">
                                                <div className="position-absolute pyramid-bg"></div>
                                            </div>

                                            {/* Sections */}
                                            <div className="position-relative d-flex flex-column gap-3 mb-3">
                                                {sections.map((section) => {
                                                    const Icon = section.icon;
                                                    const isActive = activeSection === section.id;

                                                    return (
                                                        <div key={section.id} className="d-flex justify-content-center align-items-center">
                                                            <div
                                                                className=''
                                                                style={{ width: `${section.width}%` }}
                                                                onMouseEnter={() => setActiveSection(section.id)}
                                                                onMouseLeave={() => setActiveSection(null)}
                                                            >
                                                                <div
                                                                    onClick={(e) => { e.preventDefault(); history.push(section.dashboardLink) }}
                                                                    className="section-card"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        background: `linear-gradient(150deg, ${section.gradient.from}, ${section.gradient.to})`,
                                                                        // border: `1px solid ${section.color}${isActive ? '50' : '30'}`,
                                                                        boxShadow: isActive ? `0 4px 20px ${section.color}40` : 'none'
                                                                    }}
                                                                >
                                                                    <div className="p-2 d-flex align-items-center">
                                                                        <div className="d-flex align-items-center w-100" >
                                                                            <div
                                                                                className="icon-container me-4 d-flex align-items-center justify-content-center"
                                                                                style={{
                                                                                    color: section.color,
                                                                                    backgroundColor: `${section.color}40`,
                                                                                    height: 40,
                                                                                    width: 40
                                                                                }}
                                                                            >
                                                                                <Icon size={16} />
                                                                            </div>
                                                                            <div className='w-100 d-flex flex-column align-items-start'>
                                                                                <div className='d-flex flex-row align-items-center justify-content-start'>
                                                                                    <h4 className='' style={{ color: section.color, fontSize: 22 }}>
                                                                                        {section.name}
                                                                                    </h4>
                                                                                </div>
                                                                                {isActive && <div className="smaller mt-1 w-100">
                                                                                    {getHoverData(section.type)}
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                        {productsReducer.subscribedProducts !== null ?
                                                                            (_.find(productsReducer.subscribedProducts.activePlans, plan => plan.sProductKey.includes(section.productKey.toLowerCase()))
                                                                                ? getSeverity(section.type)
                                                                                : <FontAwesomeIcon icon={faLock} className='ps-1 feature-locking-icon ms-auto' style={{ opacity: 0.4 }} />)
                                                                            : null
                                                                        }
                                                                        {/* <div
                                                                            className="p-0 px-2 text-capitalize text-center"
                                                                            style={{ backgroundColor: getStrokeColor(section.severity), borderRadius: 50, fontSize: 10, color: '#000', width: 52 }}
                                                                        >
                                                                            {section.severity}
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className='row justify-content-center'>
                                                <div className='col-sm-2'>
                                                    <div className='card custom-card highligh-asset-card' style={{ cursor: "pointer", backgroundColor: '#0d1838', border: '1px solid #1c295e', height: 180 }}
                                                        onClick={() => handleViewSummaries(false)}>
                                                        <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                                                            <div
                                                                className="icon-container d-flex align-items-center justify-content-center mb-2"
                                                                style={{
                                                                    color: '#387af2',
                                                                    backgroundColor: `#112653`,
                                                                    height: 45,
                                                                    width: 45
                                                                }}
                                                            >
                                                                <FiShield style={{ fontSize: 30 }} />
                                                            </div>
                                                            <h2 style={{ color: '#387af2' }}>{totalAssets}</h2>
                                                            <p style={{ color: '#93a1bc', fontSize: 12 }}>Total assets protected</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <div className='card custom-card highligh-asset-card' style={{ cursor: "pointer", backgroundColor: '#221531', border: '1px solid #372646', height: 180 }}
                                                        onClick={() => handleViewSummaries(true)}>
                                                        <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                                                            <div
                                                                className="icon-container mb-2 d-flex align-items-center justify-content-center"
                                                                style={{
                                                                    color: '#ff3896',
                                                                    backgroundColor: `#391b3f`,
                                                                    height: 45,
                                                                    width: 45
                                                                }}
                                                            >
                                                                {/* <Icon size={16} /> */}
                                                                <GrTime style={{ fontSize: 30 }} />
                                                            </div>
                                                            <h2 style={{ color: '#ff3896' }}>{totalCritical}</h2>
                                                            <p style={{ color: '#93a1bc', fontSize: 12 }}>Total critical vulnerabilities</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <div className='card custom-card' style={{ backgroundColor: '#06202f', border: '1px solid #153240', height: 180 }}>
                                                        <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                                                            <div
                                                                className="icon-container mb-2 d-flex align-items-center justify-content-center"
                                                                style={{
                                                                    color: '#f2a136',
                                                                    backgroundColor: `#23211d`,
                                                                    height: 45,
                                                                    width: 45
                                                                }}
                                                            >
                                                                <BiLockAlt style={{ fontSize: 30 }} />
                                                            </div>
                                                            <h2 style={{ color: '#f2a136' }}>{securityScore}</h2>
                                                            <p style={{ color: '#93a1bc', fontSize: 12 }}>Security score</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <div className='card custom-card' style={{ backgroundColor: '#221d23', border: '1px solid #3c3916', height: 180 }}>
                                                        <div className='card-body text-center d-flex align-items-center justify-content-center flex-column'>
                                                            <div
                                                                className="icon-container mb-4 d-flex align-items-center justify-content-center"
                                                                style={{
                                                                    color: '#fe9717',
                                                                    backgroundColor: `#3a2d1d`,
                                                                    height: 45,
                                                                    width: 45
                                                                }}
                                                            >
                                                                {/* <Icon size={16} /> */}
                                                                <IoFlashOutline style={{ fontSize: 30 }} />
                                                            </div>
                                                            {/* <h2>45K+</h2> */}
                                                            <div className="d-flex align-items-center gap-2">
                                                                <h5 className={`${trendCount > 0 ? 'text-danger' : 'text-success'}  fs-12 mb-0`}>
                                                                    {(trendCount > 0) ? <RiArrowRightUpLine className={'fs-13 align-middle'} /> : <RiArrowRightDownLine className={'fs-13 align-middle'} />} {Math.abs(trendCount)} %
                                                                </h5>
                                                                <p className="text-muted fs-12 mb-0">than last week</p>
                                                            </div>
                                                            <p style={{ color: '#93a1bc', fontSize: 12 }}>Compare to last week</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>





            </section>




        </>
    );
};

export default DashboardPyramid;