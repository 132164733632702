import { Divider, Button, message } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CiLink } from "react-icons/ci";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { handleDastFormSubmit } from '../../shared/helper/apiHelper';
import dayjs from 'dayjs';
import Scheduler from '../dastDashboard/scheduler';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import _ from "lodash";

export default function NoAuthFields({ handleEditConfigClose, reportData }) {

    const formikRef = useRef(null);
    const ssPlaceholderUrl = 'https://example-secured.com/';
    const pwsPlaceholderUrl = 'https://example.com/';

    const [schedulerList, setSchedulerList] = useState([]);

    useEffect(() => {
        if (reportData.schedulers && reportData.schedulers.length > 0) {
            const convertedSchedulers = reportData.schedulers.map(scheduler => {
                // Create time string in hh:mm A format
                const timeString = `${scheduler.hour}:${scheduler.minute.toString().padStart(2, '0')} ${scheduler.period}`;
                const { type } = scheduler;
                let scheduleTypeData = {};

                if (!_.isEmpty(type)) {
                    scheduleTypeData = {
                        type
                    };
                    if (type === GlobalConst.SCHEDULER_TYPE.MONTHLY) {
                        scheduleTypeData = {
                            ...scheduleTypeData,
                            // dateOfMonth: dayjs().date(scheduler.dateOfMonth),
                            dateOfMonth: scheduler.dateOfMonth
                        };
                    } else if (type === GlobalConst.SCHEDULER_TYPE.WEEKLY) {
                        scheduleTypeData = {
                            ...scheduleTypeData,
                            dayOfWeek: scheduler.dayOfWeek
                        };
                    }
                }

                return {
                    id: Date.now() + Math.random(),
                    time: dayjs(timeString, 'h:mm A'),
                    timezone: scheduler.timezone,
                    ...scheduleTypeData
                };
            });
            setSchedulerList(convertedSchedulers);
        }
    }, [reportData.schedulers]);

    const handleFormSubmit = async (values, setSubmitting) => {

        setSubmitting(true);
        let toScanData = { scanType: 'website', scanAuthType: 'NO-AUTH', url: values.url, triggerNow: values.triggerNow, configId: reportData.configId };
        let response = await handleDastFormSubmit(toScanData, 'config', schedulerList);
        if (response) {
            message.success('Scheduler configurations saved successfully');
            handleEditConfigClose();
        }
        else {
            message.error('Failed to saved scheduler configurations. Please try again later.');
            setSubmitting(false);
        }
    }

    const validationSchema = Yup.object().shape({
        url: Yup.string()
            .required('Website URL is required')
            .url('Please enter a valid URL')
            .test('is-valid-protocol', 'Please enter a valid HTTP or HTTPS URL', function (value) {
                const { isSecureSite } = this.parent;
                if (isSecureSite) {
                    return value && value.startsWith('https://');
                }
                return value && (value.startsWith('http://') || value.startsWith('https://'));
            }),
    });

    return (
        <>
            <Formik
                initialValues={{
                    url: reportData.url,
                    authType: 'NO-AUTH',
                    triggerNow: true,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleFormSubmit(values, setSubmitting);
                }}
                innerRef={formikRef}
            >
                {({ isSubmitting, values }) => {
                    return (<Form>
                        <div className="mb-3">
                            <div className="input-group me-3">
                                <span className="input-group-text"><CiLink /></span>
                                <Field
                                    name="url"
                                    type="text"
                                    className="input input-rounded form-control"
                                    aria-describedby="Website URL"
                                    placeholder={`Enter a website URL to trigger Web Security Scan for e.g. ${values.isSecureSite ? ssPlaceholderUrl : pwsPlaceholderUrl}`}
                                    disabled={true}
                                />
                            </div>
                            <ErrorMessage name="url">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                        </div>
                        <Divider />
                        <Scheduler schedulerList={schedulerList} setSchedulerList={setSchedulerList} />
                        <div className="mb-4">
                            <label className="form-check">
                                <Field
                                    type="checkbox"
                                    name="triggerNow"
                                    className="form-check-input me-2"
                                />
                                Trigger scan now
                            </label>
                        </div>
                        <div className="">
                            <Button htmlType="submit" type="primary" size={'large'}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>)
                }}
            </Formik>
        </>
    );
}